import { Modal } from '@mui/material'

const successModel = ({ title, subtitle, open, closeHandler }) => {
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className="successShadow">
        <div className="successContainer">
          <div className="top">
            <img
              src="https://webcdn.imarticus.org/inbound/Group926.svg"
              className="crosslogo"
              onClick={closeHandler}
            />
          </div>
          <div className="body">
            {title && <p className="title">{title}</p>}
            {subtitle && <p className="subtitle">{subtitle}</p>}
            <img
              className="tickImage"
              src="https://webcdn.imarticus.org/cmo/Ljg0Cv.tif_1_.svg"
            />
            <div className="okayBtn" onClick={closeHandler}>
              <p>Okay</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default successModel
