import React, { Component, useState, useRef, useEffect } from 'react'

import Tooltip from '@mui/material/Tooltip'

import {
  getAllPermitBatch,
  getAllPermitLocation,
  getCourseSpecificLG,
  getManagePOCDetails,
  getPocMapping,
} from './helper'

import ManagePOCModel from './ManagePOCModel'
import SuccessModel from './SuccessModel'
import ManagePocTable from './ManagePocTable'
import Loader from '../leadDistribution/Loader'

const ManagePOCDetail = ({
  defaultMaping,
  onlineLG,
  courseList,
  customMapping,
  setCustomMapping,
  checkDeepVisibility,
  permissions,
}) => {
  const [managePOCModel, setManagePOCModel] = useState(false)
  const [openSuccessModel, setOpenSuccessModel] = useState(false)

  const [successTitle, setSuccessTitle] = useState()
  const [successSubtitle, setSuccessSubTitle] = useState()
  const [loading, setLoading] = useState(false)

  const openManagePOPModel = () => setManagePOCModel(true)
  const closeManagePOPModel = () => {
    setManagePOCModel(false)
    setActiveMappingData({
      crs_pg_id: '',
      courseName: '',
      course_id: '',
      batchName: '',
      onboardingPoc: [
        {
          number: '',
          name: '',
          email: '',
        },
      ],
      financePoc: [
        {
          number: '',
          name: '',
          email: '',
        },
      ],
      localGovernancePoc: [
        {
          number: '',
          name: '',
          email: '',
        },
      ],
    })
  }

  const closeSuccessModelHandler = () => {
    setOpenSuccessModel(false)
    window.location.reload()
  }

  const openSuccessModelHandler = () => {
    setOpenSuccessModel(true)
  }

  const [activeMappingData, setActiveMappingData] = useState({
    crs_pg_id: '',
    courseName: '',
    course_id: '',
    batchName: '',
    onboardingPoc: [
      {
        number: '',
        name: '',
        email: '',
      },
    ],
    financePoc: [
      {
        number: '',
        name: '',
        email: '',
      },
    ],
    localGovernancePoc: [
      {
        number: '',
        name: '',
        email: '',
      },
    ],
  })
  const [mappingOperationType, setMappingOperationType] = useState('ADD_MAP') // ADD_MAP, EDIT_MAP, ADD_DEF_MAP, EDIT_DEF_MAP
  const [modelTitle, setModelTitle] = useState(
    'Add/Edit POC Details for selected batch'
  )
  const [modelSubTitle, setModelSubTitle] = useState('')

  const [selectedCourse, setSelectedCourse] = useState('')
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedBatch, setSelectedBatch] = useState('')

  const [filterCourse, setFilterCourse] = useState('')
  const [filterLocation, setFilterLocation] = useState('')
  const [filterBatch, setFilterBatch] = useState('')

  const [locationList, setLocationList] = useState([])
  const [batchList, setBatchList] = useState([])

  const [filterLocationList, setFilterLocationList] = useState([])
  const [filterBatchList, setFilterBatchList] = useState([])

  const [courseSpecificLG, setCourseSpecificLG] = useState([])

  const defaultPOCManageHandler = () => {
    setModelTitle('Manage Default Mapped POCs ')
    setModelSubTitle('(This POC is for entire Online vertical)')
    if (defaultMaping != null) {
      setActiveMappingData({ crs_pg_id: '', course_id: '', ...defaultMaping })
      setMappingOperationType('EDIT_DEF_MAP')
    } else {
      setMappingOperationType('ADD_DEF_MAP')
    }

    setSuccessTitle('default POC Details updated')
    setSuccessSubTitle('')

    openManagePOPModel()
  }

  const handleCourseSelection = async (event) => {
    console.log(event.target.value)
    setLoading(true)
    setSelectedCourse(event.target.value)

    const locationRes = await getAllPermitLocation()
    setLoading(false)

    if (locationRes.success) {
      setLocationList(locationRes.data.allLocations)
    } else {
      alert('Fail to fetch Locations')
    }

    setLoading(true)
    const batchRes = await getAllPermitBatch(
      event.target.value,
      selectedLocation ? selectedLocation : ''
    )
    setLoading(false)

    if (batchRes.success) {
      setBatchList(batchRes.data.courses)
    } else {
      alert('Fail to fetch Batches')
    }

    if (batchRes.data.courses.length == 0) {
      alert('No batches found at this location')
    }
  }

  const handleLocationSelection = async (event) => {
    setSelectedLocation(event.target.value)
    setLoading(true)
    const batchRes = await getAllPermitBatch(selectedCourse, event.target.value)

    setLoading(false)

    if (batchRes.success) {
      setBatchList(batchRes.data.courses)
    } else {
      alert('Fail to fetch Batches')
    }

    if (batchRes.data.courses.length == 0) {
      alert('No batches found at this location')
    }
  }

  const addCustomePOCHandler = async () => {
    setLoading(true)
    const existingMapping = await getPocMapping(selectedCourse, selectedBatch)
    console.log({ existingMapping })
    setLoading(false)
    if (existingMapping.data.length > 0) {
      console.log('EDIT POC MAPPING')
      setActiveMappingData({
        ...activeMappingData,
        ...existingMapping.data[0],
      })
      setMappingOperationType('EDIT_MAP')
    } else {
      console.log('ADD POC MAPPING')
      setMappingOperationType('ADD_MAP')
    }

    setLoading(true)
    const courseLG = await getCourseSpecificLG(selectedCourse, selectedBatch)
    setLoading(false)
    if (courseLG.success) {
      setCourseSpecificLG(courseLG.data.courseLG)
    } else {
      alert('Fail to fetch Course Specific LG')
    }

    // Open the Manage POC modal
    setModelTitle('Add/Edit POC Details for selected batch')
    setModelSubTitle('')

    setSuccessTitle('POC Details updated')
    setSuccessSubTitle(
      'The POC details for the selected batch has been successfully added/updated.'
    )

    openManagePOPModel()
  }

  const handleCourseFilter = async (event) => {
    setFilterCourse(event.target.value)
    setLoading(true)
    const mappingData = await getPocMapping(event.target.value)
    setLoading(false)
    if (mappingData.success === true) {
      setCustomMapping(mappingData.data)

      if (mappingData.data.length === 0) {
        alert('No Custom POC Mapping for the Selected Course')
      }
    }
    setLoading(true)
    const locationRes = await getAllPermitLocation()
    setLoading(false)
    if (locationRes.success) {
      setFilterLocationList(locationRes.data.allLocations)
    } else {
      alert('Fail to fetch Locations')
    }

    if (event.target.value != '') {
      setLoading(true)
      const batchRes = await getAllPermitBatch(event.target.value)
      setLoading(false)
      if (batchRes.success) {
        setFilterBatchList(batchRes.data.courses)
        setFilterBatch('')
      } else {
        alert('Fail to fetch Batches')
      }

      if (batchRes.data.courses.length == 0) {
        alert('No batches found at this location')
      }
    }
  }

  const handleLocationFilter = async (event) => {
    setFilterLocation(event.target.value)
    setLoading(true)
    const batchRes = await getAllPermitBatch(filterCourse, event.target.value)
    setLoading(false)
    if (batchRes.success) {
      setFilterBatchList(batchRes.data.courses)
    } else {
      alert('Fail to fetch Batches')
    }

    if (batchRes.data.courses.length == 0) {
      alert('No batches found at this location')
    }
  }

  const handleBatchFilter = async (event) => {
    setFilterBatch(event.target.value)
    setLoading(true)
    const mappingData = await getPocMapping(filterCourse, event.target.value)
    setLoading(false)
    if (mappingData.success === true) {
      setCustomMapping(mappingData.data)

      if (mappingData.data.length === 0) {
        alert('No Custom POC Mapping for the Selected Batch')
      }
    }
  }

  const editCustomPOCHandler = async (mapping) => {
    setActiveMappingData({ ...mapping })
    setLoading(true)
    const courseLG = await getCourseSpecificLG(
      mapping.crs_pg_id,
      mapping.course_id
    )
    setLoading(false)

    if (courseLG.success) {
      console.log('HEREH RE HEH')
      console.log(courseLG.data.courseLG)
      setCourseSpecificLG(courseLG.data.courseLG)
    } else {
      alert('Fail to fetch Course Specific LG')
    }

    setMappingOperationType('EDIT_MAP')
    setModelTitle('Add/Edit POC Details for selected batch')
    setModelSubTitle('')

    setSuccessTitle('POC Details updated')
    setSuccessSubTitle(
      'The POC details for the selected batch has been successfully added/updated.'
    )

    setManagePOCModel(true)
  }

  return (
    <>
      {loading && <Loader />}
      {managePOCModel && (
        <ManagePOCModel
          title={modelTitle}
          subtitle={modelSubTitle}
          managePOCModel={managePOCModel}
          closeManagePOPModel={closeManagePOPModel}
          lgData={
            mappingOperationType == 'EDIT_DEF_MAP' ||
            mappingOperationType == 'ADD_DEF_MAP'
              ? onlineLG
              : courseSpecificLG
          }
          mappingData={activeMappingData}
          setActiveMappingData={setActiveMappingData}
          mappingOperationType={mappingOperationType}
          selectedCourse={selectedCourse}
          selectedBatch={selectedBatch}
          openSuccessModelHandler={openSuccessModelHandler}
        />
      )}

      {openSuccessModel && (
        <SuccessModel
          title={successTitle}
          subtitle={successSubtitle}
          open={openSuccessModel}
          closeHandler={closeSuccessModelHandler}
        />
      )}

      <div className="inboundBody">
        <div className="defaultPoc">
          <div className="top">
            <p className="heading">
              Default POC Mapping{' '}
              <span>
                (Applicable for all batches and courses of online vertical)
              </span>
              <Tooltip
                title="Default POC are added to ensure there are some LGs added incase after the batch launch POCs are not added for particular batch"
                placement="top"
                arrow
                sx={{
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'black',
                    color: 'white',
                    fontSize: '1rem',
                  },
                  '& .MuiTooltip-arrow': {
                    color: 'black',
                  },
                }}
              >
                <img
                  src="https://webcdn.imarticus.org/inbound/ic_baseline-info.svg"
                  className="infologo"
                />
              </Tooltip>
            </p>
            {checkDeepVisibility({ permissions, value: 2, resource: 57 }) && (
              <div className="editdefault" onClick={defaultPOCManageHandler}>
                <img
                  src="https://webcdn.imarticus.org/inbound/line-md_edit.svg"
                  className="logo"
                />
                <p className="title">Manage POCs</p>
              </div>
            )}
          </div>
          {/* <p className="middle">
            For learners where enrolled in multiple batches - the allocation for
            1st assigned batch would be considered.
          </p> */}
          <div className="bottom">
            <div className="poc">
              <p className="title">Onboarding POC</p>
              <div className="names">
                {defaultMaping &&
                  defaultMaping.onboardingPoc.map((data, index) => {
                    return <span key={index}>{data.name}</span>
                  })}
              </div>
            </div>
            <div className="poc">
              <p className="title">Finance POC</p>
              <div className="names">
                {defaultMaping &&
                  defaultMaping.financePoc.map((data, index) => {
                    return (
                      <>
                        <span key={index}>{data.name}</span>
                      </>
                    )
                  })}
              </div>
            </div>
            <div className="poc">
              <p className="title">Local Governance POC</p>
              <div className="names">
                {defaultMaping &&
                  defaultMaping.localGovernancePoc.map((data, index) => {
                    return <span key={index}>{data.name}</span>
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="customPoc">
          <div className="heading">
            <p>Add Custom POC Mapping</p>
          </div>

          <div className="formSection">
            <div className="inputs">
              <div className="inputLabel">
                <p className="label">Select Course</p>
                <div className="selectInput">
                  <select
                    value={selectedCourse}
                    onChange={handleCourseSelection}
                  >
                    <option value="" disabled>
                      Select Course
                    </option>
                    {courseList.map((course, index) => {
                      return (
                        <option key={index} value={course.value}>
                          {course.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="inputLabel">
                <p className="label">Select Location</p>
                <div
                  className="selectInput"
                  style={
                    locationList.length == 0
                      ? { cursor: 'not-allowed' }
                      : { cursor: 'pointer' }
                  }
                >
                  <select
                    value={selectedLocation}
                    onChange={handleLocationSelection}
                    disabled={locationList.length == 0 ? true : false}
                    style={
                      batchList.length == 0
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    }
                  >
                    <option value="" disabled>
                      Select Location
                    </option>
                    {locationList.map((location, index) => {
                      return (
                        <option key={index} value={location._id}>
                          {location.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="inputLabel">
                <p className="label">Select Batch</p>
                <div
                  className="selectInput"
                  style={
                    batchList.length == 0
                      ? { cursor: 'not-allowed' }
                      : { cursor: 'pointer' }
                  }
                >
                  <select
                    value={selectedBatch}
                    onChange={(e) => {
                      console.log(e.target.value)
                      setSelectedBatch(e.target.value)
                    }}
                    disabled={batchList.length == 0 ? true : false}
                    style={
                      batchList.length == 0
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    }
                  >
                    <option value="" disabled>
                      Select Batch
                    </option>
                    {batchList.map((batch, index) => {
                      return (
                        <option key={index} value={batch._id}>
                          {batch.btch_name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            {checkDeepVisibility({ permissions, value: 2, resource: 57 }) && (
              <div
                className="button"
                onClick={selectedBatch != '' ? addCustomePOCHandler : null}
                style={
                  selectedBatch
                    ? { cursor: 'pointer', opacity: '1' }
                    : { cursor: 'not-allowed', opacity: '0.4' }
                }
              >
                <p>Add/Edit POC Details</p>
              </div>
            )}
          </div>
        </div>

        <div className="customPoc">
          <p className="middle">
            <strong>Note : </strong>For learners where enrolled in multiple
            batches - the allocation for 1st assigned batch would be considered.
          </p>
          <div className="heading">
            <p>View POC Mapping</p>
          </div>

          <div className="formSection">
            <div className="inputs">
              <div className="inputLabel">
                <p className="label">Filter Course</p>
                <div className="selectInput">
                  <select value={filterCourse} onChange={handleCourseFilter}>
                    <option value="">Select Course</option>
                    {courseList.map((course, index) => {
                      return (
                        <option key={index} value={course.value}>
                          {course.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="inputLabel">
                <p className="label">Filter Location</p>
                <div
                  className="selectInput"
                  style={
                    locationList.length == 0
                      ? { cursor: 'not-allowed' }
                      : { cursor: 'pointer' }
                  }
                >
                  <select
                    value={filterLocation}
                    onChange={handleLocationFilter}
                    disabled={filterLocationList.length == 0 ? true : false}
                    style={
                      batchList.length == 0
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    }
                  >
                    <option value="" disabled>
                      Select Course
                    </option>
                    {filterLocationList.map((location, index) => {
                      return (
                        <option key={index} value={location._id}>
                          {location.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="inputLabel">
                <p className="label">Filter Batch</p>
                <div
                  className="selectInput"
                  style={
                    filterBatchList.length == 0
                      ? { cursor: 'not-allowed' }
                      : { cursor: 'pointer' }
                  }
                >
                  <select
                    value={filterBatch}
                    onChange={handleBatchFilter}
                    disabled={filterBatchList.length == 0 ? true : false}
                    style={
                      filterBatchList.length == 0
                        ? { cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    }
                  >
                    <option value="" disabled>
                      Select Course
                    </option>
                    {filterBatchList.map((batch, index) => {
                      return (
                        <option key={index} value={batch._id}>
                          {batch.btch_name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {customMapping.length > 0 ? (
            <ManagePocTable
              data={customMapping}
              editCustomPOCHandler={editCustomPOCHandler}
              checkDeepVisibility={checkDeepVisibility}
              permissions={permissions}
            />
          ) : (
            <div className="emptyScreen">
              <img
                src="https://webcdn.imarticus.org/inbound/Group4606.svg"
                className="emptyImage"
              />
              <p>
                You will be able to view the batch table data once you start
                adding POCs batch-wise.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ManagePOCDetail
