import './kachingViewer.scss'
import React, { useState, useEffect } from 'react'
import momentjs from 'moment-timezone'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import countryCodes from '../../countryCodes'
import Autocomplete from '@mui/material/Autocomplete'
import {
  deleteUnverifiedKachingOTP,
  getCategoryBasedCourses,
  getUnverifiedKachings,
} from '../../actions/general'
import PendingOrFailedKaching from './pendingOrfailedKaching/pendingOrfailedKaching'
import AddOrEditKaching from './addOrEditKaching/addOrEditKaching'

const KachingViewer = ({ props }) => {
  const [loading, setLoading] = useState(false)
  const [showAddOrEditKachingModal, setShowAddOrEditKachingModal] =
    useState(false)
  const [showPendingOrFailedKachingModal, setShowPendingOrFailedKachingModal] =
    useState(false)
  const [edit, setEdit] = useState(false)
  const [courses, setCourses] = useState([])
  const [skip, setSkip] = useState(0)
  const [monthAndYear, setMonthAndYear] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
  )
  const [kachings, setKachings] = useState([])
  const [moreKachingsPresent, setMoreKachingsPresent] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [mobile, setMobile] = useState('')
  const [ccod, setCcod] = useState({
    code: 'IN',
    value: '91',
    display: 'India (+91)',
  })
  const [leadSelected, setLeadSelected] = useState({})
  const [key, setKey] = useState(Date.now())
  const [isAdmin, setIsAdmin] = useState(false)
  const [isSubAdmin, setIsSubAdmin] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    start()
  }, [])

  useEffect(() => {
    setSkip(0)
    setKachings([])
    setKey(Date.now())
  }, [monthAndYear, selectedCourse])

  useEffect(() => {
    if (loaded) {
      getKachings(skip)
    }
    setLoaded(true)
  }, [key])

  const start = async () => {
    setLoading(true)
    const { success, message, ...data } = await getCategoryBasedCourses()
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    setCourses(data.courses)
    setLoading(false)
  }

  const getKachings = async (skip) => {
    setLoading(true)
    let { success, message, ...data } = await getUnverifiedKachings({
      skip,
      monthAndYear,
      crs_pg_id: selectedCourse?._id || undefined,
      mobile: mobile || undefined,
      ccod: ccod?.value || undefined,
    })
    console.log({ success, message })

    if (!success) {
      window.alert(message || 'Something went wrong, Please try again.')
      setLoading(false)
      return
    }

    setIsAdmin(data.isAdmin)
    setIsSubAdmin(data.isSubAdmin)

    if (data.kachings.length == 0) {
      if (data.areMoreKachingsPresent) {
        const newSkip = skip + 1
        setSkip(newSkip)
        await getKachings(newSkip)
      } else {
        setMoreKachingsPresent(data.areMoreKachingsPresent)
        setLoading(false)
      }
    } else {
      setKachings([...kachings, ...data.kachings])
      setMoreKachingsPresent(data.areMoreKachingsPresent)
      setLoading(false)
    }
  }

  let selectCcod = (ev, ccod) => {
    console.log({ ev, ccod })
    setCcod({ ...ccod })
  }

  const fetchKachings = async () => {
    setKachings([])
    setKey(Date.now())
  }

  const deleteKaching = async (kaching_id) => {
    setLoading(true)
    const { success, message, ...res } = await deleteUnverifiedKachingOTP({
      kaching_id,
    })
    console.log({ success, message, res })
    if (!success) {
      setLoading(false)
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    } else {
      window.alert(message)
    }
    setLoading(false)
  }

  if (loading)
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  return (
    <div
      className="mainContainer kachingViewer"
      style={{
        alignItems: 'flex-start',
        background: 'rgb(255, 255, 255)',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        height: '100%',
        padding: '2rem',
        width: '100%',
      }}
    >
      {showAddOrEditKachingModal && (
        <AddOrEditKaching
          open={showAddOrEditKachingModal}
          closeModal={() => {
            setShowAddOrEditKachingModal(false)
            fetchKachings()
          }}
          courses={courses}
          edit={edit}
          {...leadSelected}
        />
      )}
      {showPendingOrFailedKachingModal && (
        <PendingOrFailedKaching
          open={showPendingOrFailedKachingModal}
          closeModal={() => {
            setShowPendingOrFailedKachingModal(false)
            fetchKachings()
          }}
        />
      )}
      <h1>Kaching Viewer</h1>

      <div className="filters" style={{ marginBottom: '36px' }}>
        {' '}
        <input
          type="month"
          max={`${new Date().getFullYear()}-${new Date().getMonth() + 1}`}
          value={monthAndYear}
          onChange={(e) => setMonthAndYear(e.target.value)}
          className="kcv_dateInput"
          style={{ marginRight: '24px' }}
        />
        <Autocomplete
          multiple={false}
          disablePortal
          sx={{ width: 350 }}
          autoHighlight
          value={selectedCourse}
          onChange={(ev, course) => setSelectedCourse(course)}
          getOptionLabel={(course) => {
            return course?.name || ''
          }}
          style={{ width: '320px', marginRight: '24px' }}
          id="courseSelector"
          options={courses}
          renderInput={(params) => {
            return (
              <TextField
                label="Select a Course"
                {...params}
                variant="outlined"
              />
            )
          }}
        />
        <Autocomplete
          multiple={false}
          disablePortal
          disableClearable
          sx={{ width: 150 }}
          autoHighlight
          value={ccod}
          onChange={selectCcod}
          getOptionLabel={(ccode) => {
            return ccode?.display || ''
          }}
          id="ccodeSelector"
          options={countryCodes}
          style={{ width: '140px', marginRight: '24px' }}
          renderInput={(params) => {
            return (
              <TextField
                label="Select a country code"
                {...params}
                variant="outlined"
              />
            )
          }}
        />
        <TextField
          id="mobile"
          value={mobile}
          onChange={(e) =>
            e.target.value.length < 15 ? setMobile(e.target.value) : null
          }
          label="Mobile Number"
          variant="outlined"
          style={{ width: '200px', marginRight: '24px' }}
        />
        <button
          style={{
            width: '180px',
            color: 'white',
            backgroundColor: '#055646',
            borderRadius: '4px',
            padding: '6px 20px',
            height: '56px',
            textAlign: 'center',
          }}
          onClick={fetchKachings}
        >
          Search
        </button>
      </div>

      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {isAdmin && (
            <button
              onClick={() => {
                setEdit(false)
                setLeadSelected({})
                setShowAddOrEditKachingModal(true)
              }}
              style={{
                width: '220px',
                color: 'white',
                backgroundColor: '#055646',
                borderRadius: '4px',
                padding: '6px 20px',
                textAlign: 'center',
                marginRight: '16px',
                height: '40px',
              }}
            >
              Add Kaching
            </button>
          )}
          {(isAdmin || isSubAdmin) && (
            <button
              style={{
                width: '220px',
                color: 'white',
                backgroundColor: '#055646',
                borderRadius: '4px',
                padding: '6px 20px',
                height: '40px',
                textAlign: 'center',
              }}
              onClick={() => setShowPendingOrFailedKachingModal(true)}
            >
              Pending/Failed Kachings
            </button>
          )}
        </div>
        {moreKachingsPresent && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button
              style={{
                width: '220px',
                color: 'white',
                backgroundColor: '#055646',
                borderRadius: '4px',
                padding: '6px 20px',
                textAlign: 'center',
                marginRight: '16px',
                height: '40px',
              }}
              onClick={() => {
                const newSkip = skip + 1
                setSkip(newSkip)
                getKachings(newSkip)
              }}
            >
              Get More
            </button>
          </div>
        )}
      </div>
      <div className="notes-header">Notes</div>
      <ul>
        <li>
          A kaching will only get verified if a corresponding kaching is
          generated for the same learner via Admissions & Payment Module, until
          then it will remain unverified.
        </li>
      </ul>
      {kachings && kachings.length ? (
        <>
          <TableContainer className="mainTable" component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      fontWeight: 600,
                      width: '100px',
                      textAlign: 'center',
                    }}
                  >
                    S. No.
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{
                      fontWeight: 600,
                      width: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Lead Owner
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      fontWeight: 600,
                      width: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      fontWeight: 600,
                      width: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    style={{
                      fontWeight: 600,
                      width: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{
                      fontWeight: 600,
                      width: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Course
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{
                      fontWeight: 600,
                      width: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Revenue
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{
                      fontWeight: 600,
                      width: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Sales Date
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    style={{
                      fontWeight: 600,
                      width: '100px',
                      textAlign: 'center',
                    }}
                  >
                    Verified Kaching Present
                  </TableCell>
                  {isAdmin && (
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        fontWeight: 600,
                        width: '100px',
                        textAlign: 'center',
                      }}
                    >
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {kachings.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{ width: '100px', textAlign: 'center' }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{ width: '100px', textAlign: 'center' }}
                      >
                        {row.sales_owner_email}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{ width: '100px', textAlign: 'center' }}
                      >
                        {row.nam}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ width: '100px', textAlign: 'center' }}
                      >
                        +{row.ccod || row.ccod_unverified}-
                        {row.m || row.m_unverified}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ width: '100px', textAlign: 'center' }}
                      >
                        {row.ep}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ width: '100px', textAlign: 'center' }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ width: '100px', textAlign: 'center' }}
                      >
                        {row.curr} {row.revenue}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ width: '100px', textAlign: 'center' }}
                      >
                        {momentjs(
                          new Date(row.sales_date).getTime() + 19800000
                        ).format('lll')}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        style={{ width: '100px', textAlign: 'center' }}
                      >
                        {row.verified ? 'Yes' : 'No'}
                      </TableCell>
                      {isAdmin && (
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ width: '160px', textAlign: 'center' }}
                        >
                          <img
                            onClick={() => {
                              setEdit(true)
                              setLeadSelected(row)
                              setShowAddOrEditKachingModal(true)
                            }}
                            style={{ cursor: 'pointer' }}
                            src="https://webcdn.imarticus.org/Demo/Group5555.svg"
                          />
                          <img
                            onClick={() => {
                              if (
                                window.confirm(
                                  'Are you sure you want to delete this kaching?'
                                )
                              ) {
                                deleteKaching(row.kaching_id)
                              }
                            }}
                            style={{
                              height: '32px',
                              width: '32px',
                              marginLeft: '12px',
                              color: 'red',
                              backgroundColor: 'red',
                              borderRadius: '4px',
                              cursor: 'pointer',
                            }}
                            src="https://webcdn.imarticus.org/dsgdj/material-symbols_delete-outline.svg"
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <div
          style={{
            fontSize: '20px',
            fontWeight: '600',
            textAlign: 'center',
            width: '100%',
            marginTop: '24px',
          }}
        >
          No Unverified Kachings Found
        </div>
      )}
    </div>
  )
}

export default KachingViewer
