import React, { Component, useState, useRef, useEffect } from 'react'
import './QRCodeGen.scss'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import momentjs from 'moment-timezone'
import moment from 'moment'
import ejs from './ejs'
import * as constants from '../../constants'
import he from 'he'
import {
  Layout,
  Popconfirm,
  Menu,
  Breadcrumb,
  // Icon,
  PageHeader,
} from 'antd'
import axios from 'axios'
import QRCodeStyling from 'qr-code-styling'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useStepContext,
  Pagination,
  PaginationItem,
} from '@mui/material'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import Autocomplete from '@mui/material/Autocomplete'
import TablePagination from '@mui/material/TablePagination'
import { Modal } from '@mui/material'
import {
  fetchUserbyMobile,
  startFetchCourse,
  fetchEmailSendStatus,
  fetchLearnerPreviewData,
  sendOnboardMail,
  exportEmailSendStatus,
} from '../../actions/general'
import { countryCode, country_code_with_ISO, sgList } from '../../constants'
import countryCodes from '../../countryCodes'
const { SubMenu } = Menu
import { Troubleshoot } from '@mui/icons-material'
import {
  getAllPossibleLocations,
  getPseudoBatchesforCourse,
} from '../../actions/general'

const cookies = new Cookies()

let OnboardEmail = (props) => {
  // console.log({ props });
  const navigate = useNavigate()
  // const [qrCode, setQrCode] = useState(null);
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [data, setData] = useState([])
  const [studentData, setStudentData] = useState({})
  const [batches, setBatches] = useState([])
  const [realBatches, setRealBatches] = useState([])
  const [locations, setLocations] = useState([])
  const [courses, setCourses] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [batchType, setBatchType] = useState([
    // { _id: 'ALL', name: 'All' },
    { _id: 'PSEUDO', name: 'Pseudo Batch' },
    { _id: 'REAL', name: 'Real Batch' },
  ])
  const [sendStatus, setSendStatus] = useState([
    { _id: 'ALL', name: 'All' },
    { _id: 'SENT', name: 'Sent' },
    { _id: 'PENDING', name: 'Pending To Send' },
  ])
  const [acceptStatus, setAcceptStatus] = useState([
    { _id: 'ALL', name: 'All' },
    { _id: 'WAITING', name: 'Yet to Accept' },
    { _id: 'ACCEPTED', name: 'Accepted' },
  ])
  const [searchOptions, setSearchOptions] = useState([
    { _id: 'nm', name: 'Search By Student Name' },
    { _id: 'uid', name: 'Search By UID' },
    { _id: 'ep', name: 'Search By Email ID' },
    { _id: 'm', name: 'Search By Mobile No.' },
  ])
  const [selectedSearchOptions, setSelectedSearchOptions] = useState('')
  const [applicationSearchValue, setApplicationSearchValue] = useState('')
  const [noLearnerMessage, setNoLearnerMessage] = useState('')
  const [selectedCourse, setSelectedCourse] = useState({})
  const [selectedBatchType, setSelectedBatchType] = useState({})
  const [selectedBatch, setSelectedBatch] = useState({})
  const [selectedRealBatch, setSelectedRealBatch] = useState({})
  const [selectSendStatus, setSelectedSendStatus] = useState({
    _id: 'ALL',
    name: 'All',
  })
  const [selectedAcceptStatus, setSelectedAcceptStatus] = useState({
    _id: 'ALL',
    name: 'All',
  })
  const [locationName, setLocationName] = useState('')
  const [locationId, setLocationId] = useState('')
  const [pseudoBatches, setPseudoBatches] = useState([])
  const [totalPseudoBatches, setTotalPseudoBatches] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectMailType, setSelectMailType] = useState(0)
  const [isFocused, setIsFocused] = useState(false)
  const [validFrom, setValidFrom] = useState('')
  const [acceptDays, setAcceptDays] = useState(0)
  const [openSendMailPopup, setOpenSendMailPopup] = useState(false)
  const [openBulkSendMailPopup, setOpenBulkSendMailPopup] = useState(false)
  const [openSinglePreviewPopup, setOpenSinglePreviewPopup] = useState(false)
  const [openMultiplePreviewPopup, setOpenMultiplePreviewPopup] =
    useState(false)
  const [openSingleConfirmPopup, setOpenSingleConfirmPopup] = useState(false)
  const [openMultipleConfirmPopup, setOpenMultipleConfirmPopup] =
    useState(false)
  const [openErrorPopup, setOpenErrorPopup] = useState(false)
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [templateError, setTemplateError] = useState(false)
  const [welcomeHtml, setWelcomeHtml] = useState('')
  const [offerPdfHtml, setOfferPdfHtml] = useState('')
  const [currentTemplates, setCurrentTemplates] = useState()
  const [generatedTemplate, setGeneratedTemplate] = useState()
  const [selectedStudent, setSelectedStudent] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [hoveredRow, setHoveredRow] = useState(null)
  // const [showTemplatePopup, setShowTemplatePopup] = useState(false);

  let fetchCourses = async () => {
    setLoading(true)
    let { success, message, ...data } = await startFetchCourse()
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { courses: coursesTemp } = data
    let filteredCourses = coursesTemp.filter((course) =>
      selectMailType === 0
        ? course.if_offerletter_enbld
        : course.if_welcomeletter_enbld
    )
    setCourses(filteredCourses)
  }

  let fetchLocations = async () => {
    setLoading(true)
    let { success, message, ...data } = await getAllPossibleLocations()
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        alert(message)
      } else {
        alert('Something went wrong. Please try again.')
      }
      return
    }
    let locations = [{ name: 'All', _id: '' }, ...data.data.allLocations]

    setLocations(locations)
  }

  const getAllBatches = (crs_pg_id, location_id, batchType) => {
    setLoading(true)
    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        crs_pg_id: crs_pg_id,
        a_id: cookies.get('aid'),
      }
      if (location_id) {
        data['location_id'] = location_id
      }
      var getData = {
        url: `${constants.API_ENDPOINT}/cfpanelCourse/ls_btch`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      axios(getData)
        .then((res) => {
          let tmp_data =
            res.data.data && res.data.data.courses ? res.data.data.courses : []
          let result = []
          for (let d = 0; d < tmp_data.length; d++) {
            if (tmp_data[d].btch_name) {
              result.push({
                name: tmp_data[d].btch_name,
                _id: tmp_data[d]._id,
              })
            }
          }
          // if (!result.length) {
          //   alert('No batches found at this location')
          // }
          // result.unshift({ name: '', _id: null })
          setLoading(false)
          setRealBatches(result)
          if (batchType != 'PSEUDO') {
            setBatches(result)
          }
          resolve(result)
        })
        .catch((err) => {
          console.log('inside get data')
          setLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  let fetchPseudoBatches = async (crs_pg_id) => {
    if (!selectedCourse) return alert('Select a course first!')
    const courseId = crs_pg_id !== '' ? crs_pg_id : selectedCourse._id
    setLoading(true)
    let { success, message, ...data } = await getPseudoBatchesforCourse(
      courseId,
      locationId ? locationId : undefined
    )
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    console.log({ data })
    if (data.pseudoBatches && data.pseudoBatches.length > 0) {
      setPseudoBatches(processRows(data.pseudoBatches))
      setBatches(processRows(data.pseudoBatches))
    }
    setTotalPseudoBatches(data.totalPseudoBatches)
  }

  let fetchLearnersList = async () => {
    if (!selectedCourse || !selectedCourse._id) {
      window.alert('Select a course first')
      return
    }
    if (!selectedBatch || !selectedBatch._id) {
      window.alert('Select a batch first')
      return
    }
    setLoading(true)
    let { success, message, ...data } = await fetchEmailSendStatus(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      selectSendStatus ? selectSendStatus._id : '',
      selectedAcceptStatus ? selectedAcceptStatus._id : '',
      selectedSearchOptions ? selectedSearchOptions : '',
      applicationSearchValue,
      page,
      rowsPerPage
    )
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { studentList: studentList } = data
    setTotalPages(data.totalPages)
    console.log({ data })
    console.log({ studentList })
    setData(studentList)
    if (data.length === 0) {
      setNoLearnerMessage('No learner found for this selection')
    }
    if (selectedBatchType._id === 'PSEUDO') {
      getAllBatches(selectedCourse && selectedCourse._id, locationId, 'PSEUDO')
    }
  }

  let fetchLearnersListByReset = async () => {
    setLoading(true)
    let { success, message, ...data } = await fetchEmailSendStatus(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      selectSendStatus ? selectSendStatus._id : '',
      selectedAcceptStatus ? selectedAcceptStatus._id : '',
      '',
      '',
      page,
      rowsPerPage
    )
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    setSelectedSearchOptions('')
    setApplicationSearchValue('')
    let { studentList: studentList } = data
    setTotalPages(data.totalPages)
    console.log({ data })
    console.log({ studentList })
    setData(studentList)
    if (data.length === 0) {
      setNoLearnerMessage('No learner found for this selection')
    }
    if (selectedBatchType._id === 'PSEUDO') {
      getAllBatches(selectedCourse && selectedCourse._id, locationId, 'PSEUDO')
    }
  }

  let exportLearnersList = async () => {
    // setLoading(true)
    await exportEmailSendStatus(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      selectSendStatus ? selectSendStatus._id : '',
      selectedAcceptStatus ? selectedAcceptStatus._id : '',
      selectedSearchOptions ? selectedSearchOptions : '',
      applicationSearchValue,
      page,
      rowsPerPage
    )
    // setLoading(false)
    // console.log({ success, message, data })
    // if (!success) {
    //   if (message) {
    //     window.alert(message)
    //   } else {
    //     window.alert('Something went wrong. Please try again.')
    //   }
    //   return
    // }
    // let { studentList: studentList } = data
    // console.log({ data })
    // console.log({ studentList })
    // setData(studentList)
    // if (selectedBatchType._id === 'PSEUDO') {
    //   getAllBatches(selectedCourse && selectedCourse._id, locationId, 'PSEUDO')
    // }
  }

  let fetchDataForConfirm = async (aid) => {
    setLoading(true)
    let learnerArray = [aid]
    let { success, message, ...data } = await fetchLearnerPreviewData(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      learnerArray
    )
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { studentData: studentData } = data
    console.log({ data })
    console.log({ studentData })
    setStudentData(studentData)
    console.log({ aid })
    let templateNum = selectMailType == 0 ? 6 : 5
    let html = getPreviewRenderedBlock(templateNum, templateNum, aid)
    setOpenSingleConfirmPopup(true)
    setGeneratedTemplate(html)
  }

  let fetchBulkDataForConfirm = async () => {
    setLoading(true)
    let learnerArray = selectedRows
    let aid = learnerArray.length > 0 ? learnerArray[0] : null
    let { success, message, ...data } = await fetchLearnerPreviewData(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      learnerArray
    )
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { studentData: studentData } = data
    console.log({ data })
    console.log({ studentData })
    setStudentData(studentData)
    setOpenMultipleConfirmPopup(true)
    // let templateNum = selectMailType == 0 ? 6 : 5;
    // let html = getPreviewRenderedBlock(templateNum, templateNum, aid);
    // setGeneratedTemplate(html);
    if (aid) {
      setCurrentIndex(0)
      let templateNum = selectMailType == 0 ? 6 : 5
      let html = getPreviewRenderedBlock(templateNum, templateNum, aid)
      setGeneratedTemplate(html)
    }
  }

  let fetchDataForPreview = async (aid) => {
    setLoading(true)
    let learnerArray = [aid]
    let { success, message, ...data } = await fetchLearnerPreviewData(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      learnerArray
    )
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { studentData: studentData } = data
    console.log({ data })
    console.log({ studentData })
    setStudentData(studentData)
    console.log({ aid })
    let templateNum = selectMailType == 0 ? 6 : 5
    let html = getPreviewRenderedBlock(templateNum, templateNum, aid)
    setOpenSinglePreviewPopup(true)
    setGeneratedTemplate(html)
  }

  let fetchDataForPrevSent = async (aid, prevEmail) => {
    setLoading(true)
    let learnerArray = [aid]
    let { success, message, ...data } = await fetchLearnerPreviewData(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      learnerArray
    )
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { studentData: studentData } = data
    console.log({ data })
    console.log({ studentData })
    setStudentData(studentData)
    console.log({ aid })
    let templateNum = selectMailType == 0 ? 6 : 5
    let html = getPreviewRenderedBlockSent(
      templateNum,
      templateNum,
      aid,
      prevEmail
    )
    setOpenSinglePreviewPopup(true)
    setGeneratedTemplate(html)
  }

  let fetchBulkDataForPreview = async () => {
    setLoading(true)
    let learnerArray = selectedRows
    let aid = learnerArray.length > 0 ? learnerArray[0] : null
    let { success, message, ...data } = await fetchLearnerPreviewData(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      learnerArray
    )
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { studentData: studentData } = data
    console.log({ data })
    console.log({ studentData })
    setStudentData(studentData)
    setOpenMultiplePreviewPopup(true)
    // let templateNum = selectMailType == 0 ? 6 : 5;
    // let html = getPreviewRenderedBlock(templateNum, templateNum, aid);
    // setGeneratedTemplate(html);
    if (aid) {
      setCurrentIndex(0)
      let templateNum = selectMailType == 0 ? 6 : 5
      let html = getPreviewRenderedBlock(templateNum, templateNum, aid)
      setGeneratedTemplate(html)
    }
  }

  let sendMailToSingleStudent = async () => {
    setLoading(true)
    let learnerArray = [selectedStudent]
    let { success, message, ...data } = await sendOnboardMail(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      learnerArray,
      validFrom,
      acceptDays,
      selectedBatchType._id === 'REAL'
        ? selectedBatch._id
        : selectedRealBatch
        ? selectedRealBatch._id
        : ''
    )
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    // let { studentData: studentData } = data;
    console.log({ data })
    // console.log({ studentData });
    // setStudentData(studentData);
    // setOpenMultiplePreviewPopup(true);
    setOpenSuccessPopup(true)
    setOpenBulkSendMailPopup(false)
    setOpenSendMailPopup(false)
    setSelectedStudent('')
    setOpenSingleConfirmPopup(false)
  }

  let sendMailToMultipleStudent = async () => {
    setLoading(true)
    let learnerArray = selectedRows
    let { success, message, ...data } = await sendOnboardMail(
      selectedCourse._id,
      selectedBatch._id,
      selectedBatchType._id,
      selectMailType,
      learnerArray,
      validFrom,
      acceptDays,
      selectedBatchType._id === 'REAL'
        ? selectedBatch._id
        : selectedRealBatch
        ? selectedRealBatch._id
        : ''
    )
    setLoading(false)
    console.log({ success, message, data })
    if (!success) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    // let { studentData: studentData } = data;
    console.log({ data })
    // console.log({ studentData });
    // setStudentData(studentData);
    // setOpenMultiplePreviewPopup(true);
    setOpenSuccessPopup(true)
    setOpenBulkSendMailPopup(false)
    setOpenSendMailPopup(false)
    setSelectedStudent('')
    setOpenMultipleConfirmPopup(false)
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < selectedRows.length - 1 ? prevIndex + 1 : prevIndex
    )
  }

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
  }

  const handleChange = (event, value) => {
    console.log({ value })
    let val = value - 1
    setPage(val)
  }

  let processRows = (rows) => {
    let arr = rows.map((el) => {
      // console.log({ el })
      let location = locations.find((element) => element._id == el.loc_id)
      let tz = momentjs.tz.guess()
      let etim = momentjs.tz(el.l_date, tz).valueOf()
      // console.log({ etim })
      let date = momentjs(etim).format('Do MMM YYYY')
      let unResolvedStudents = el.unResolvedStudents.map((user) => {
        let expTim = momentjs.tz(user.exp, tz).valueOf()
        // console.log({ expTim })
        let dateExp = momentjs(expTim).format('Do MMM YYYY')
        return { ...user, dateExp }
      })
      let resolvedStudents = el.resolvedStudents.map((user) => {
        let expTim = momentjs.tz(user.exp, tz).valueOf()
        // console.log({ expTim })
        let dateExp = momentjs(expTim).format('Do MMM YYYY')
        return { ...user, dateExp }
      })
      let rejectedStudents = el.rejectedStudents.map((user) => {
        let expTim = momentjs.tz(user.exp, tz).valueOf()
        // console.log({ expTim })
        let dateExp = momentjs(expTim).format('Do MMM YYYY')
        return { ...user, dateExp }
      })
      return {
        name: el.name,
        location: `${location.name}, ${location.city}, ${location.country}`,
        l_date: date,
        unResolvedStudents,
        resolvedStudents,
        rejectedStudents,
        _id: el._id,
      }
    })
    console.log({ processedArr: arr })
    return arr
  }

  useEffect(() => {
    fetchCourses()
    fetchLocations()
  }, [])

  useEffect(() => {
    if (data.length > 0 && selectedRows.length > 0 && openBulkSendMailPopup) {
      const aid = selectedRows[currentIndex]
      setSelectedStudent(aid)
      console.log({ aid })
      let templateNum = selectMailType === 0 ? 6 : 5
      let html = getPreviewRenderedBlock(templateNum, templateNum, aid)
      setGeneratedTemplate(html)
    }
  }, [currentIndex, selectMailType, data])

  const handleOpenBulkPopup = () => {
    if (data.length > 0 && selectedRows.length > 0) {
      const aid = selectedRows[currentIndex]
      setSelectedStudent(aid)
      console.log({ aid })
      let templateNum = selectMailType === 0 ? 6 : 5
      let html = getPreviewRenderedBlock(templateNum, templateNum, aid)
      setGeneratedTemplate(html)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset to first page when changing rows per page
  }

  useEffect(() => {
    if (selectedCourse?._id && selectedBatch?._id) {
      fetchLearnersList()
    }
  }, [page, rowsPerPage])

  const EJSVariableMap = {
    IMARTICUS_USERNAME_VARIABLE: '<%= data.mail_info.user_name %>',
    IMARITCUS_CRS_UTAG_VARIABLE: '<%= data.mail_info.crs_utag %>',
    IMARTICUS_CRS_NAME_VARIABLE: '<%= data.mail_info.crs_name %>',
    IMARTICUS_BATCH_NAME_VARIABLE: '<%= data.mail_info.btch_name %>',
    IMARTICUS_PAYMENT_INSTALLMENT_TABLE:
      '<%- data.mail_info.installment_table %>',
    IMARTICUS_OFFER_DATE_VARIABLE: '<%= data.mail_info.offer_date %>',
    IMARTICUS_BATCH_LAUNCH_DATE_VARIABLE: '<%= data.mail_info.l_date %>',
    IMARTICUS_ACCEPT_DAYS_VARIABLE: '<%= data.mail_info.accept_days %>',
  }

  let getInitRenderedBlock = (template, status, course) => {
    let mail_info = {
      crs_name: course.name,
      status: status,
      user_name: 'IMARTICUS_USERNAME_VARIABLE',
      crs_utag: 'IMARITCUS_CRS_UTAG_VARIABLE',
      btch_name: 'IMARTICUS_BATCH_NAME_VARIABLE',
      installment_table: 'IMARTICUS_PAYMENT_INSTALLMENT_TABLE',
      offer_date: 'IMARTICUS_OFFER_DATE_VARIABLE',
      l_date: 'IMARTICUS_BATCH_LAUNCH_DATE_VARIABLE',
      accept_days: 'IMARTICUS_ACCEPT_DAYS_VARIABLE',
    }
    console.log({ template })
    let html = ejs.render(template, { data: { mail_info: mail_info } })
    return html
  }

  let getPreviewRenderedBlock = (templateNum, status, aid) => {
    let user = data.find((item) => item.aid === aid)
    console.log(data)
    console.log({ user })
    console.log({ selectedBatch })
    const decodedHTML =
      studentData[aid] &&
      studentData[aid].length > 0 &&
      he.decode(studentData[aid])
    console.log({ decodedHTML })
    let mail_info = {
      crs_name: selectedCourse.name,
      status: templateNum,
      user_name: user ? user.name : 'IMARTICUS_USERNAME_VARIABLE',
      crs_utag: selectedCourse.u_tag,
      btch_name:
        selectedBatchType._id === 'PSEUDO'
          ? selectedRealBatch.name
          : selectedBatch.name
          ? selectedBatch.name
          : 'IMARTICUS_BATCH_NAME_VARIABLE',

      installment_table:
        studentData[aid] && studentData[aid].length > 0
          ? he.decode(studentData[aid])
          : 'IMARTICUS_PAYMENT_INSTALLMENT_TABLE',
      offer_date: moment(Date.now()).format('DD-MM-YYYY'),
      l_date:
        validFrom != ''
          ? moment(validFrom).format('DD-MM-YYYY')
          : 'IMARTICUS_BATCH_LAUNCH_DATE_VARIABLE',
      accept_days: acceptDays,
    }
    console.log({ mail_info: mail_info })
    let html = ejs.render(
      selectedCourse.application_email_template[templateNum],
      { data: { mail_info: mail_info } }
    )
    return html
  }

  let getPreviewRenderedBlockSent = (templateNum, status, aid, prevEmail) => {
    let user = data.find((item) => item.aid === aid)
    console.log(data)
    console.log({ user })
    const batch = realBatches.find(
      (batch) => batch._id === prevEmail.real_batch_id
    )
    console.log({ batch })
    const btch_name = batch ? batch.name : 'IMARTICUS_BATCH_NAME_VARIABLE'
    console.log({ btch_name })
    const decodedHTML =
      studentData[aid] &&
      studentData[aid].length > 0 &&
      he.decode(studentData[aid])
    console.log({ decodedHTML })
    let mail_info = {
      crs_name: selectedCourse.name,
      status: templateNum,
      user_name: user ? user.name : 'IMARTICUS_USERNAME_VARIABLE',
      crs_utag: selectedCourse.u_tag,
      btch_name: btch_name != '' ? btch_name : 'IMARTICUS_BATCH_NAME_VARIABLE',

      installment_table:
        studentData[aid] && studentData[aid].length > 0
          ? he.decode(studentData[aid])
          : 'IMARTICUS_PAYMENT_INSTALLMENT_TABLE',
      offer_date: moment(prevEmail.createdAt).format('DD-MM-YYYY'),
      l_date:
        prevEmail.batch_launch_date != ''
          ? moment(prevEmail.batch_launch_date).format('DD-MM-YYYY')
          : 'IMARTICUS_BATCH_LAUNCH_DATE_VARIABLE',
      accept_days: prevEmail.accept_deadline_days,
    }
    console.log({ mail_info: mail_info })
    let html = ejs.render(
      selectedCourse.application_email_template[templateNum],
      { data: { mail_info: mail_info } }
    )
    return html
  }

  let viewApplicationMailTemplate = async (crs) => {
    let templates = {}
    if (crs.application_email_template) {
      if (selectMailType === 1) {
        templates[5] = getInitRenderedBlock(
          crs.application_email_template[5],
          5,
          crs
        )
        templates[6] = getInitRenderedBlock(
          crs.application_email_template[6],
          6,
          crs
        )
        templates[7] = getInitRenderedBlock(
          crs.application_email_template[7],
          7,
          crs
        )
      } else {
        templates[5] = getInitRenderedBlock(
          crs.application_email_template[5],
          5,
          crs
        )
        templates[6] = getInitRenderedBlock(
          crs.application_email_template[6],
          6,
          crs
        )
        templates[7] = getInitRenderedBlock(
          crs.application_email_template[7],
          7,
          crs
        )
      }
    }
    console.log({ templates })
    setCurrentTemplates(templates)
  }

  const courseChanged = (course) => {
    if (String(selectedCourse?._id) !== String(course._id)) {
      setSelectedCourse(course)
      setSelectedBatch('')
      setBatches([])
      setLocationName('')
      setData([])
      setPage(0)
      setLocationName('All')
      setLocationId('')
      batchTypeChanged({ _id: 'PSEUDO', name: 'Pseudo Batch' }, course._id)
      setNoLearnerMessage('')

      const templateKeys = Object.keys(course.application_email_template || {})
      console.log({ templateKeys })
      if (selectMailType === 0) {
        if (!templateKeys.includes('6') && !templateKeys.includes('7')) {
          setTemplateError(true)
          setOpenErrorPopup(true)
        } else {
          viewApplicationMailTemplate(course)
          setTemplateError(false)
        }
      } else {
        if (!templateKeys.includes('5')) {
          setTemplateError(true)
          setOpenErrorPopup(true)
        } else {
          viewApplicationMailTemplate(course)
          setTemplateError(false)
        }
      }
      // console.log({batches})
    }
  }

  const locationChanged = (locationName, locationId) => {
    setLocationName(locationName)
    setLocationId(locationId)
    setSelectedBatch('')
    setData([])
    setPage(0)
    setNoLearnerMessage('')
  }

  const batchTypeChanged = (batchType, crs_pg_id = '') => {
    setSelectedBatchType(batchType)
    setSelectedBatch({})
    setData([])
    setPage(0)
    setNoLearnerMessage('')
    console.log({ batchType })
    if (batchType._id === 'PSEUDO') {
      fetchPseudoBatches(crs_pg_id)
    } else if (batchType._id === 'REAL') {
      getAllBatches(selectedCourse && selectedCourse._id, locationId, 'REAL')
    }
  }

  const batchChanged = (batch) => {
    setData([])
    setPage(0)
    setNoLearnerMessage('')
    setSelectedBatch(batch)
  }

  const realBatchChanged = (batch) => {
    setSelectedRealBatch(batch)
  }

  const sendStatusChanged = (batch) => {
    setData([])
    setPage(0)
    setNoLearnerMessage('')
    setSelectedSendStatus(batch)
  }

  const acceptStatusChanged = (batch) => {
    setData([])
    setPage(0)
    setNoLearnerMessage('')
    setSelectedAcceptStatus(batch)
  }

  const handleScreeningStatusChange = (screeningStatus) => {
    setSelectMailType(screeningStatus)
    if (selectedCourse && selectedCourse?._id != undefined) {
      setLocationName('All')
    }
  }

  const handleDateInputChnage = (e) => {
    const [key, value] = [e.target.name, e.target.value]

    if (e.target.name == 'validFrom') {
      setValidFrom(e.target.value)
    }
    if (e.target.name == 'validTill') {
      setValidTill(e.target.value)
    }
  }

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    )
  }

  useEffect(() => {
    fetchCourses()
    setPseudoBatches([])
    setSelectedBatch('')
    // setSelectedBatchType({})
    // setBatches([])
    // setLocationName('')
    setData([])
    setPage(0)
  }, [selectMailType])

  const checkInstallmentCondition = (row) => {
    if (!selectedCourse) return false

    const condition =
      selectMailType === 0
        ? selectedCourse.offer_letter_condition
        : selectedCourse.welcome_letter_condition

    console.log({ 'Installment Condition:': condition })
    console.log({ 'Transaction:': row.transaction })
    if (
      condition === 0 &&
      row.transaction &&
      row.transaction.reg_trxn_id &&
      row.transaction?.updated_package?.reg_price &&
      row.transaction?.updated_package?.reg_price > 0
    ) {
      return true
    } else if (
      condition === 1 &&
      row.transaction &&
      ((row.transaction.installment_trxn_ids &&
        row.transaction.installment_trxn_ids[0]) ||
        (row.transaction.emi_trxn_ids && row.transaction.emi_trxn_ids[0]) ||
        row.transaction.full_trxn_id)
    ) {
      return true
    } else {
      return false
    }
  }

  const getInstallmentTooltip = (row) => {
    if (!selectedCourse) return ''
    console.log({ selectedCourse })
    const condition =
      selectMailType === 0
        ? selectedCourse.offer_letter_condition
        : selectedCourse.welcome_letter_condition

    if (
      condition === 0 &&
      !row.transaction?.reg_trxn_id &&
      row.transaction?.updated_package?.reg_price &&
      row.transaction?.updated_package?.reg_price > 0
    ) {
      return "Mail can't be sent,learner has not paid the registration fee."
    } else if (
      condition === 1 &&
      !(
        (row?.transaction?.installment_trxn_ids &&
          row?.transaction?.installment_trxn_ids[0]) ||
        (row?.transaction?.emi_trxn_ids && row?.transaction?.emi_trxn_ids[0]) ||
        row?.transaction?.full_trxn_id
      )
    ) {
      return "Mail can't be sent,learner has not paid the 1st Installment/Full fee."
    } else {
      return "Mail can't be sent, learner has no transaction history"
    }

    return ''
  }

  if (loading)
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  return (
    <>
      <div className="onboardmail">
        <h3>Offer Letter & Welcome Email</h3>
        <hr style={{ width: '100%', margin: '-1rem 0 0rem' }} />
        <div className="toggle-container2 app-round-dsply">
          <button
            className={`job-screen-dt-tog-btn3 subscribed ${
              selectMailType === 0 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
            }`}
            onClick={() => handleScreeningStatusChange(0)}
          >
            <span>Send Offer Letter</span>
          </button>
          <button
            className={`job-screen-dt-tog-btn3 ${
              selectMailType === 1 ? 'btch-toggle-3' : 'btch-toggle-unchecked-3'
            }`}
            onClick={() => handleScreeningStatusChange(1)}
          >
            <span>Send Welcome Email</span>
          </button>
        </div>

        <div className="labs-table-header">
          <div className="labs-table-header-2">
            <Tooltip
              title={
                selectedCourse && selectedCourse.name ? '' : 'Select Course'
              }
              arrow
              disableInteractive
            >
              <div>
                <Autocomplete
                  disableClearable
                  value={selectedCourse && selectedCourse.name}
                  onChange={(event, course) => {
                    courseChanged(course)
                  }}
                  getOptionLabel={(batch) => batch.name || batch}
                  getOptionSelected={(option, test) => option.name === test}
                  id="batchSelector"
                  options={courses}
                  disabled={courses.length === 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="Select Course"
                    />
                  )}
                />
              </div>
            </Tooltip>
          </div>

          <div className="labs-table-header-2">
            <Tooltip title={locationName ? '' : 'Select Location'} arrow>
              <div>
                <Autocomplete
                  disableClearable
                  value={locationName}
                  onChange={(event, location) => {
                    locationChanged(location.name, location._id)
                  }}
                  getOptionLabel={(batch) => batch.name || batch}
                  getOptionSelected={(option, test) => option.name === test}
                  id="batchSelector"
                  options={locations}
                  disabled={locations.length === 0 || templateError}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="Select Location"
                    />
                  )}
                />
              </div>
            </Tooltip>
          </div>

          <div className="labs-table-header-2">
            <Tooltip
              title={
                selectedBatchType && selectedBatchType.name
                  ? ''
                  : 'Select Batch Type'
              }
              arrow
              disableInteractive
            >
              <div>
                <Autocomplete
                  disableClearable
                  value={selectedBatchType && selectedBatchType.name}
                  onChange={(event, batch) => {
                    batchTypeChanged(batch)
                  }}
                  getOptionLabel={(batch) => batch.name || batch}
                  getOptionSelected={(option, test) => option.name === test}
                  id="batchTypeSelector"
                  options={batchType}
                  disabled={batchType.length === 0 || templateError}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="Select Batch Type"
                    />
                  )}
                />
              </div>
            </Tooltip>
          </div>

          <div className="labs-table-header-2">
            <Tooltip
              title={selectedBatch && selectedBatch.name ? '' : 'Select Batch'}
              arrow
              disableInteractive
            >
              <div>
                <Autocomplete
                  disableClearable
                  value={selectedBatch && selectedBatch.name}
                  onChange={(event, batch) => {
                    batchChanged(batch)
                  }}
                  getOptionLabel={(batch) => batch.name || batch}
                  getOptionSelected={(option, test) => option.name === test}
                  id="batchSelector"
                  options={batches}
                  disabled={batches.length === 0 || templateError}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="Select Batch"
                    />
                  )}
                />
              </div>
            </Tooltip>
          </div>

          <div className="labs-table-header-2">
            <Tooltip
              title={
                selectSendStatus && selectSendStatus.name
                  ? ''
                  : 'Select Send Status'
              }
              arrow
              disableInteractive
            >
              <div>
                <Autocomplete
                  disableClearable
                  value={selectSendStatus && selectSendStatus.name}
                  onChange={(event, batch) => {
                    sendStatusChanged(batch)
                  }}
                  getOptionLabel={(batch) => batch.name || batch}
                  getOptionSelected={(option, test) => option.name === test}
                  id="sendStatusSelector"
                  options={sendStatus}
                  disabled={sendStatus.length === 0 || templateError}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="Select Send Status"
                    />
                  )}
                />
              </div>
            </Tooltip>
          </div>
          <div className="labs-table-header-2">
            <Tooltip
              title={
                selectedAcceptStatus && selectedAcceptStatus.name
                  ? ''
                  : 'Select Accept Status'
              }
              arrow
              disableInteractive
            >
              <div>
                <Autocomplete
                  disableClearable
                  value={selectedAcceptStatus && selectedAcceptStatus.name}
                  onChange={(event, batch) => {
                    acceptStatusChanged(batch)
                  }}
                  getOptionLabel={(batch) => batch.name || batch}
                  getOptionSelected={(option, test) => option.name === test}
                  id="acceptStatusSelector"
                  options={acceptStatus}
                  disabled={acceptStatus.length === 0 || templateError}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="Select Accept Status"
                    />
                  )}
                />
              </div>
            </Tooltip>
          </div>

          <div className="labs-table-search">
            <div
              style={{
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
              }}
            >
              <button
                onClick={() => {
                  setSelectedSearchOptions('')
                  setApplicationSearchValue('')
                  fetchLearnersList()
                }}
                className={`search-student-btn ${
                  templateError ? 'disabled' : ''
                }`}
                disabled={templateError}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        {data.length > 0 || selectedSearchOptions != '' ? (
          <>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginLeft: '4px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  zIndex: '999',
                  // minWidth: "680px",
                }}
              >
                {/* <div className="btch-search" style={{ marginRight: '1rem', width: '250px' }}> */}
                <FormControl fullWidth>
                  {selectedSearchOptions === '' && !isFocused && (
                    <InputLabel className="btch-search-label">
                      Select Search By
                    </InputLabel>
                  )}
                  <Select
                    value={selectedSearchOptions}
                    onChange={(event) => {
                      console.log({ searchOption: event.target.value })
                      setSelectedSearchOptions(event.target.value)
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    displayEmpty
                    className="btch-search"
                    sx={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {searchOptions.map((option, index) => (
                      <MenuItem key={index} value={option._id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* </div> */}
                <div className="btch-search">
                  <input
                    placeholder="Enter Search Value"
                    value={applicationSearchValue}
                    onChange={(e) => setApplicationSearchValue(e.target.value)}
                    className="form-control as-dl-search full-height"
                    // ref={(input) => input && searchJobKey && input.focus()}
                    style={{ height: '100%' }}
                  />
                </div>

                <button
                  onClick={() => {
                    fetchLearnersList()
                    // setIsFocused(true);
                  }}
                  className="labs-table-btn-3"
                  style={{ marginLeft: '1rem' }}
                >
                  Search
                </button>
                <button
                  onClick={() => {
                    fetchLearnersListByReset()
                    // setIsFocused(true);
                  }}
                  className="labs-table-btn-4"
                  style={{ marginLeft: '1rem' }}
                >
                  Reset
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    exportLearnersList()
                    // setIsFocused(true);
                  }}
                  className="labs-table-btn-download"
                  style={{ marginLeft: '1rem' }}
                >
                  <img src="https://webcdn.imarticus.org/Placement/tabler_file-export1.svg" />
                  Download Report
                </button>
              </div>
            </div>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <span className="list-learner-heading">List of Learners</span>
            </div>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={{ overflowX: 'auto', width: '100%' }}>
                <table className="table table-responsive table-striped lectureTable">
                  <thead>
                    <tr className="flex-100">
                      <th className="width-60 th-text">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              // setSelectedRows(data.map((row) => row.aid));
                              setSelectedRows(
                                data
                                  .filter((row) =>
                                    checkInstallmentCondition(row)
                                  )
                                  .map((row) => row.aid)
                              )
                            } else {
                              setSelectedRows([])
                            }
                          }}
                          checked={
                            selectedRows.length > 0 &&
                            selectedRows.length ===
                              data.filter((row) =>
                                checkInstallmentCondition(row)
                              ).length
                          }
                          disabled={data.every(
                            (row) => !checkInstallmentCondition(row)
                          )}
                          style={{
                            cursor: data.every(
                              (row) => !checkInstallmentCondition(row)
                            )
                              ? 'not-allowed'
                              : 'pointer',
                          }}
                        />
                      </th>
                      <th className="width-60 th-text">S.No.</th>
                      <th className="width-160 th-text">Name</th>
                      <th className="width-160 th-text">Student UID</th>
                      <th className="width-160 th-text">Student Phone</th>
                      <th className="width-260 th-text">Student Email</th>
                      {selectedBatchType._id === 'PSEUDO' ? (
                        <th className="width-260 th-text">
                          Pseudo Batch Launch Date
                        </th>
                      ) : (
                        <th className="width-260 th-text">Batch Launch Date</th>
                      )}
                      <th className="width-260 th-text">Sent Launch Date</th>
                      {selectMailType === 0 && (
                        <th className="width-160 th-text">Accept Status</th>
                      )}
                      <th className="width-160 th-text">Action</th>
                      <th className="width-160 th-text">Preview</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((row, index) => (
                        <tr className="flex-100">
                          <td className="width-60 th-cell">
                            {row.emails &&
                              row.emails.length >= 0 &&
                              (() => {
                                const filteredEmails = row.emails.filter(
                                  (email) => email.type === selectMailType
                                )

                                return (
                                  <>
                                    {filteredEmails.length > 0 ? (
                                      <input
                                        type="checkbox"
                                        checked={selectedRows.includes(row.aid)}
                                        onChange={() =>
                                          handleCheckboxChange(row.aid)
                                        }
                                        // disabled={!checkInstallmentCondition(row)}
                                        style={{
                                          cursor: !checkInstallmentCondition(
                                            row
                                          )
                                            ? 'not-allowed'
                                            : 'pointer',
                                        }}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        checked={selectedRows.includes(row.aid)}
                                        onChange={() =>
                                          handleCheckboxChange(row.aid)
                                        }
                                        disabled={
                                          !checkInstallmentCondition(row)
                                        }
                                        style={{
                                          cursor: !checkInstallmentCondition(
                                            row
                                          )
                                            ? 'not-allowed'
                                            : 'pointer',
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              })()}
                          </td>
                          <td className="width-60 th-cell">
                            {index + 1 + page * rowsPerPage}
                          </td>
                          <td className="width-160 th-cell">
                            {row?.name || '-'}
                          </td>
                          <td className="width-160 th-cell">{row.uid}</td>
                          <td className="width-160 th-cell">{row.m}</td>
                          <td className="width-260 th-cell">{row.email}</td>
                          <td className="width-260 th-cell">
                            {moment(row.l_date).format('DD-MM-YYYY')}
                          </td>
                          <td className="width-260 th-cell">
                            {row.emails && row.emails.length > 0
                              ? (() => {
                                  const filteredEmails = row.emails.filter(
                                    (email) => email.type === selectMailType
                                  )

                                  if (
                                    filteredEmails.length > 0 &&
                                    filteredEmails[0].batch_launch_date
                                  ) {
                                    return moment(
                                      filteredEmails[0].batch_launch_date
                                    ).format('DD-MM-YYYY')
                                  } else {
                                    return '-'
                                  }
                                })()
                              : '-'}
                          </td>

                          {selectMailType === 0 && (
                            <td className="width-160 th-cell">
                              {row?.accept_time || '-'}
                            </td>
                          )}
                          <td className="width-160 th-cell">
                            {row.emails && row.emails.length === 0 && (
                              <div
                                className="tooltip-container"
                                onMouseEnter={() => setHoveredRow(row.aid)}
                                onMouseLeave={() => setHoveredRow(null)}
                              >
                                <button
                                  className={`btn btn-success ${
                                    !checkInstallmentCondition(row)
                                      ? 'disabled'
                                      : ''
                                  }`}
                                  onClick={() => {
                                    if (checkInstallmentCondition(row)) {
                                      setSelectedStudent(row.aid)
                                      setOpenSendMailPopup(true)
                                    }
                                  }}
                                >
                                  Send
                                </button>
                                {!checkInstallmentCondition(row) &&
                                  hoveredRow === row.aid && (
                                    <span className="tooltip-text ms-2">
                                      {getInstallmentTooltip(row)}
                                    </span>
                                  )}
                              </div>
                            )}
                            {row.emails &&
                              row.emails.length > 0 &&
                              (() => {
                                const filteredEmails = row.emails.filter(
                                  (email) => email.type === selectMailType
                                )
                                return filteredEmails.length > 0 &&
                                  filteredEmails[0].createdAt ? (
                                  <>
                                    <div style={{ display: 'flex' }}>
                                      <span
                                        className="text-muted"
                                        style={{ marginRight: '10px' }}
                                      >
                                        Sent on{' '}
                                        {moment(
                                          filteredEmails[0].createdAt
                                        ).format('DD MMM YYYY')}
                                      </span>
                                      <Tooltip title={'Resend'} arrow>
                                        <div>
                                          <img
                                            style={{
                                              height: '24px',
                                              width: '24px',
                                              borderRadius: '4px',
                                              background: '#377080',
                                              cursor: 'pointer',
                                              marginTop: '4px',
                                              padding: '2px',
                                            }}
                                            src="https://webcdn.imarticus.org/Placement/pajamas_redo1.svg"
                                            onClick={() => {
                                              setSelectedStudent(row.aid)
                                              setOpenSendMailPopup(true)
                                            }}
                                          ></img>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-success"
                                    onClick={() => {
                                      setSelectedStudent(row.aid)
                                      setOpenSendMailPopup(true)
                                    }}
                                  >
                                    Send
                                  </button>
                                )
                              })()}
                          </td>
                          <td className="width-160 th-cell">
                            {row.emails && row.emails.length > 0
                              ? (() => {
                                  const filteredEmails = row.emails.filter(
                                    (email) => email.type === selectMailType
                                  )

                                  const lastEmail = filteredEmails[0] // Get the last email
                                  console.log('Last Email:', lastEmail) // Log last email to console

                                  return (
                                    lastEmail && (
                                      <>
                                        <img
                                          style={{
                                            height: '32px',
                                            width: '32px',
                                            borderRadius: '3px',
                                            background: '#28A745',
                                            cursor: 'pointer',
                                            padding: '2px',
                                          }}
                                          src="https://webcdn.imarticus.org/Placement/lets-icons_view.svg"
                                          onClick={() => {
                                            fetchDataForPrevSent(
                                              row.aid,
                                              lastEmail
                                            )
                                          }}
                                        />
                                      </>
                                    )
                                  )
                                })()
                              : null}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {totalPages > 0 && (
                  <Pagination
                    count={totalPages} // Total number of pages
                    page={page} // Currently active page
                    onChange={handleChange} // Handle page change
                    variant="outlined" // Outlined style
                    shape="rounded" // Rounded pagination
                    size="medium" // Small size
                    showFirstButton // Show First Page button
                    showLastButton // Show Last Page button
                    renderItem={(item) => <PaginationItem {...item} />}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '16px',
                    }}
                  />
                )}
              </div>

              {selectedRows.length > 0 && (
                <div className="btch-dt-foot-main">
                  <div className="btch-dt-foot-cnt">
                    {selectedRows.length} Students Selected
                  </div>
                  <div className="btch-dt-foot-btn">
                    <button
                      className="btch-dt-foot-btn1"
                      onClick={() => {
                        setOpenBulkSendMailPopup(true)
                        handleOpenBulkPopup()
                      }}
                    >
                      {selectMailType === 0
                        ? 'Send Offer Letter'
                        : 'Send Welcome Email'}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="job-not-found">
            <img src="https://webcdn.imarticus.org/demo/Group4606.svg"></img>
            <div style={{ marginBottom: '1rem' }}>
              <br></br>
              {data.length === 0 &&
              selectedBatch.name != '' &&
              noLearnerMessage != '' ? (
                noLearnerMessage
              ) : (
                <h6>Please select all filters to get data</h6>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="modal-parent">
        <Modal
          open={openSendMailPopup}
          onClose={() => setOpenSendMailPopup(false)}
          className="send-offer-popup"
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: '80%',
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
            }}
          >
            <div className="flex-100-spbtwn">
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="offer-letter-heading"
              >
                {selectMailType === 0
                  ? 'Send Offer Letter'
                  : 'Send Welcome Email'}
              </Typography>

              <img
                src="https://webcdn.imarticus.org/Placement/Group926.svg"
                style={{
                  paddingRight: '7px',
                  maxWidth: '21px',
                  maxHeight: '21px',
                }}
                onClick={() => {
                  setValidFrom('')
                  setAcceptDays(0)
                  setSelectedRealBatch({})
                  setOpenSendMailPopup(false)
                }}
              />
            </div>
            <div className="flex-100-spbtwn">
              <div className="labs-table-header-2">
                <p
                  style={{ fontSize: '16px', fontWeight: '400', margin: '0px' }}
                >
                  Batch Launch Date
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                </p>
                <input
                  type="date"
                  className="labs-create-new-form-date_input"
                  name="validFrom"
                  value={validFrom}
                  required={true}
                  onChange={(event) => {
                    handleDateInputChnage(event)
                  }}
                  // min={this.state.currentFormattedDate}
                  // max={this.state.finalFormattedDate}
                />
              </div>
              {selectMailType === 0 && (
                <div className="labs-table-header-2">
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      margin: '0px',
                    }}
                  >
                    No. of days to accept offer letter
                    <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                  </p>
                  <input
                    type="number"
                    placeholder="Enter Search Value"
                    value={acceptDays}
                    onChange={(e) => {
                      const value = e.target.value
                      const numericValue = Number(value)
                      if (
                        /^\d+$/.test(value) &&
                        numericValue >= 0 &&
                        numericValue <= 50 &&
                        String(numericValue) === value
                      ) {
                        setAcceptDays(value)
                      }
                    }}
                    className="labs-create-new-form-date_input"
                    // ref={(input) => input && searchJobKey && input.focus()}
                    // style={{ height: "100%" }}
                  />
                </div>
              )}
            </div>
            {selectedBatchType._id === 'PSEUDO' && (
              <div className="flex-100-spbtwn-2">
                <div className="labs-table-header-2">
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      margin: '0px',
                    }}
                  >
                    Select Real Batch
                    <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    <img
                      src="https://webcdn.imarticus.org/webinar/Vector1.svg"
                      style={{ objectFit: 'contain' }}
                      onMouseEnter={() => setHoverImage(true)}
                      onMouseLeave={() => setHoverImage(false)}
                    />
                    {hoverImage ? (
                      <div className="showJobPopupSkill">
                        If you cannot find the batch you are looking for, please
                        reach out to the Student success team to get the batch
                        created
                      </div>
                    ) : (
                      ''
                    )}
                  </p>
                  <Autocomplete
                    disableClearable
                    value={selectedRealBatch && selectedRealBatch.name}
                    onChange={(event, batch) => {
                      realBatchChanged(batch)
                    }}
                    getOptionLabel={(batch) => batch.name || batch}
                    getOptionSelected={(option, test) => option.name === test}
                    id="batchSelector"
                    options={realBatches}
                    disabled={realBatches.length === 0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        placeholder="Select Batch"
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '16px',
                marginBottom: '16px',
                marginRight: '16px',
              }}
            >
              <div>
                <button
                  onClick={() => {
                    fetchDataForPreview(selectedStudent)
                  }}
                  className={`preview-btn ${
                    validFrom === '' ||
                    (selectedBatchType._id === 'PSEUDO' &&
                      Object.keys(selectedRealBatch).length === 0)
                      ? 'disabled'
                      : ''
                  }`}
                  style={{ marginLeft: '8px' }}
                  disabled={
                    validFrom === '' ||
                    (selectedBatchType._id === 'PSEUDO' &&
                      Object.keys(selectedRealBatch).length === 0)
                  }
                >
                  Preview
                </button>

                <button
                  onClick={() => {
                    fetchDataForConfirm(selectedStudent)
                  }}
                  className={`confirm-btn ${
                    validFrom === '' ||
                    (selectedBatchType._id === 'PSEUDO' &&
                      Object.keys(selectedRealBatch).length === 0)
                      ? 'disabled'
                      : ''
                  }`}
                  style={{ marginLeft: '8px' }}
                  disabled={
                    validFrom === '' ||
                    (selectedBatchType._id === 'PSEUDO' &&
                      Object.keys(selectedRealBatch).length === 0)
                  }
                >
                  Confirm & Send
                </button>

                <button
                  onClick={() => {
                    setValidFrom('')
                    setAcceptDays(0)
                    setSelectedRealBatch({})
                    setOpenSendMailPopup(false)
                  }}
                  className="cancel-btn"
                  style={{ marginLeft: '8px' }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={openErrorPopup}
          onClose={() => setOpenErrorPopup(false)}
          className="send-offer-popup"
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: '80%',
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
            }}
          >
            <div className="flex-100-heading" style={{ paddingTop: '32px' }}>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="error-heading"
                style={{ maxWidth: '293px' }}
              >
                {selectMailType === 0
                  ? 'No Offer Letter found for this course'
                  : 'No Welcome email found for this course'}
              </Typography>
            </div>
            <div className="flex-100-heading">
              <img src="https://webcdn.imarticus.org/Placement/Vector.svg"></img>
            </div>
            <div className="flex-100-heading">
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="offer-letter-heading"
              >
                Please contact tech support in case of any queries
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '16px',
                marginBottom: '16px',
                marginRight: '16px',
              }}
            >
              <div style={{ paddingBottom: '40px' }}>
                <button
                  onClick={() => {
                    setOpenErrorPopup(false)
                    // setIsFocused(true);
                  }}
                  className="confirm-btn"
                  style={{ marginLeft: '8px' }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="modal-parent">
        <Modal
          open={openBulkSendMailPopup}
          onClose={() => setOpenBulkSendMailPopup(false)}
          className="send-offer-popup"
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: '80%',
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
            }}
          >
            <div className="flex-100-spbtwn">
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="offer-letter-heading"
              >
                {selectMailType === 0
                  ? 'Send Offer Letter'
                  : 'Send Welcome Email'}
              </Typography>

              <img
                src="https://webcdn.imarticus.org/Placement/Group926.svg"
                style={{
                  paddingRight: '7px',
                  maxWidth: '21px',
                  maxHeight: '21px',
                }}
                onClick={() => {
                  setValidFrom('')
                  setAcceptDays(0)
                  setSelectedRealBatch({})
                  setOpenBulkSendMailPopup(false)
                }}
              />
            </div>
            <div className="flex-100-spbtwn">
              <div className="labs-table-header-2">
                <p
                  style={{ fontSize: '16px', fontWeight: '400', margin: '0px' }}
                >
                  Batch Launch Date
                  <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                </p>
                <input
                  type="date"
                  className="labs-create-new-form-date_input"
                  name="validFrom"
                  value={validFrom}
                  required={true}
                  onChange={(event) => {
                    handleDateInputChnage(event)
                  }}
                  // min={this.state.currentFormattedDate}
                  // max={this.state.finalFormattedDate}
                />
              </div>
              {selectMailType === 0 && (
                <div className="labs-table-header-2">
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      margin: '0px',
                    }}
                  >
                    No. of days to accept offer letter
                    <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                  </p>
                  <input
                    type="number"
                    placeholder="Enter Search Value"
                    value={acceptDays}
                    onChange={(e) => {
                      const value = e.target.value
                      if (/^\d+$/.test(value) && value >= 0 && value <= 50) {
                        setAcceptDays(value)
                      }
                    }}
                    className="labs-create-new-form-date_input"
                    // ref={(input) => input && searchJobKey && input.focus()}
                    // style={{ height: "100%" }}
                  />
                </div>
              )}
            </div>
            {selectedBatchType._id === 'PSEUDO' && (
              <div className="flex-100-spbtwn-2">
                <div className="labs-table-header-2">
                  <p
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      margin: '0px',
                    }}
                  >
                    Select Real Batch
                    <span style={{ color: 'red', fontSize: '18px' }}>*</span>
                    <img
                      src="https://webcdn.imarticus.org/webinar/Vector1.svg"
                      style={{ objectFit: 'contain' }}
                      onMouseEnter={() => setHoverImage(true)}
                      onMouseLeave={() => setHoverImage(false)}
                    />
                    {hoverImage ? (
                      <div className="showJobPopupSkill">
                        If you cannot find the batch you are looking for, please
                        reach out to the Student success team to get the batch
                        created
                      </div>
                    ) : (
                      ''
                    )}
                  </p>
                  <Autocomplete
                    disableClearable
                    value={selectedRealBatch && selectedRealBatch.name}
                    onChange={(event, batch) => {
                      realBatchChanged(batch)
                    }}
                    getOptionLabel={(batch) => batch.name || batch}
                    getOptionSelected={(option, test) => option.name === test}
                    id="batchSelector"
                    options={realBatches}
                    disabled={realBatches.length === 0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        placeholder="Select Batch"
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '16px',
                marginBottom: '16px',
                marginRight: '16px',
              }}
            >
              <div>
                <button
                  onClick={() => {
                    fetchBulkDataForPreview(selectedStudent)
                  }}
                  className={`preview-btn ${
                    validFrom === '' ||
                    (selectedBatchType._id === 'PSEUDO' &&
                      Object.keys(selectedRealBatch).length === 0)
                      ? 'disabled'
                      : ''
                  }`}
                  style={{ marginLeft: '8px' }}
                  disabled={
                    validFrom === '' ||
                    (selectedBatchType._id === 'PSEUDO' &&
                      Object.keys(selectedRealBatch).length === 0)
                  }
                >
                  Preview
                </button>

                <button
                  onClick={() => {
                    fetchBulkDataForConfirm(selectedStudent)
                  }}
                  className={`confirm-btn ${
                    validFrom === '' ||
                    (selectedBatchType._id === 'PSEUDO' &&
                      Object.keys(selectedRealBatch).length === 0)
                      ? 'disabled'
                      : ''
                  }`}
                  style={{ marginLeft: '8px' }}
                  disabled={
                    validFrom === '' ||
                    (selectedBatchType._id === 'PSEUDO' &&
                      Object.keys(selectedRealBatch).length === 0)
                  }
                >
                  Confirm & Send
                </button>

                <button
                  onClick={() => {
                    setValidFrom('')
                    setAcceptDays(0)
                    setSelectedRealBatch({})
                    setOpenBulkSendMailPopup(false)
                  }}
                  className="cancel-btn"
                  style={{ marginLeft: '8px' }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={openSuccessPopup}
          onClose={() => setOpenSuccessPopup(false)}
          className="send-offer-popup"
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: '70%',
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
            }}
          >
            <div className="flex-100-heading">
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="success-heading"
              >
                Success!
              </Typography>
            </div>
            <div className="flex-100-heading">
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="offer-success-text"
              >
                {selectMailType === 0
                  ? 'Offer letter has been successfully sent.'
                  : 'Welcome email has been successfully sent.'}
              </Typography>
            </div>
            <div className="flex-100-heading">
              <img src="https://webcdn.imarticus.org/Placement/Ljg0Cv.tif_1_.svg"></img>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '16px',
                marginBottom: '16px',
                marginRight: '16px',
              }}
            >
              <div>
                <button
                  onClick={() => {
                    setOpenSuccessPopup(false)
                    setOpenSendMailPopup(false)
                    setValidFrom('')
                    setAcceptDays(0)
                    setSelectedRealBatch({})
                    setOpenBulkSendMailPopup(false)
                    fetchLearnersList()
                  }}
                  className="confirm-btn"
                  style={{ marginLeft: '8px' }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="modal-parent">
        <Modal
          open={openSinglePreviewPopup}
          onClose={() => setOpenSinglePreviewPopup(false)}
          className="preview-offer-popup"
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: '100%',
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
            }}
          >
            <div
              className="flex-100-spbtwn"
              style={{
                borderBottom: '1px solid #DEE2E6',
                paddingBottom: '12px',
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="offer-letter-heading"
              >
                {selectMailType === 0
                  ? 'Preview Offer Letter'
                  : 'Preview Welcome Email'}
              </Typography>

              <img
                src="https://webcdn.imarticus.org/Placement/Group926.svg"
                style={{
                  paddingRight: '7px',
                  maxWidth: '21px',
                  maxHeight: '21px',
                }}
                onClick={() => setOpenSinglePreviewPopup(false)}
              />
            </div>
            <div className="flex-100-spbtwn">
              <div className="iframe-div">
                {selectMailType === 0 ? (
                  <iframe
                    srcDoc={generatedTemplate && generatedTemplate}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      maxHeight: '400px',
                      border: '0.5px solid rgba(0, 0, 0, 0.50)',
                      borderRadius: '4px',
                    }}
                    frameBorder="0"
                    title="Offer PDF Preview"
                  />
                ) : (
                  <iframe
                    srcDoc={generatedTemplate && generatedTemplate}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      maxHeight: '400px',
                      border: '0.5px solid rgba(0, 0, 0, 0.50)',
                      borderRadius: '4px',
                    }}
                    frameBorder="0"
                    title="Welcome Email Preview"
                  />
                )}
              </div>
            </div>
            <div className="flex-100-spbtwn">
              <div className="labs-table-header-2">
                <div className="preview-footer-div">
                  <div>
                    <p className="preview-footer-text">
                      Want to edit User’s Set Payment Preferences?{' '}
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#035642',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          window.open(
                            `${window.location.origin}/set_user_payment_preferences`,
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }}
                      >
                        Click Here
                      </span>
                    </p>
                  </div>
                  <div>
                    {/* {validFrom != '' && (
                      <button
                        onClick={() => {
                          setOpenSingleConfirmPopup(true)
                          setOpenSinglePreviewPopup(false)
                          setIsFocused(true)
                        }}
                        className="confirm-btn"
                        style={{ marginLeft: '8px' }}
                        disabled={
                          validFrom === '' ||
                          (selectedBatchType._id === 'PSEUDO' &&
                            Object.keys(selectedRealBatch).length === 0)
                        }
                      >
                        Confirm & Send
                      </button>
                    )} */}
                    <button
                      onClick={() => {
                        setOpenSinglePreviewPopup(false)
                        setIsFocused(true)
                      }}
                      className="cancel-btn"
                      style={{ marginLeft: '8px' }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={openSingleConfirmPopup}
          onClose={() => setOpenSingleConfirmPopup(false)}
          className="preview-offer-popup"
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: '100%',
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
            }}
          >
            <div className="flex-100-spbtwn">
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="offer-letter-heading"
              >
                Confirm & Send
              </Typography>

              <img
                src="https://webcdn.imarticus.org/Placement/Group926.svg"
                style={{
                  paddingRight: '7px',
                  maxWidth: '21px',
                  maxHeight: '21px',
                }}
                onClick={() => setOpenSingleConfirmPopup(false)}
              />
            </div>
            <div className="flex-100-center">
              <h4 className="confirm-sure-heading">ARE YOU SURE?</h4>
            </div>
            <div className="flex-100-spbtwn">
              <div className="iframe-div">
                {selectMailType === 0 ? (
                  <iframe
                    srcDoc={generatedTemplate && generatedTemplate}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      maxHeight: '400px',
                      border: '0.5px solid rgba(0, 0, 0, 0.50)',
                      borderRadius: '4px',
                    }}
                    frameBorder="0"
                    title="Offer PDF Preview"
                  />
                ) : (
                  <iframe
                    srcDoc={generatedTemplate && generatedTemplate}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      maxHeight: '400px',
                      border: '0.5px solid rgba(0, 0, 0, 0.50)',
                      borderRadius: '4px',
                    }}
                    frameBorder="0"
                    title="Welcome Email Preview"
                  />
                )}
              </div>
            </div>
            <div className="flex-100-spbtwn">
              <div className="labs-table-header-2">
                <div className="confirm-footer-div">
                  <button
                    onClick={() => {
                      sendMailToSingleStudent()
                    }}
                    className="confirm-btn"
                    style={{ marginLeft: '8px' }}
                  >
                    Send
                  </button>

                  <button
                    onClick={() => {
                      setOpenSingleConfirmPopup(false)
                      setIsFocused(true)
                    }}
                    className="cancel-btn"
                    style={{ marginLeft: '8px' }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={openMultiplePreviewPopup}
          onClose={() => setOpenMultiplePreviewPopup(false)}
          className="preview-offer-popup"
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: '100%',
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
            }}
          >
            <div
              className="flex-100-spbtwn"
              style={{
                borderBottom: '1px solid #DEE2E6',
                paddingBottom: '12px',
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="offer-letter-heading"
              >
                {selectMailType === 0
                  ? 'Preview Offer Letter'
                  : 'Preview Welcome Email'}
              </Typography>

              <img
                src="https://webcdn.imarticus.org/Placement/Group926.svg"
                style={{
                  paddingRight: '7px',
                  maxWidth: '21px',
                  maxHeight: '21px',
                }}
                onClick={() => {
                  setOpenMultiplePreviewPopup(false)
                }}
              />
            </div>
            <div className="flex-100-spbtwn">
              <p className="student-detail-title-text">Student Details</p>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="preview-first-div">
                <div className="flex-100" style={{ paddingLeft: '16px' }}>
                  <div className="preview-detail-heading">
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Name:
                      </span>{' '}
                      <p className="student-detail-detail-text">
                        {data.find(
                          (item) => String(item.aid) === selectedStudent
                        )?.name || '-'}
                      </p>
                    </div>
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Student Phone Number:
                      </span>
                      <p className="student-detail-detail-text">
                        {data.find((item) => item.aid === selectedStudent)?.m ||
                          '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-100" style={{ paddingLeft: '16px' }}>
                  <div className="preview-detail-heading">
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Student UID:
                      </span>{' '}
                      <p className="student-detail-detail-text">
                        {data.find((item) => item.aid === selectedStudent)
                          ?.uid || '-'}
                      </p>
                    </div>
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Student Email:
                      </span>{' '}
                      <p className="student-detail-detail-text">
                        {data.find((item) => item.aid === selectedStudent)
                          ?.email || '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-100" style={{ paddingLeft: '16px' }}>
                  <div className="preview-detail-heading">
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Course:
                      </span>
                      <p className="student-detail-detail-text">
                        {selectedCourse.name}
                      </p>
                    </div>
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Batch:
                      </span>
                      <p className="student-detail-detail-text">
                        {selectedBatch.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="preview-second-div">
                <div
                  className="img-icon"
                  onClick={handlePrevious}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="https://webcdn.imarticus.org/Placement/ci_chevron-up.svg" />
                </div>
                <div
                  className="img-icon"
                  onClick={handleNext}
                  style={{ marginLeft: '4px', cursor: 'pointer' }}
                >
                  <img src="https://webcdn.imarticus.org/Placement/ci_chevron-up1.svg" />
                </div>
              </div>
            </div>

            <div className="flex-100-spbtwn">
              <div className="iframe-div">
                {selectMailType === 0 ? (
                  <iframe
                    srcDoc={generatedTemplate && generatedTemplate}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      maxHeight: '400px',
                      border: '0.5px solid rgba(0, 0, 0, 0.50)',
                      borderRadius: '4px',
                    }}
                    frameBorder="0"
                    title="Offer PDF Preview"
                  />
                ) : (
                  <iframe
                    srcDoc={generatedTemplate && generatedTemplate}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      maxHeight: '400px',
                      border: '0.5px solid rgba(0, 0, 0, 0.50)',
                      borderRadius: '4px',
                    }}
                    frameBorder="0"
                    title="Welcome Email Preview"
                  />
                )}
              </div>
            </div>
            <div className="flex-100-spbtwn">
              <div className="labs-table-header-2">
                <div className="preview-footer-div">
                  <div>
                    <p className="preview-footer-text">
                      Want to edit User’s Set Payment Preferences?{' '}
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#035642',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          window.open(
                            `${window.location.origin}/set_user_payment_preferences`,
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }}
                      >
                        Click Here
                      </span>
                    </p>
                  </div>

                  <div>
                    {/* {validFrom != '' && (
                      <button
                        onClick={() => {
                          setOpenMultipleConfirmPopup(true)
                          setOpenMultiplePreviewPopup(false)
                          setIsFocused(true)
                        }}
                        className="confirm-btn"
                        style={{ marginLeft: '8px' }}
                        disabled={
                          validFrom === '' ||
                          (selectedBatchType._id === 'PSEUDO' &&
                            Object.keys(selectedRealBatch).length === 0)
                        }
                      >
                        Confirm & Send
                      </button>
                    )} */}
                    <button
                      onClick={() => {
                        setOpenMultiplePreviewPopup(false)
                        setIsFocused(true)
                      }}
                      className="cancel-btn"
                      style={{ marginLeft: '8px' }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={openMultipleConfirmPopup}
          onClose={() => setOpenMultipleConfirmPopup(false)}
          className="preview-offer-popup"
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: '100%',
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '8px',
            }}
          >
            <div
              className="flex-100-spbtwn"
              style={{
                borderBottom: '1px solid #DEE2E6',
                paddingBottom: '12px',
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                className="offer-letter-heading"
              >
                {selectMailType === 0
                  ? 'Preview Offer Letter'
                  : 'Preview Welcome Email'}
              </Typography>

              <img
                src="https://webcdn.imarticus.org/Placement/Group926.svg"
                style={{
                  paddingRight: '7px',
                  maxWidth: '21px',
                  maxHeight: '21px',
                }}
                onClick={() => {
                  setOpenMultipleConfirmPopup(false)
                }}
              />
            </div>
            <div className="flex-100-center">
              <h4 className="confirm-sure-heading">ARE YOU SURE?</h4>
            </div>
            <div className="flex-100-spbtwn">
              <p className="student-detail-title-text">Student Details</p>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="preview-first-div">
                <div className="flex-100" style={{ paddingLeft: '16px' }}>
                  <div className="preview-detail-heading">
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Name:
                      </span>{' '}
                      <p className="student-detail-detail-text">
                        {data.find(
                          (item) => String(item.aid) === selectedStudent
                        )?.name || '-'}
                      </p>
                    </div>
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Student Phone Number:
                      </span>
                      <p className="student-detail-detail-text">
                        {data.find((item) => item.aid === selectedStudent)?.m ||
                          '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-100" style={{ paddingLeft: '16px' }}>
                  <div className="preview-detail-heading">
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Student UID:
                      </span>{' '}
                      <p className="student-detail-detail-text">
                        {data.find((item) => item.aid === selectedStudent)
                          ?.uid || '-'}
                      </p>
                    </div>
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Student Email:
                      </span>{' '}
                      <p className="student-detail-detail-text">
                        {data.find((item) => item.aid === selectedStudent)
                          ?.email || '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-100" style={{ paddingLeft: '16px' }}>
                  <div className="preview-detail-heading">
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Course:
                      </span>
                      <p className="student-detail-detail-text">
                        {selectedCourse.name}
                      </p>
                    </div>
                    <div className="preview-detail-detail-div">
                      <span className="student-detail-title-heading">
                        Batch:
                      </span>
                      <p className="student-detail-detail-text">
                        {selectedBatch.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="preview-second-div">
                <div
                  className="img-icon"
                  onClick={handlePrevious}
                  style={{ cursor: 'pointer' }}
                >
                  <img src="https://webcdn.imarticus.org/Placement/ci_chevron-up.svg" />
                </div>
                <div
                  className="img-icon"
                  onClick={handleNext}
                  style={{ marginLeft: '4px', cursor: 'pointer' }}
                >
                  <img src="https://webcdn.imarticus.org/Placement/ci_chevron-up1.svg" />
                </div>
              </div>
            </div>

            <div className="flex-100-spbtwn">
              <div className="iframe-div">
                {selectMailType === 0 ? (
                  <iframe
                    srcDoc={generatedTemplate && generatedTemplate}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      maxHeight: '400px',
                      border: '0.5px solid rgba(0, 0, 0, 0.50)',
                      borderRadius: '4px',
                    }}
                    frameBorder="0"
                    title="Offer PDF Preview"
                  />
                ) : (
                  <iframe
                    srcDoc={generatedTemplate && generatedTemplate}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      maxHeight: '400px',
                      border: '0.5px solid rgba(0, 0, 0, 0.50)',
                      borderRadius: '4px',
                    }}
                    frameBorder="0"
                    title="Welcome Email Preview"
                  />
                )}
              </div>
            </div>
            <div className="flex-100-spbtwn">
              <div className="labs-table-header-2">
                <div className="preview-footer-div">
                  <div>
                    <p className="preview-footer-text">
                      Want to edit User’s Set Payment Preferences?{' '}
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#035642',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          window.open(
                            `${window.location.origin}/set_user_payment_preferences`,
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }}
                      >
                        Click Here
                      </span>
                    </p>
                  </div>

                  <div>
                    {validFrom != '' && (
                      <button
                        onClick={() => {
                          sendMailToMultipleStudent()
                        }}
                        className="confirm-btn"
                        style={{ marginLeft: '8px' }}
                        disabled={
                          validFrom === '' ||
                          (selectedBatchType._id === 'PSEUDO' &&
                            Object.keys(selectedRealBatch).length === 0)
                        }
                      >
                        Send
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setOpenMultipleConfirmPopup(false)
                        setIsFocused(true)
                      }}
                      className="cancel-btn"
                      style={{ marginLeft: '8px' }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardEmail)
