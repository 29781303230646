import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Grid,
  Tooltip,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  DialogContent,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  FormControlLabel,
  Switch,
  TablePagination,
  DialogActions,
  Dialog,
  DialogTitle,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import momentjs from 'moment'
import {
  addDeposit,
  getAllPossibleLocations,
  getCashCollectedStudents,
  uploadDocumentsToAws,
  getSignedS3Url,
} from '../../actions/general'

let CreateChequeDeposits = (props) => {
  let selectedLocationData = []
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedBank, setSelectedBank] = useState('')
  const [studentList, setStudentList] = useState({})
  const [filters, setFilters] = useState({
    uid: '',
    name: '',
    mobbile: '',
    courseName: '',
    fromDate: '',
    toDate: '',
  })
  const [filterType, setFilterType] = useState('uid')
  const [courseNameList, setCourseNameList] = useState([])
  const [totalCount, setTotalCount] = useState(10)
  const [createPopUp, setCreatePopUp] = useState(false)
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false)
  const [confirmPopUp, setConfirmPopUp] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [depositDate, setDepositDate] = useState()
  const [depositAmount, setDepositAmount] = useState(0)
  const [selectedRows, setSelectedRows] = useState([])
  const [file, setFile] = useState(null)
  const [s3FileUrl, setS3FileUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [openAttachment, setOpenAttachment] = useState(false)
  const [fileUrl, setFileUrl] = useState('')

  useEffect(() => {
    fetchLocations()
  }, [])

  const addCashDeposit = async () => {
    setCreatePopUp(false)
    if (
      isNaN(depositAmount) ||
      (depositAmount && depositAmount != fetchAmount())
    ) {
      window.alert('Please enter a valid deposit amount')
    }
    console.log({ selectedBank, selectedLocation, selectedRows })
    let paymentIds = []
    if (selectedRows.length > 0) {
      selectedRows.forEach((dt) => {
        paymentIds.push(dt._id)
      })
      const { success, message, ...data } = await addDeposit({
        amount: depositAmount,
        doc: s3FileUrl,
        type: 2,
        accountNumber: selectedBank,
        depositDate,
        locationId: selectedLocation,
        paymentIds,
      })
      if (success == false) {
        if (message) {
          window.alert(message)
        } else {
          window.alert('Something went wrong. Please try again.')
        }
        return
      } else {
        setOpenSuccessPopup(true)
        console.log()
        console.log({ data })
      }
    }
  }

  let fetchLocations = async () => {
    // setLoading(true)
    let { success, message, ...data } = await getAllPossibleLocations()
    // setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { allLocations } = data.data
    console.log({ allLocations, data })
    setLocations(allLocations)
  }

  selectedLocationData = locations.find((loc) => loc._id === selectedLocation)
  console.log({ selectedLocationData })

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value)
    setSelectedBank('') // Reset bank account selection
  }

  const handleBankChange = (event) => {
    setSelectedBank(event.target.value)
  }

  const getStudentList = async () => {
    let { success, message, ...data } = await getCashCollectedStudents({
      centerLocation: selectedLocation,
      type: 2,
    })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { studentList } = data.data
    let tempCourseName = []
    Object.values(studentList).forEach((dt) => {
      if (!tempCourseName.includes(dt['courseName'])) {
        tempCourseName.push(dt['courseName'])
      }
    })
    setCourseNameList(tempCourseName)
    setStudentList(studentList)
  }

  const filteredData = Object.values(studentList).filter((row) => {
    const depositDate = new Date(row['date']).setHours(0, 0, 0, 0) // Remove time

    const fromDate = filters.fromDate
      ? new Date(filters.fromDate).setHours(0, 0, 0, 0)
      : null
    const toDate = filters.toDate
      ? new Date(filters.toDate).setHours(0, 0, 0, 0)
      : null

    return (
      (filters[filterType] === '' || row[filterType] == filters[filterType]) &&
      (filters.courseName === '' || row.courseName === filters.courseName) &&
      (!fromDate || depositDate >= fromDate) &&
      (!toDate || depositDate <= toDate)
    )
  })
  let getDate = (date) => {
    let tz = momentjs.tz.guess()
    let Date = momentjs.tz(date, tz).valueOf()
    let formatedString = momentjs(Date).format('LL')
    return formatedString
  }
  const handleFilterChange = (name, value) => {
    setFilters({ ...filters, [name]: value })
  }

  const handlePDFUpload = async (e) => {
    if (e.target.files[0]) {
      console.log(e.target.files[0])
      setFile(e.target.files[0])
      await uploadDocuments(e.target.files[0])
    }
  }
  const handleSelectRow = (id, row) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some(
        (selectedRow) => selectedRow._id === id
      )
      if (isSelected) {
        // Remove row if already selected
        return prevSelectedRows.filter((selectedRow) => selectedRow._id !== id)
      } else {
        // Add row to selected rows
        return [...prevSelectedRows, row]
      }
    })
  }

  const uploadDocuments = async function (document) {
    console.log({ document })
    const validTypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/pdf',
    ]
    const maxSize = 5 * 1024 * 1024 // 5MB in bytes
    let validFile = true
    if (!validTypes.includes(document.type)) {
      validFile = false
      window.alert(
        'Invalid file type. Please select a PNG, JPEG, JPG or PDF file.'
      )
      return
    }
    if (document.size > maxSize) {
      window.alert('File size exceeds the maximum limit of 5MB.')
      return
    }
    if (validFile) {
      setLoading(true)
      uploadDocumentsToAws(document)
        .then(({ data }) => {
          console.log({ DataInAxiosThen: data })
          setS3FileUrl(data.data)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    }
  }

  const fetchAmount = () => {
    let amount = 0
    selectedRows.forEach((dt) => {
      amount += dt.amount
    })
    return amount
  }
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all rows
      setSelectedRows(filteredData)
    } else {
      // Deselect all
      setSelectedRows([])
    }
  }

  const isAllSelected = selectedRows.length === filteredData.length
  const handlePageChange = (_, newPage) => setPage(newPage)
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleView = async (file) => {
    setLoading(true)
    let { success, message, ...data } = await getSignedS3Url({
      m_url: file,
    })
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { signed_url } = data
    console.log({ signed_url })
    setOpenAttachment(true)
    setFileUrl(signed_url)
  }

  const fetchSelectedLocation = () => {
    let tempLocation = {}
    locations.forEach((dt) => {
      if (dt._id == selectedLocation) {
        tempLocation = dt
      }
    })
    return `${tempLocation.name}, ${tempLocation.city}, ${tempLocation.country}`
  }

  return (
    <div style={{ padding: '24px' }}>
      <div
        style={{
          fontSize: '28px',
          fontWeight: '700',
          lineHeight: '32px',
          color: '#575757',
        }}
      >
        Create Bulk Cheque Deposit
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
          marginTop: '24px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '12px',
            width: '100%',
            flexWrap: 'wrap',
            rowGap: '32px',
          }}
        >
          <FormControl
            sx={{
              width: '49%',
              backgroundColor: 'white',
            }}
          >
            <InputLabel>Select a Branch Location*</InputLabel>
            <Select
              Select
              value={selectedLocation}
              onChange={handleLocationChange}
              label="Select a Branch Location*"
            >
              {locations.length > 0 &&
                locations.map((loc) => (
                  <MenuItem key={loc._id} value={loc._id}>
                    {`${loc.name}, ${loc.city}, ${loc.country}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: '49%',
              backgroundColor: 'white',
            }}
            disabled={
              !selectedLocation ||
              selectedLocationData?.bankAccounts.length === 0
            }
          >
            <InputLabel>Select a Bank Account*</InputLabel>
            <Select
              label="Select a Bank Account*"
              Select
              value={selectedBank}
              onChange={handleBankChange}
            >
              {selectedLocationData?.bankAccounts?.map((bank, index) => (
                <MenuItem key={index} value={bank}>
                  {bank}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            sx={{
              width: '49%',
              backgroundColor: 'white',
              height: 'fit-content',
            }}
            label="Transaction Date*"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={depositDate}
            onChange={(e) => setDepositDate(e.target.value)}
          />
          <Button
            component="label"
            sx={{
              width: '49%',
              backgroundColor: 'white',
              justifyContent: 'flex-start',
              textAlign: 'left',
              paddingLeft: 2,
            }}
          >
            <div
              style={{
                display: 'flex',
                padding: '4px 8px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div
                style={{
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #212A39',
                  backgroundColor: '#EFEFEF',
                  color: '#3c4852',
                  fontSize: '12px',
                  fontWeight: '600',
                  lineHeight: '16px',
                }}
              >
                Choose File* - jpeg, jpg, pdf
              </div>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: '#3c4852',
                  wordBreak: 'break-all',
                }}
              >
                {file ? `${file.name}` : 'no file chosen'}

                <span style={{ color: 'red' }}>{` (Upload Bank Receipt)`}</span>
              </div>
            </div>
            <input
              type="file"
              hidden
              accept="application/pdf"
              onChange={(e) => handlePDFUpload(e)}
            />
          </Button>
        </div>
      </div>
      <div
        style={{
          cursor:
            selectedLocation && selectedBank && depositDate && file
              ? 'pointer'
              : 'not-allowed',
          padding: '12px 16px',
          borderRadius: '8px',
          backgroundColor:
            selectedLocation && selectedBank && depositDate && file
              ? 'rgba(40,167,69,1)'
              : 'rgba(40,167,69,0.4)',
          color: 'white',
          width: 'fit-content',
        }}
        onClick={() => {
          if (selectedLocation && selectedBank && depositDate && file) {
            getStudentList()
          }
        }}
      >
        Add Learner Cheque Payment
      </div>
      {Object.keys(studentList).length > 0 && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '24px',
              marginTop: '24px',
              gap: '12px',
            }}
          >
            <div style={{ display: 'flex', gap: '12px' }}>
              {/* <FormControl sx={{ width: 200 }}>
            <InputLabel>Bank Account</InputLabel>
            <Select
              label="Bank Account"
              value={filters.bankAccount}
              onChange={(e) =>
                handleFilterChange("bankAccount", e.target.value)
              }
            >
              <MenuItem value="">All</MenuItem>
              {bankAccount.map((bank) => (
                <MenuItem key={bank} value={bank}>
                  {bank}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
              <FormControl sx={{ width: 200 }}>
                <InputLabel>Search Via</InputLabel>
                <Select
                  label="Search Via"
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                >
                  <MenuItem value="uid">UID</MenuItem>
                  <MenuItem value="mobile">Mobile</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: 200 }}>
                <TextField
                  sx={{
                    fontSize: '12px',
                    width: '99%',
                  }}
                  fullWidth
                  label={`Select ${filterType}`}
                  value={filters[`${filterType}`]}
                  onChange={(e) => {
                    handleFilterChange(`${filterType}`, e.target.value)
                  }}
                />
              </FormControl>
            </div>
            <div style={{ display: 'flex', gap: '12px' }}>
              <FormControl sx={{ width: 300 }}>
                <InputLabel>Select a Course Name</InputLabel>
                <Select
                  label="Select a Course Name"
                  value={filters.courseName}
                  onChange={(e) =>
                    handleFilterChange('courseName', e.target.value)
                  }
                >
                  <MenuItem value="">All</MenuItem>
                  {courseNameList.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                sx={{ width: 200 }}
                label="Filter From"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={filters.fromDate}
                onChange={(e) => handleFilterChange('fromDate', e.target.value)}
              />

              <TextField
                sx={{ width: 200 }}
                label="Filter To"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={filters.toDate}
                onChange={(e) => handleFilterChange('toDate', e.target.value)}
              />
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{
              marginTop: '32px',
              width: '100%',
              overflowX: 'auto',
              border: '1px solid #ccc',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 0,
                      background: '#f4f4f4',
                      zIndex: 3,
                      width: '50px',
                      minWidth: '50px',
                      border: '1px solid #ccc',
                    }}
                  >
                    <Checkbox
                      checked={
                        selectedRows.length === filteredData.length &&
                        filteredData.length > 0
                      }
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < filteredData.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 50,
                      background: '#f4f4f4',
                      zIndex: 3,
                      width: '50px',
                      minWidth: '50px',
                      border: '1px solid #ccc',
                    }}
                  >
                    S.No.
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 100,
                      background: '#f4f4f4',
                      zIndex: 3,
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Learner UID
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 350,
                      background: '#f4f4f4',
                      zIndex: 3,
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Learner Name
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 600,
                      background: '#f4f4f4',
                      zIndex: 3,
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Course Name
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Batch Name
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Payment Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Payment Date
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Payment Type
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Payment Mode
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                    }}
                  >
                    Comment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '50px',
                        minWidth: '50px',
                        border: '1px solid #ccc',
                      }}
                    >
                      <Checkbox
                        checked={selectedRows.some(
                          (selectedRow) => selectedRow._id === row._id
                        )}
                        onChange={() => handleSelectRow(row._id, row)}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 50,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '50px',
                        minWidth: '50px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 100,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {row['uid']}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 350,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {row['name']}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 600,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {row['mobile']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {row['courseName']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {row['batchName']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {row['amount']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {getDate(row['date'])}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {row['type']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {row['mode']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {row['comment']}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: '32px' }}>
            <span
              style={{
                color: '#575757',
                fontSiz: '16px',
                fontWeight: '600',
                lineHeight: '24px',
              }}
            >
              Total amount for selected learners:
            </span>
            <span
              style={{
                color: '#035642',
                fontSiz: '16px',
                fontWeight: '700',
                lineHeight: '24px',
              }}
            >
              {fetchAmount()}
            </span>
            <Tooltip
              title="This amount is the total amount you have selected above."
              arrow
            >
              <IconButton size="small">
                <InfoIcon
                  sx={{ color: 'rgba(60, 72, 82, 0.40)', fontSize: '16px' }}
                />
              </IconButton>
            </Tooltip>
          </div>
          <div
            style={{
              marginTop: '32px',
              display: 'flex',
              flexDirection: 'row-reverse',
              gap: '12px',
            }}
          >
            <div
              style={{
                cursor: 'pointer',
                backgroundColor: '#DC3545',
                padding: '16px 24px',
                borderRadius: '8px',
                color: 'white',
                width: '160px',
                textAlign: 'center',
                verticalAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                window.history.back()
              }}
            >
              Cancel Cheque Deposit Date
            </div>
            <div
              onClick={() => {
                if (selectedRows.length > 0) {
                  setCreatePopUp(true)
                }
              }}
              style={{
                backgroundColor:
                  selectedRows.length > 0
                    ? 'rgba(3,86,66,1)'
                    : 'rgba(3,86,66,0.4)',
                padding: '16px 24px',
                borderRadius: '8px',
                color: 'white',
                width: '160px',
                textAlign: 'center',
                cursor: selectedRows.length > 0 ? 'pointer' : 'not-allowed',
                verticalAlign: 'center',
              }}
            >
              Assign Cheque Deposit Date{' '}
            </div>
          </div>
        </>
      )}
      <Dialog
        open={createPopUp}
        onClose={() => {
          setCreatePopUp(false)
        }}
        maxWidth="md"
        fullWidth
      >
        <div style={{ padding: '24px' }}>
          <div
            style={{
              color: '#A09FA0',
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '32px',
            }}
          >
            Do you want to create this Cash deposit?
          </div>
          <div style={{ marginTop: '24px' }}>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Branch Location: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {fetchSelectedLocation()}
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Bank Account: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {selectedBank}
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Bank Deposit Date: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {getDate(depositDate)}
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Uploaded Bank Receipt: '}
              </span>
              <span
                style={{
                  textUnderlinePosition: 'from-font',
                  textDecorationLine: 'underline',
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                  cursor: 'pointer',
                  textUnderlineOffset: 'auto',
                }}
                onClick={() => {
                  handleView(s3FileUrl)
                }}
              >
                View Attachment
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Deposit Amount: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {depositAmount}
              </span>
            </div>
            {/* <div style={{ marginBottom: "4px" }}>
              <span
                style={{
                  color: "#212A39",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                {"Selected Learners: "}
              </span>
              <span
                style={{
                  color: "#035642",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  cursor: "pointer",
                }}
              >
                View List
              </span>
            </div> */}
          </div>
          <div style={{ padding: '24px' }}>
            <div
              style={{
                color: '#3c4852',
                fontSize: '20px',
                fontWeight: '600',
                lineHeight: '24px',
              }}
            >
              List of learner payments added into this deposit
            </div>
            <TableContainer
              component={Paper}
              sx={{
                marginTop: '32px',
                width: '100%',
                overflowX: 'auto',
                border: '1px solid #ccc',
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '50px',
                        minWidth: '50px',
                        border: '1px solid #ccc',
                      }}
                    >
                      <Checkbox
                        checked={
                          selectedRows.length === filteredData.length &&
                          filteredData.length > 0
                        }
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < filteredData.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 50,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '50px',
                        minWidth: '50px',
                        border: '1px solid #ccc',
                      }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 100,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Learner UID
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 250,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Learner Name
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 400,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Mobile
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Batch Name
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Payment Amount
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Payment Date
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Payment Type
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Payment Mode
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Comment
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRows.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 0,
                          background: '#f4f4f4',
                          zIndex: 3,
                          width: '50px',
                          minWidth: '50px',
                          border: '1px solid #ccc',
                        }}
                      >
                        <Checkbox
                          checked={selectedRows.some(
                            (selectedRow) => selectedRow._id === row._id
                          )}
                          onChange={() => handleSelectRow(row._id, row)}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 50,
                          background: '#f4f4f4',
                          zIndex: 3,
                          width: '50px',
                          minWidth: '50px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 100,
                          background: '#f4f4f4',
                          zIndex: 3,
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['uid']}
                      </TableCell>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 250,
                          background: '#f4f4f4',
                          zIndex: 3,
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['name']}
                      </TableCell>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 400,
                          background: '#f4f4f4',
                          zIndex: 3,
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['mobile']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['courseName']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['batchName']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['amount']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {getDate(row['date'])}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['type']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['mode']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['comment']}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <DialogActions>
            <button
              style={{
                width: '148px',
                cursor: 'pointer',
                borderRadius: '8px',
                padding: '12px 24px',
                backgroundColor: '#035642',
                color: 'white',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
              }}
              onClick={() => {
                addCashDeposit()
              }}
              color="primary"
            >
              Confirm
            </button>
            <button
              style={{
                width: '148px',
                cursor: 'pointer',
                borderRadius: '8px',
                padding: '12px 24px',
                backgroundColor: '#DC3545',
                color: 'white',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
              }}
              onClick={() => {
                setCreatePopUp(false)
              }}
              color="primary"
            >
              Close
            </button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={openSuccessPopup}
        onClose={() => {
          setOpenSuccessPopup(false)
        }}
        maxWidth="sm"
        fullWidth
      >
        {/* Title */}
        <DialogTitle>
          <div style={{ margin: '40px' }}>
            <div
              style={{
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                color: '#3c4852',
                textAlign: 'center',
                marginTop: '16px',
              }}
            >
              New Cheque deposit has been successfully created!
            </div>
            <div style={{ textAlign: 'center', margin: '40px' }}>
              <img
                style={{ height: '160px', width: '160px' }}
                src="https://cdn.pegasus.imarticus.org/payments/paid.svg"
                alt="logo"
                className="as-dl-logo-hp"
              />
            </div>
            <div style={{ margin: '40px', textAlign: 'center' }}>
              <button
                onClick={() => {
                  setOpenSuccessPopup(false)
                  window.location.reload()
                }}
                style={{
                  width: '160px',
                  height: '40px',
                  color: 'white',
                  backgroundColor: '#035642',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  borderColor: 'transparent',
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </DialogTitle>
      </Dialog>
      <Dialog
        open={openAttachment}
        onClose={() => {
          setOpenAttachment(false)
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Attachment Preview
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenAttachment(false)
            }}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <iframe
            src={fileUrl}
            title="PDF Preview"
            width="100%"
            height="500px"
            style={{ border: 'none' }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default CreateChequeDeposits
