import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Tooltip,
} from '@mui/material'
import CustomToolTip from './ToolTip'
import Pagination from '../leadDistribution/Pagination'
import './TableStyles.css'

const sentimentColorMap = {
  1: '#EB8A93', // Medium Red
  2: '#F2C173', // Medium Orange
  3: '#FFFFFF', // Medium Yellow
  4: '#EAF6EC', // Medium Green
  5: '#82CC93', // Medium Light Green
}

const formatDateIST = (isoString) =>
  new Date(isoString).toLocaleString('en-IN', {
    timeZone: 'Asia/Kolkata',
    hour12: true,
  })

const TrackLearnerCallTable = ({
  data,
  queryCategory,
  sentimentScale,
  openSentimentModel,
  handleClick,
  pageCount,
  pagination,
  currentPage,
}) => {
  return (
    <div className="table-wrapper">
      {/* Fixed Columns */}
      <div className="">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="fixed-column sn-column">S.No.</TableCell>
                <TableCell className="fixed-column fixed-column2">
                  Customer Number
                </TableCell>
                <TableCell className="fixed-column fixed-column2">
                  Call Date and Time
                </TableCell>
                <TableCell className="fixed-column fixed-column2">
                  Query Category
                </TableCell>
                <TableCell className="fixed-column poc-column">
                  <p>
                    POC 1 Response
                    {/* <CustomToolTip ttData={"SUCCESS   "}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00016 1.33325C4.32016 1.33325 1.3335 4.31992 1.3335 7.99992C1.3335 11.6799 4.32016 14.6666 8.00016 14.6666C11.6802 14.6666 14.6668 11.6799 14.6668 7.99992C14.6668 4.31992 11.6802 1.33325 8.00016 1.33325ZM8.66683 11.3333H7.3335V7.33325H8.66683V11.3333ZM8.66683 5.99992H7.3335V4.66658H8.66683V5.99992Z"
                          fill="#3C4852"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </CustomToolTip> */}
                  </p>
                </TableCell>
                <TableCell className="fixed-column poc-column">
                  <p>
                    POC 2 Response
                    {/* <CustomToolTip ttData={"SUCCESS   "}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00016 1.33325C4.32016 1.33325 1.3335 4.31992 1.3335 7.99992C1.3335 11.6799 4.32016 14.6666 8.00016 14.6666C11.6802 14.6666 14.6668 11.6799 14.6668 7.99992C14.6668 4.31992 11.6802 1.33325 8.00016 1.33325ZM8.66683 11.3333H7.3335V7.33325H8.66683V11.3333ZM8.66683 5.99992H7.3335V4.66658H8.66683V5.99992Z"
                          fill="#3C4852"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </CustomToolTip> */}
                  </p>
                </TableCell>
                <TableCell className="fixed-column poc-column">
                  <p>
                    POC 3 Response
                    {/* <CustomToolTip ttData={"SUCCESS   "}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00016 1.33325C4.32016 1.33325 1.3335 4.31992 1.3335 7.99992C1.3335 11.6799 4.32016 14.6666 8.00016 14.6666C11.6802 14.6666 14.6668 11.6799 14.6668 7.99992C14.6668 4.31992 11.6802 1.33325 8.00016 1.33325ZM8.66683 11.3333H7.3335V7.33325H8.66683V11.3333ZM8.66683 5.99992H7.3335V4.66658H8.66683V5.99992Z"
                          fill="#3C4852"
                          fill-opacity="0.5"
                        />
                      </svg>
                    </CustomToolTip> */}
                  </p>
                </TableCell>
                <TableCell className="fixed-column response-column">
                  Response Status
                </TableCell>
                <TableCell className="fixed-column response-column">
                  Responer Name
                </TableCell>
                <TableCell className="fixed-column record-column">
                  Recording
                </TableCell>
                <TableCell className="fixed-column fixed-column2">
                  Learner Sentiment
                </TableCell>
                <TableCell className="fixed-column summary-column">
                  AI Summary
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                const callDateTime = new Date(row.startTime)
                const date = callDateTime.toISOString().split('T')[0]
                const time = callDateTime.toTimeString().split(' ')[0]

                if (!row.queryCategory) {
                  return <></>
                }

                return (
                  <TableRow key={row._id || index}>
                    <TableCell className="">
                      {currentPage + index + 1}
                    </TableCell>
                    <CustomToolTip
                      ttData={
                        row.studentDetail
                          ? `Student Name : ${row.studentDetail.name}\nStudent Email : ${row.studentDetail.email}\nCourse : ${row.studentDetail.courseName}\nBatch : ${row.studentDetail.batchName}`
                          : ''
                      }
                    >
                      <TableCell className="">{row.customerNumber}</TableCell>
                    </CustomToolTip>
                    <TableCell className="">
                      {formatDateIST(row.createdAt)}
                      {/* {date} {time} */}
                    </TableCell>
                    <TableCell className="">
                      {queryCategory[row.queryCategory]}
                    </TableCell>

                    {row.agentDisposition && row.agentDisposition.length > 0 ? (
                      <>
                        {row.agentDisposition
                          .slice(0, 3)
                          .map((disposition, i) => (
                            <CustomToolTip
                              ttData={`Agent Name : ${disposition.agentName}\nAgent Email : ${disposition.agentEmail}\nAgent Number : ${disposition.agentNumber}`}
                            >
                              <TableCell
                                key={disposition._id || i}
                                style={
                                  disposition.agentCallDisposition == 'MISSED'
                                    ? {
                                        background: 'rgba(220, 53, 69, 0.16)',
                                        cursor: 'pointer',
                                      }
                                    : { cursor: 'pointer' }
                                }
                                className="agent-hover-class"
                              >
                                {disposition.agentCallDisposition.toLowerCase() ==
                                'call not received'
                                  ? '-'
                                  : disposition.agentCallDisposition}
                              </TableCell>
                            </CustomToolTip>
                          ))}
                        {Array.from(
                          { length: 3 - row.agentDisposition.length },
                          (_, i) => (
                            <TableCell key={`placeholder-${i}`} className="">
                              Not Configured
                            </TableCell>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        <TableCell className="">Not Configured</TableCell>
                        <TableCell className="">Not Configured</TableCell>
                        <TableCell className="">Not Configured</TableCell>
                      </>
                    )}

                    <TableCell
                      className=""
                      style={
                        row.finalCallDisposition == 'MISSED'
                          ? { background: 'rgba(220, 53, 69, 0.16)' }
                          : {}
                      }
                    >
                      {row.finalCallDisposition}
                    </TableCell>
                    <TableCell className="">
                      {row.answeredBy ? row.answeredBy : '-'}
                    </TableCell>
                    <TableCell className="" style={{ height: '50px' }}>
                      {row.callRecording && (
                        <audio controls style={{ height: '28px' }}>
                          <source src={row.callRecording} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      )}
                    </TableCell>

                    <TableCell
                      className=""
                      style={{ background: sentimentColorMap[row.sentiment] }}
                    >
                      {sentimentScale[row.sentiment]}
                    </TableCell>
                    <TableCell className="">
                      {row.isSentimentAnalysisDone ? (
                        <img
                          onClick={() =>
                            openSentimentModel(row.summary, row.actionItems)
                          }
                          className="genAI"
                          src="https://webcdn.imarticus.org/CIB/hugeicons_ai-magic.svg"
                        />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {pagination && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '24px',
              marginBottom: '24px',
            }}
          >
            <Pagination handleClick={handleClick} pageCount={pageCount} />
          </div>
        )}
      </div>

      {/* Scrollable Columns */}
      {/* <div className="scrollable-columns">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Onboarding POC</TableCell>
                <TableCell>Finance POC</TableCell>
                <TableCell>Local Governance POC</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row._id || index}>
                  <TableCell>
                    {row.onboardingPoc.map((poc) => poc.name).join(", ")}
                  </TableCell>
                  <TableCell>
                    {row.financePoc.map((poc) => poc.name).join(", ")}
                  </TableCell>
                  <TableCell>
                    {row.localGovernancePoc.map((poc) => poc.name).join(", ")}
                  </TableCell>
                  <TableCell>
                    <div
                      className="action-btn"
                      onClick={() => editCustomPOCHandler(row)}
                    >
                      Edit POC Details
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div> */}
    </div>
  )
}

export default TrackLearnerCallTable
