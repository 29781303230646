import { Tooltip } from '@mui/material'
const CustomToolTip = ({ children, ttData }) => {
  return (
    <Tooltip
      // title={ttData}
      title={
        <div style={{ whiteSpace: 'pre-line' }}>{ttData}</div> // Key change here
      }
      placement="top"
      arrow
      sx={{
        '& .MuiTooltip-tooltip': {
          backgroundColor: 'black',
          color: 'white',
          fontSize: '1rem',
        },
        '& .MuiTooltip-arrow': {
          color: 'black',
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

export default CustomToolTip
