import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material'
import './TableStyles.css'
import CustomToolTip from './ToolTip'

const ManagePocTable = ({
  data,
  editCustomPOCHandler,
  checkDeepVisibility,
  permissions,
}) => {
  return (
    <div className="table-wrapper">
      {/* Fixed Columns */}

      <div className="fixed-columns">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="fixed-column sn-column">S.No.</TableCell>
                <TableCell className="fixed-column">Course Name</TableCell>
                <TableCell className="fixed-column">Batch Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row._id || index}>
                  <TableCell className="fixed-column">{index + 1}</TableCell>
                  <CustomToolTip ttData={row.courseName}>
                    <TableCell
                      className="fixed-column"
                      style={{ cursor: 'pointer' }}
                    >
                      {row.courseName}
                    </TableCell>
                  </CustomToolTip>
                  <CustomToolTip ttData={row.batchName}>
                    <TableCell
                      className="fixed-column"
                      style={{ cursor: 'pointer' }}
                    >
                      {row.batchName}
                    </TableCell>
                  </CustomToolTip>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Scrollable Columns */}
      <div className="scrollable-columns">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Onboarding POC</TableCell>
                <TableCell>Finance POC</TableCell>
                <TableCell>Local Governance POC</TableCell>
                {checkDeepVisibility({
                  permissions,
                  value: 2,
                  resource: 57,
                }) && <TableCell>Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row._id || index}>
                  <CustomToolTip
                    ttData={row.onboardingPoc.map((poc) => `${poc.name}\n`)}
                  >
                    <TableCell style={{ cursor: 'pointer' }}>
                      {row.onboardingPoc.map((poc) => poc.name).join(', ')}
                    </TableCell>
                  </CustomToolTip>
                  <CustomToolTip
                    ttData={row.financePoc.map((poc) => `${poc.name}\n`)}
                  >
                    <TableCell style={{ cursor: 'pointer' }}>
                      {row.financePoc.map((poc) => poc.name).join(', ')}
                    </TableCell>
                  </CustomToolTip>
                  <CustomToolTip
                    ttData={row.localGovernancePoc.map(
                      (poc) => `${poc.name}\n`
                    )}
                  >
                    <TableCell style={{ cursor: 'pointer' }}>
                      {row.localGovernancePoc.map((poc) => poc.name).join(', ')}
                    </TableCell>
                  </CustomToolTip>

                  {checkDeepVisibility({
                    permissions,
                    value: 2,
                    resource: 57,
                  }) && (
                    <TableCell>
                      <div
                        className="action-btn"
                        onClick={() => editCustomPOCHandler(row)}
                      >
                        Edit POC Details
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default ManagePocTable
