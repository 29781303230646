import { Modal } from '@mui/material'
import { addPocMapping } from './helper'

// onboardingPoc: [
//     {
//         number : "",
//         name : "",
//         email : "",
//     }
// ]

const ManagePOCModel = ({
  title,
  subtitle,
  managePOCModel,
  closeManagePOPModel,
  lgData,
  mappingData,
  setActiveMappingData,
  mappingOperationType,
  selectedCourse,
  selectedBatch,
  openSuccessModelHandler,
}) => {
  const removePocHandler = (indexToDelete, flag) => {
    if (flag === 1 && mappingData.onboardingPoc) {
      const updatedPoc = [...mappingData.onboardingPoc] // Create a copy
      updatedPoc.splice(indexToDelete, 1) // Remove the entry
      setActiveMappingData({ ...mappingData, onboardingPoc: updatedPoc }) // Update state
    }
    if (flag === 2 && mappingData.financePoc) {
      const updatedPoc = [...mappingData.financePoc]
      updatedPoc.splice(indexToDelete, 1)
      setActiveMappingData({ ...mappingData, financePoc: updatedPoc })
    }
    if (flag === 3 && mappingData.localGovernancePoc) {
      const updatedPoc = [...mappingData.localGovernancePoc]
      updatedPoc.splice(indexToDelete, 1)
      setActiveMappingData({ ...mappingData, localGovernancePoc: updatedPoc })
    }
  }

  const addPocHandler = (flag) => {
    if (flag === 1 && mappingData.onboardingPoc) {
      const updatedPoc = [...mappingData.onboardingPoc] // Create a copy
      updatedPoc.push({ number: '', name: '', email: '' }) // Add an empty entry
      setActiveMappingData({ ...mappingData, onboardingPoc: updatedPoc }) // Update state
    }
    if (flag === 2 && mappingData.financePoc) {
      const updatedPoc = [...mappingData.financePoc]
      updatedPoc.push({ number: '', name: '', email: '' })
      setActiveMappingData({ ...mappingData, financePoc: updatedPoc })
    }
    if (flag === 3 && mappingData.localGovernancePoc) {
      const updatedPoc = [...mappingData.localGovernancePoc]
      updatedPoc.push({ number: '', name: '', email: '' })
      setActiveMappingData({ ...mappingData, localGovernancePoc: updatedPoc })
    }
  }

  const lgSelectHandler = (index, flag, value) => {
    // Find the selected LG from lgData based on the selected value (number)
    const selectedLg = lgData.find((lg) => lg.m === value)

    if (!selectedLg) return // If no matching LG is found, do nothing

    if (flag === 1 && mappingData.onboardingPoc) {
      const updatedPoc = [...mappingData.onboardingPoc]
      updatedPoc[index] = {
        ...updatedPoc[index],
        number: selectedLg.m,
        name: selectedLg.nam,
        email: selectedLg.ep,
      }
      setActiveMappingData({ ...mappingData, onboardingPoc: updatedPoc })
    }

    if (flag === 2 && mappingData.financePoc) {
      const updatedPoc = [...mappingData.financePoc]
      updatedPoc[index] = {
        ...updatedPoc[index],
        number: selectedLg.m,
        name: selectedLg.nam,
        email: selectedLg.ep,
      }
      setActiveMappingData({ ...mappingData, financePoc: updatedPoc })
    }

    if (flag === 3 && mappingData.localGovernancePoc) {
      const updatedPoc = [...mappingData.localGovernancePoc]
      updatedPoc[index] = {
        ...updatedPoc[index],
        number: selectedLg.m,
        name: selectedLg.nam,
        email: selectedLg.ep,
      }
      setActiveMappingData({ ...mappingData, localGovernancePoc: updatedPoc })
    }
  }

  const renderOptions = (selectedLg, flag) => {
    // Get the list of used LGs for the specific POC type
    const usedLgs =
      flag === 1
        ? mappingData.onboardingPoc.map((poc) => poc.number)
        : flag === 2
        ? mappingData.financePoc.map((poc) => poc.number)
        : mappingData.localGovernancePoc.map((poc) => poc.number)

    // Exclude used LGs except the currently selected one
    return lgData.filter((lg) => !usedLgs.includes(lg.m) || lg.m === selectedLg)
  }

  const isSaveDisabled = () => {
    const isValidPOC = (pocArray) => {
      return pocArray.some((poc) => poc.number && poc.name && poc.email)
    }

    return (
      !isValidPOC(mappingData.onboardingPoc) ||
      !isValidPOC(mappingData.financePoc) ||
      !isValidPOC(mappingData.localGovernancePoc)
    )
  }

  const submitHandler = async () => {
    if (mappingOperationType == 'ADD_MAP') {
      mappingData.crs_pg_id = selectedCourse
      mappingData.course_id = selectedBatch
    }
    const response = await addPocMapping(mappingData, mappingOperationType)

    if (response.success == true) {
      closeManagePOPModel()
      openSuccessModelHandler()
    } else {
      alert('Failed to process the request.')
    }
  }

  return (
    <Modal
      open={managePOCModel}
      onClose={closeManagePOPModel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className="managePOCModelShadow">
        <div className="managePOCModelContainer">
          <div className="top">
            <p>
              {title}
              <span>{subtitle}</span>
            </p>
            <img
              src="https://webcdn.imarticus.org/inbound/Group926.svg"
              className="crosslogo"
              onClick={closeManagePOPModel}
            />
          </div>
          <div className="middle">
            {mappingOperationType == 'ADD_MAP' ||
              (mappingOperationType == 'EDIT_MAP' && (
                <div className="Disclamer">
                  <img
                    className="dis_image"
                    src="https://webcdn.imarticus.org/cmo/ic_baseline-info.svg"
                  />
                  <p>
                    Only those POC are visible who are assigned similar batches
                    in roles and permissions.
                  </p>
                </div>
              ))}

            <div className="POC">
              <p className="heading">Assign Onboarding POC</p>
              {mappingData.onboardingPoc.map((data, index) => {
                return (
                  <div className="pocsSet">
                    <div className="head">
                      <p>{`POC ${index + 1}`}</p>
                      {mappingData.onboardingPoc.length > 1 && (
                        <img
                          className="deleteBtn"
                          src="https://webcdn.imarticus.org/inbound/Group5426.svg"
                          onClick={() => removePocHandler(index, 1)}
                        />
                      )}
                    </div>
                    <div className="inputSet">
                      <div className="selectInput">
                        <select
                          value={data.number}
                          onChange={(e) =>
                            lgSelectHandler(index, 1, e.target.value)
                          }
                        >
                          <option value="" disabled>
                            -- Choose a LG --
                          </option>
                          {renderOptions(data.number, 1).map((lg) => (
                            <option key={lg.m} value={lg.m}>
                              {`${lg.nam}:${lg.ep}`}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="numberInput">
                        <p>{data.number}</p>
                      </div>
                    </div>
                  </div>
                )
              })}

              {mappingData.onboardingPoc.length < 3 && (
                <div className="addPoc" onClick={() => addPocHandler(1)}>
                  <img
                    src="https://webcdn.imarticus.org/inbound/material-symbols_add.svg"
                    className="logo"
                  />
                  <p className="title">Add POC</p>
                </div>
              )}
            </div>
            <div className="POC" style={{ marginTop: '16px' }}>
              <p className="heading">Assign Finance POC</p>
              {mappingData.financePoc.map((data, index) => {
                return (
                  <div className="pocsSet">
                    <div className="head">
                      <p>{`POC ${index + 1}`}</p>
                      {mappingData.financePoc.length > 1 && (
                        <img
                          className="deleteBtn"
                          src="https://webcdn.imarticus.org/inbound/Group5426.svg"
                          onClick={() => removePocHandler(index, 2)}
                        />
                      )}
                    </div>
                    <div className="inputSet">
                      <div className="selectInput">
                        <select
                          value={data.number}
                          onChange={(e) =>
                            lgSelectHandler(index, 2, e.target.value)
                          }
                        >
                          <option value="" disabled>
                            -- Choose a LG --
                          </option>
                          {renderOptions(data.number, 2).map((lg) => (
                            <option key={lg.m} value={lg.m}>
                              {`${lg.nam}:${lg.ep}`}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="numberInput">
                        <p>{data.number}</p>
                      </div>
                    </div>
                  </div>
                )
              })}

              {mappingData.financePoc.length < 3 && (
                <div className="addPoc" onClick={() => addPocHandler(2)}>
                  <img
                    src="https://webcdn.imarticus.org/inbound/material-symbols_add.svg"
                    className="logo"
                  />
                  <p className="title">Add POC</p>
                </div>
              )}
            </div>
            <div className="POC" style={{ marginTop: '16px' }}>
              <p className="heading">Assign Local Governance POC</p>
              {mappingData.localGovernancePoc.map((data, index) => {
                return (
                  <div className="pocsSet">
                    <div className="head">
                      <p>{`POC ${index + 1}`}</p>
                      {mappingData.localGovernancePoc.length > 1 && (
                        <img
                          className="deleteBtn"
                          src="https://webcdn.imarticus.org/inbound/Group5426.svg"
                          onClick={() => removePocHandler(index, 3)}
                        />
                      )}
                    </div>
                    <div className="inputSet">
                      <div className="selectInput">
                        <select
                          value={data.number}
                          onChange={(e) =>
                            lgSelectHandler(index, 3, e.target.value)
                          }
                        >
                          <option value="" disabled>
                            -- Choose a LG --
                          </option>
                          {renderOptions(data.number, 3).map((lg) => (
                            <option key={lg.m} value={lg.m}>
                              {`${lg.nam}:${lg.ep}`}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="numberInput">
                        <p>{data.number}</p>
                      </div>
                    </div>
                  </div>
                )
              })}

              {mappingData.localGovernancePoc.length < 3 && (
                <div className="addPoc" onClick={() => addPocHandler(3)}>
                  <img
                    src="https://webcdn.imarticus.org/inbound/material-symbols_add.svg"
                    className="logo"
                  />
                  <p className="title">Add POC</p>
                </div>
              )}
            </div>
          </div>
          <div className="bottom">
            <div
              className="saveBtn"
              style={
                isSaveDisabled()
                  ? { opacity: '0.5', cursor: 'not-allowed' }
                  : { cursor: 'pointer' }
              }
              onClick={!isSaveDisabled() && submitHandler}
            >
              <p>Save</p>
            </div>
            <div className="cancelBtn" onClick={closeManagePOPModel}>
              <p>Cancel</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ManagePOCModel
