import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import momentjs from 'moment-timezone'
import {
  Box,
  Grid,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControlLabel,
  DialogTitle,
  Switch,
  Checkbox,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import {
  deleteSingleDeposit,
  fetchRecordDeposit,
  getAllPossibleLocations,
  getSingleDepositDetail,
  getSignedS3Url,
} from '../../actions/general'
import CloseIcon from '@mui/icons-material/Close'

let Deposits = (props) => {
  let selectedLocationData = []
  const navigate = useNavigate()
  const [filters, setFilters] = useState({
    location: '',
    bankAccount: '',
    paymentMode: '',
    fromDate: '',
    toDate: '',
    isReconciled: '',
    paymentMode: '',
  })
  const [totalCount, setTotalCount] = useState(10)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [openViewPopUp, setOpenViewPopUp] = useState(false)
  const [selectedRows, setSelectedRow] = useState([])
  const [selectedViewData, setSelectedViewData] = useState([])
  const [openDeleteSuccessPopup, setOpenDeleteSuccessPopup] = useState(false)
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedBank, setSelectedBank] = useState('')
  const [transactions, settransactions] = useState([])
  const [paymentMode, setPaymentMode] = useState([])
  const [checkPendingReconciliation, setCheckPendingReconciliation] =
    useState(true)
  const [loading, setLoading] = useState(false)
  const [openAttachment, setOpenAttachment] = useState(false)
  const [fileUrl, setFileUrl] = useState('')

  const filteredData = transactions.filter((row) => {
    const depositDate = new Date(row.depositDate).setHours(0, 0, 0, 0) // Remove time

    const fromDate = filters.fromDate
      ? new Date(filters.fromDate).setHours(0, 0, 0, 0)
      : null
    const toDate = filters.toDate
      ? new Date(filters.toDate).setHours(0, 0, 0, 0)
      : null
    return (
      (filters.location === '' || row.location === filters.location) &&
      (filters.bankAccount === '' || row.bankAccount === filters.bankAccount) &&
      (filters.paymentMode === '' || row.trxnMethod === filters.paymentMode) &&
      (filters.isReconciled === '' ||
        row.isReconciled === filters.isReconciled) &&
      (!fromDate || depositDate >= fromDate) &&
      (!toDate || depositDate <= toDate)
    )
  })
  let getDate = (date) => {
    let tz = momentjs.tz.guess()
    let Date = momentjs.tz(date, tz).valueOf()
    let formatedString = momentjs(Date).format('LL')
    return formatedString
  }

  const handleDelete = async (depositId) => {
    setLoading(true)
    let { success, message, ...data } = await deleteSingleDeposit({
      depositId,
    })
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { RecordDepositData } = data.data
    console.log({ RecordDepositData })
    setOpenDeletePopup(false)
    setSelectedRow({})
    setOpenDeleteSuccessPopup(true)
  }
  const handleFilterChange = (name, value) => {
    setFilters({ ...filters, [name]: value })
  }
  const handlePageChange = (_, newPage) => setPage(newPage)
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  useEffect(() => {
    fetchLocations()
  }, [])
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all rows
      setSelectedRow(filteredData)
    } else {
      // Deselect all
      setSelectedRow([])
    }
  }
  const handleViewAttachemnt = async (file) => {
    setLoading(true)
    let { success, message, ...data } = await getSignedS3Url({
      m_url: file,
    })
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { signed_url } = data
    console.log({ signed_url })
    setOpenAttachment(true)
    setFileUrl(signed_url)
  }
  let fetchLocations = async () => {
    setLoading(true)
    let { success, message, ...data } = await getAllPossibleLocations()
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { allLocations } = data.data
    console.log({ allLocations, data })
    setLocations(allLocations)
  }
  let fetchDepositData = async (locationId, accountNumber) => {
    setLoading(true)
    let { success, message, ...data } = await fetchRecordDeposit({
      locationId,
      accountNumber,
    })
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { depositData } = data.data
    let tempPaymentMethod = []
    depositData.forEach((dt) => {
      if (!tempPaymentMethod.includes(dt.trxnMethod)) {
        tempPaymentMethod.push(dt.trxnMethod)
      }
    })

    console.log({ depositData })
    setPaymentMode(tempPaymentMethod)
    settransactions(depositData)
  }
  const fetchSelectedLocation = () => {
    let tempLocation = {}
    locations.forEach((dt) => {
      if (dt._id == selectedLocation) {
        tempLocation = dt
      }
    })
    return `${tempLocation.name}, ${tempLocation.city}, ${tempLocation.country}`
  }
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value)
    setSelectedBank('') // Reset bank account selection
  }
  const handleBankChange = async (event) => {
    setSelectedBank(event.target.value)
    await fetchDepositData(selectedLocation, event.target.value)
  }
  const handleView = async (depositId) => {
    setLoading(true)
    let { success, message, ...data } = await getSingleDepositDetail({
      depositId,
    })
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { depositData } = data.data
    console.log({ depositData })
    setSelectedViewData(depositData)
    setOpenViewPopUp(true)
  }
  selectedLocationData = locations.find((loc) => loc._id === selectedLocation)
  console.log({ selectedLocationData })
  return loading ? (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  ) : (
    <div style={{ padding: '24px' }}>
      <div
        style={{
          fontSize: '28px',
          fontWeight: '700',
          lineHeight: '32px',
          color: '#575757',
        }}
      >
        Record Bank Deposits for Payments Collected
      </div>
      <div
        style={{
          marginTop: '24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <FormControl
            sx={{
              width: '300px',
              marginRight: '12px',
            }}
          >
            <InputLabel>Select a Branch Location*</InputLabel>
            <Select
              Select
              value={selectedLocation}
              onChange={handleLocationChange}
              label="Select a Branch Location*"
            >
              {locations.length > 0 &&
                locations.map((loc) => (
                  <MenuItem key={loc._id} value={loc._id}>
                    {`${loc.name}, ${loc.city}, ${loc.country}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: '300px',
              marginRight: '12px',
            }}
            disabled={
              !selectedLocation ||
              selectedLocationData?.bankAccounts.length === 0
            }
          >
            <InputLabel>Select a Bank Account*</InputLabel>
            <Select
              label="Select a Bank Account*"
              Select
              value={selectedBank}
              onChange={handleBankChange}
            >
              {selectedLocationData?.bankAccounts?.map((bank, index) => (
                <MenuItem key={index} value={bank}>
                  {bank}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <FormControl sx={{ width: 250 }}>
          <InputLabel>Create Deposit</InputLabel>
          <Select label="Create Deposit">
            <MenuItem
              onClick={() => {
                navigate('/createDeposits')
              }}
              value="cash"
            >
              Record a Cash Deposit
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/createChequeDeposits')
              }}
              value="cheque"
            >
              Create a Cheque Deposit
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          marginTop: '24px',
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '20px',
          color: 'rgba(87,87,87,1)',
        }}
      >
        Use below filters to refine the table
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
          marginTop: '24px',
        }}
      >
        <div style={{ display: 'flex', gap: '12px' }}>
          <FormControl sx={{ width: 200 }}>
            <InputLabel>Payment Method</InputLabel>
            <Select
              label="Payment Method"
              value={filters.paymentMode}
              onChange={(e) =>
                handleFilterChange('paymentMode', e.target.value)
              }
            >
              <MenuItem value="">All</MenuItem>
              {paymentMode.map((dt) => (
                <MenuItem key={dt} value={dt}>
                  {dt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            sx={{ width: 200, marginRight: '12px' }}
            label="Filter From"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={filters.fromDate}
            onChange={(e) => handleFilterChange('fromDate', e.target.value)}
          />

          <TextField
            sx={{ width: 200, marginRight: '12px' }}
            label="Filter To"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={filters.toDate}
            onChange={(e) => handleFilterChange('toDate', e.target.value)}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={filters.isReconciled}
                onChange={() =>
                  handleFilterChange('isReconciled', !filters.isReconciled)
                }
              />
            }
            label="Has been Reconciled"
          />
        </div>
      </div>
      <TableContainer
        component={Paper}
        sx={{ width: '100%', overflowX: 'auto', border: '1px solid #ccc' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  textAlign: 'center',
                  background: '#f4f4f4',
                  border: '1px solid #ccc',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  background: '#f4f4f4',
                  border: '1px solid #ccc',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Reference Number
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  background: '#f4f4f4',
                  border: '1px solid #ccc',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Deposit Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  background: '#f4f4f4',
                  border: '1px solid #ccc',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Bank Account Number
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  background: '#f4f4f4',
                  border: '1px solid #ccc',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Transaction Method
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  background: '#f4f4f4',
                  border: '1px solid #ccc',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  background: '#f4f4f4',
                  border: '1px solid #ccc',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Has been Reconciled?
              </TableCell>
              <TableCell
                sx={{
                  textAlign: 'center',
                  background: '#f4f4f4',
                  border: '1px solid #ccc',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {row['referenceNumber']}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {getDate(row.depositDate)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {row['bankAccount']}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {row['trxnMethod']}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    background: '#fff',
                    border: '1px solid #ccc',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {row['amount']}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    background: row['isReconciled'] ? '#DAEFDC' : '#FFFFC5',
                    border: '1px solid #ccc',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                >
                  {row['isReconciled'] ? 'Yes' : 'No'}
                </TableCell>
                <TableCell
                  sx={{
                    background: '#fff',
                    border: '1px solid #ccc',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    textAlign: 'center',
                    padding: '8px',
                  }}
                  align="center"
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <div
                      style={{
                        borderRadius: '3px',
                        height: '24px',
                        width: '24px',
                        backgroundColor: '#28a745',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSelectedRow(row)
                        handleView(row.referenceNumber)
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none" stroke="#ffffff" strokeWidth="2">
                          <circle cx="12" cy="12" r="3" />
                          <path d="M20.188 10.934c.388.472.582.707.582 1.066s-.194.594-.582 1.066C18.768 14.79 15.636 18 12 18s-6.768-3.21-8.188-4.934c-.388-.472-.582-.707-.582-1.066s.194-.594.582-1.066C5.232 9.21 8.364 6 12 6s6.768 3.21 8.188 4.934Z" />
                        </g>
                      </svg>
                    </div>

                    {!row['isReconciled'] && (
                      <>
                        {/* <div
                          style={{
                            borderRadius: "3px",
                            height: "24px",
                            width: "24px",
                            backgroundColor: "#035642",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(
                              `/createDeposits/${row["referenceNumber"]}`
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M10.545 6.75L11.25 7.455L4.44 14.25H3.75V13.56L10.545 6.75ZM13.245 2.25C13.0575 2.25 12.8625 2.325 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28C15.825 4.9875 15.825 4.5 15.5325 4.2225L13.7775 2.4675C13.6275 2.3175 13.44 2.25 13.245 2.25ZM10.545 4.6425L2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425Z"
                              fill="white"
                            />
                          </svg>
                        </div> */}

                        <div
                          style={{
                            borderRadius: '3px',
                            height: '24px',
                            width: '24px',
                            backgroundColor: '#Dc3545',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectedRow(row)
                            setOpenDeletePopup(true)
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#ffffff"
                              d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
                            />
                          </svg>
                        </div>
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openViewPopUp}
        onClose={() => {
          setOpenViewPopUp(false)
          setSelectedRow({})
        }}
        maxWidth="md"
        fullWidth
      >
        <div style={{ padding: '24px' }}>
          <div
            style={{
              color: '#A09FA0',
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '32px',
            }}
          >
            {selectedRows.trxnMethod == 'CASH'
              ? 'Cash Deposit'
              : 'Cheque Deposit'}
          </div>
          <div
            style={{
              height: '1px',
              width: 'auto',
              backgroundColor: '#DEE2E6',
              marginTop: '16px',
            }}
          ></div>
          <div style={{ marginTop: '24px' }}>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Branch Location: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {fetchSelectedLocation()}
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Bank Account: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {selectedBank}
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Bank Deposit Date: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {getDate(selectedRows.depositDate)}
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Uploaded Bank Receipt: '}
              </span>
              <span
                style={{
                  textUnderlinePosition: 'from-font',
                  textDecorationLine: 'underline',
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                  cursor: 'pointer',
                  textUnderlineOffset: 'auto',
                }}
                onClick={() => {
                  handleViewAttachemnt(selectedRows.doc)
                }}
              >
                View Attachment
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Deposit Amount: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {selectedRows.amount}
              </span>
            </div>
          </div>
          <div style={{ padding: '24px 0px' }}>
            <div
              style={{
                color: '#3c4852',
                fontSize: '20px',
                fontWeight: '600',
                lineHeight: '24px',
              }}
            >
              List of learner payments added into this deposit
            </div>
            <TableContainer
              component={Paper}
              sx={{
                marginTop: '32px',
                width: '100%',
                overflowX: 'auto',
                border: '1px solid #ccc',
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '50px',
                        minWidth: '50px',
                        border: '1px solid #ccc',
                      }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 50,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Learner UID
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 200,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      Learner Name
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 350,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      Mobile
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Batch Name
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Payment Amount
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Payment Date
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Payment Type
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Payment Mode
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '150px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                      }}
                    >
                      Comment
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedViewData.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 0,
                          background: '#f4f4f4',
                          zIndex: 3,
                          width: '50px',
                          minWidth: '50px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 50,
                          background: '#f4f4f4',
                          zIndex: 3,
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['uid']}
                      </TableCell>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 200,
                          background: '#f4f4f4',
                          zIndex: 3,
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {row['name']}
                      </TableCell>
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 350,
                          background: '#f4f4f4',
                          zIndex: 3,
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['mobile']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {row['courseName']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {row['batchName']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['amount']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {getDate(row['date'])}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['type']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {row['mode']}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '150px',
                          minWidth: '150px',
                          border: '1px solid #ccc',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {row['comment']}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <DialogActions>
            <button
              style={{
                width: '148px',
                cursor: 'pointer',
                borderRadius: '8px',
                padding: '12px 24px',
                backgroundColor: '#035642',
                color: 'white',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
              }}
              onClick={() => {
                setOpenViewPopUp(false)
                setSelectedRow({})
              }}
              color="primary"
            >
              Okay
            </button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={openDeletePopup}
        onClose={() => {
          setOpenDeletePopup(false)
          setSelectedRow({})
        }}
        maxWidth="md"
        fullWidth
      >
        <div style={{ padding: '40px' }}>
          <div
            style={{
              color: '#A09FA0',
              fontSize: '24px',
              textAlign: 'center',
              fontWeight: '700',
              lineHeight: '32px',
            }}
          >
            {'Do you want to delete this deposit?'}
          </div>
          <div style={{ marginTop: '24px' }}>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Branch Location: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {fetchSelectedLocation()}
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Bank Account: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {selectedBank}
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Bank Deposit Date: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {getDate(selectedRows.depositDate)}
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Uploaded Bank Receipt: '}
              </span>
              <span
                style={{
                  textUnderlinePosition: 'from-font',
                  textDecorationLine: 'underline',
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                  cursor: 'pointer',
                  textUnderlineOffset: 'auto',
                }}
                onClick={() => {
                  handleViewAttachemnt(selectedRows.doc)
                }}
              >
                View Attachment
              </span>
            </div>
            <div style={{ marginBottom: '4px' }}>
              <span
                style={{
                  color: '#212A39',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {'Deposit Amount: '}
              </span>
              <span
                style={{
                  color: '#035642',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {selectedRows.amount}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <DialogActions>
              <button
                style={{
                  width: '148px',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  padding: '12px 24px',
                  backgroundColor: '#035642',
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                }}
                onClick={() => {
                  handleDelete(selectedRows.referenceNumber)
                }}
                color="primary"
              >
                Confirm
              </button>
              <button
                style={{
                  width: '148px',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  padding: '12px 24px',
                  backgroundColor: '#DC3545',
                  color: 'white',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                }}
                onClick={() => {
                  setOpenDeletePopup(false)
                  setSelectedRow({})
                }}
                color="primary"
              >
                Close
              </button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openDeleteSuccessPopup}
        onClose={() => {
          setOpenDeleteSuccessPopup(false)
        }}
        maxWidth="sm"
        fullWidth
      >
        {/* Title */}
        <DialogTitle>
          <div style={{ margin: '40px' }}>
            <div
              style={{
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                color: '#3c4852',
                textAlign: 'center',
                marginTop: '16px',
              }}
            >
              Deposit has been successfully delted;
            </div>
            <div style={{ textAlign: 'center', margin: '40px' }}>
              <img
                style={{ height: '160px', width: '160px' }}
                src="https://cdn.pegasus.imarticus.org/payments/paid.svg"
                alt="logo"
                className="as-dl-logo-hp"
              />
            </div>
            <div style={{ margin: '40px', textAlign: 'center' }}>
              <button
                onClick={() => {
                  setOpenDeleteSuccessPopup(false)
                  window.location.reload()
                }}
                style={{
                  width: '160px',
                  height: '40px',
                  color: 'white',
                  backgroundColor: '#035642',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  borderColor: 'transparent',
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </DialogTitle>
      </Dialog>
      {/* <Dialog
        open={checkPendingReconciliation}
        onClose={() => {
          setCheckPendingReconciliation(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <div style={{ margin: "40px" }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "left" }}>
              <div>
                <button
                  tabIndex={-1}
                  onClick={(e) => {
                    e.target.blur();
                    setCheckPendingReconciliation(false);
                    window.location.reload();
                  }}
                  style={{
                    position: 'absolute',
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
            <div
              style={{
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "32px",
                color: "#a09fa0",
                textAlign: "center",
                marginTop: "16px",
              }}
            >
              You have pending reconciliations!
            </div>
            <div style={{ textAlign: "center", margin: "40px" }}>
              <img
                style={{ height: "160px", width: "160px" }}
                src="https://webcdn.imarticus.org/inbound/Group4606.svg"
                alt="logo"
                className="as-dl-logo-hp"
              />
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#3c4852",
                textAlign: "center",
                marginTop: "24px",
              }}
            >
              It seems that you have payments that are pending to be reconciled for more than 3 days.
              Please reconcile them at the earliest to avoid risking the locking of your Sales Ops.
            </div>
          </div>
        </DialogTitle>
      </Dialog> */}
      <Dialog
        open={openAttachment}
        onClose={() => {
          setOpenAttachment(false)
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Attachment Preview
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenAttachment(false)
            }}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <iframe
            src={fileUrl}
            title="PDF Preview"
            width="100%"
            height="500px"
            style={{ border: 'none' }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default Deposits

// // is_tat_exceeded

// var tat_ticket_date = resolved_at || closed_at || new date().getTime()

// //ticket created at 31stdec (usecreatedAt) to calculate tat

// var tat_filtered =
