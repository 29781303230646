const initialState = {
  appcurrent_page: 1,
  arloading: false,
  courses: [],
  coursePackages: [],
  locations: [],
  searchType: '',
  searchVal: '',
  isLead: false,
  message: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'INDIVIDUAL_APPLICATION_DETAIL':
      return {
        ...state,
        userinfo: action.payload.userinfo,
      }

    case 'SUBMIT_APPLICATION_MESSAGE':
      return {
        ...state,
        message: action.payload.message,
      }

    case 'SELECTED_APPLICATION_STATUS':
      return {
        ...state,
        app_status: action.payload.app_status,
      }

    case 'SELECTED_COURSE':
      return {
        ...state,
        crs_pg_id: action.payload.crs_pg_id,
      }

    case 'FETCH_APPLICATION_FORMS':
      return {
        ...state,
        applications: action.payload.applications,
        crs_pkgs: action.payload.crs_pkgs,
        total_app: action.payload.total_app,
        isLead: action.payload.isLead,
        lead: action.payload.lead,
        courseCategoryData: action.payload.courseCategoryData,
      }

    case 'ADD_MORE_APPLICATION_FORMS':
      return {
        ...state,
        applications: state.applications.concat(action.payload.applications),
        total_app: action.payload.total_app,
        isLead: action.payload.isLead,
        lead: action.payload.lead,
        courseCategoryData: action.payload.courseCategoryData,
      }

    case 'UPDATE_APPCURRENT_PAGE':
      return {
        ...state,
        appcurrent_page: action.payload.appcurrent_page,
      }

    case 'UPDATE_COURSES':
      console.log({ receivedPayload: action.payload })
      return {
        ...state,
        courses: action.payload.courses,
        coursePackages: action.payload.coursePackages,
        locations: action.payload.locations,
      }

    case 'DELETE_USERINFO_DATA':
      return {
        ...state,
        userinfo: '',
      }

    case 'INITITATE_AR_LOADING':
      return {
        ...state,
        arloading: true,
      }

    case 'STOP_AR_LOADING':
      return {
        ...state,
        arloading: false,
      }

    case 'SHOW_NOTIFY': {
      return {
        ...state,
        data: {
          type: action.payload.type,
          show: true,
          message: action.payload.message,
        },
      }
    }

    case 'HIDE_NOTIFY': {
      return {
        ...state,
        data: {
          show: false,
        },
      }
    }

    case 'UPDATE_APPLICARION_SEARCH_DATA': {
      return {
        ...state,
        searchType: action.payload.searchType,
        searchVal: action.payload.searchVal,
      }
    }

    default:
      return state
  }
}
