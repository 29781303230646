import React, { useEffect, useState, lazy, Suspense } from 'react'
import Cookies from 'universal-cookie'
import { connect } from 'react-redux'
import * as constants from './constants'
import { getUserPermissions, removeAuthCookies } from './actions/general'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
const App = lazy(() => import('./layout/App'))
const Home = lazy(() => import('./scenes/landingPage/landingPage'))
const PromoCode = lazy(() => import('./scenes/promocode/promocode'))
const SpecialPromocode = lazy(() =>
  import('./scenes/promocode/specialpromocode')
)
const GeneralPromocode = lazy(() =>
  import('./scenes/promocode/generalpromocode')
)
const AddSpecialPromocode = lazy(() =>
  import('./scenes/promocode/add_specialpromocode')
)
const AddGeneralPromocode = lazy(() =>
  import('./scenes/promocode/add_generalpromocode')
)
import OnboardMail from './scenes/onboardemail/OnboardMail'
const EditGeneralPromoCode = lazy(() =>
  import('./scenes/promocode/edit_generalpromocode')
)
const Login = lazy(() => import('./scenes/login/index'))
const ApplicationsReview = lazy(() =>
  import('./scenes/applicationform/ApplicationsReview')
)
const CashPayment = lazy(() => import('./scenes/cashpayment/CashPayment'))

const QRCodeGenerate = lazy(() => import('./scenes/qrcodegen/QRCodeGenerator'))

const PaymentStatus = lazy(() => import('./scenes/paymentStatus/ViewPayment'))
const PartnerCollectedAllocation = lazy(() =>
  import('./scenes/partnercollectedallocation/PartnerCollectedAllocation')
)
const BranchCollectedAllocation = lazy(() =>
  import('./scenes/branchcollectedallocation/BranchCollectedAllocation')
)
const EditRegistrationFee = lazy(() =>
  import('./scenes/editregistrationfee/editregistrationfee')
)
const Editapplicationfee = lazy(() =>
  import('./scenes/editapplicationfee/editapplicationfee')
)
const InstallmentEdit = lazy(() =>
  import('./scenes/installmentedit/InstallmentEdit')
)
const EditInstallmentDates = lazy(() =>
  import('./scenes/editinstallmentdates/EditInstallmentDates')
)
const CreateCustomerPreferences = lazy(() =>
  import('./scenes/CreateCustomerPreferences/CreateCustomerPreferences')
)
const ApplyTokenPayment = lazy(() =>
  import('./scenes/applyTokenPayment/ApplyTokenPayment')
)
const LegacyInstallmentCollection = lazy(() =>
  import('./scenes/legacyInstallment/legacyInstallment')
)
const LivePaymentTracking = lazy(() =>
  import('./scenes/livePaymentTracking/livePaymentTracking')
)
const ApplicationDocumentDownload = lazy(() =>
  import('./scenes/applicationdocumentdownload/applicationdocumentdownload')
)
const CertificateDownload = lazy(() =>
  import('./scenes/certificateDownload/certificateDownload')
)
const Loader = lazy(() => import('./scenes/applicationform/Loader'))
const BatchDrop = lazy(() => import('./scenes/batchDrop/BatchDrop'))

const cookies = new Cookies()
import LeadDistributionPanel from './scenes/leadDistribution/leadDistributionPanel'
import LeadDistributionPanelAdvisors from './scenes/leadDistribution/LeadDistributionPanelAdvisors'
import LearnerCallManagement from './scenes/learnerCallManagement/LearnerCallManagement'
import Deposits from './scenes/deposits/Deposits'
import CreateDeposits from './scenes/createDeposits/CreateDeposits'
import CreateChequeDeposits from './scenes/createChequeDeposits/CreateChequeDeposits'
import TrackReconciliation from './scenes/trackreconciliation/TrackReconciliation'
import KachingViewer from './scenes/kachingViewer/kachingViewer'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const Dashboard = (props) => {
  console.log({ props })
  const [show, setShow] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    console.log(
      'Location changed!, ',
      'Pathname: ',
      location.pathname,
      ', SearchQuery: ',
      location.search
    )
    let listener = async () => {
      let pathname = location.pathname
      try {
        let res = await getUserPermissions()
        console.log({ res })
        if (!res.data.isReconciled) {
          let lastPopUpShownTime = localStorage.getItem('lastPopUpShownTime')
          if (!lastPopUpShownTime) {
            localStorage.setItem('lastPopUpShownTime', Date.now())
            setShow(true)
          } else {
            if (
              Date.now() - res.data.reconciliationPopUpLimit >=
              parseInt(lastPopUpShownTime)
            ) {
              localStorage.setItem('lastPopUpShownTime', Date.now())
              setShow(true)
            }
          }
        }
        if (res.success) {
          props.dispatch(
            props.setPermissions(
              res.data.permissionMap,
              res.data.resources,
              res.data.isReconciled,
              res.data.isDismissible,
              res.data.locationMap,
              res.data.reconciliationPopUpLimit
            )
          )
        }
      } catch (err) {
        removeAuthCookies()
        if (
          location.pathname &&
          (location.pathname.includes('/applicationdoc/') ||
            location.pathname.includes('/certificate/'))
        ) {
          navigate(
            '/login' + '?redirect=' + encodeURIComponent(location.pathname)
          )
        } else {
          alert('FORBIDDEN')
          navigate('/login')
        }
      }
    }
    if (cookies.get('isLoggedIn')) {
      listener()
    }
  }, [location])

  const checkVisibility = (resource) => {
    if (!props.permissions) {
      return true
    } else {
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(1) != -1) {
        return true
      } else {
        return false
      }
    }
  }

  const handleClose = () => setShow(false)
  const handleRedirect = () => {
    window.location.href = `${constants.SALESOPS_API_ENDPOINT}/imarticus_collected` // Change tohos your URL
  }

  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            width: '720px',
            maxWidth: '720px',
            padding: 2,
          },
        }}
      >
        {/* Header with Close Button */}
        <DialogTitle
          sx={{
            color: '#A09FA0',
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '32px',
            textTransform: 'uppercase',
          }}
        >
          You Have Pending Reconciliation!
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src="https://webcdn.imarticus.org/reconcialation/58420911_92647441.webp"
            alt="Popup Image"
            style={{
              display: 'block',
              margin: '0 auto',
              width: '80%',
              borderRadius: '8px',
            }}
          />
          <div
            style={{
              color: '#3c4852',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '24px',
            }}
          >
            It seems that you have payments that are pending to be reconciled.
          </div>
          <div
            style={{
              color: '#3c4852',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '24px',
            }}
          >
            Please reconcile them at the earliest to avoid risking the locking
            of your Sales Ops.
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            sx={{
              width: 'auto',
              height: '40px',
              color: 'white',
              backgroundColor: '#035642',
              cursor: 'pointer',
              borderRadius: '4px',
              borderColor: 'transparent',
              '&:hover': { backgroundColor: '#035642' },
            }}
            onClick={handleRedirect}
            variant="contained"
          >
            Go To Reconciliation Dashboard
          </Button>
        </DialogActions>
      </Dialog>
      <Suspense>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route path="/" element={<App />}>
            <Route path="/" element={<Home />} />
            <Route
              path="promocode"
              element={checkVisibility('7') ? <PromoCode /> : <Home />}
            />
            <Route
              path="promocode/generalpromocode"
              element={
                checkVisibility('7') && checkVisibility('30') ? (
                  <GeneralPromocode />
                ) : (
                  <Home />
                )
              }
            />
            <Route
              path="promocode/specialpromocode"
              element={checkVisibility('7') ? <SpecialPromocode /> : <Home />}
            />
            <Route
              path="promocode/specialpromocode/add"
              element={
                checkVisibility('7') ? <AddSpecialPromocode /> : <Home />
              }
            />
            <Route
              path="promocode/generalpromocode/add"
              element={
                checkVisibility('7') && checkVisibility('30') ? (
                  <AddGeneralPromocode />
                ) : (
                  <Home />
                )
              }
            />
            <Route
              path="promocode/generalpromocode/edit/:promoid"
              element={
                checkVisibility('7') && checkVisibility('30') ? (
                  <EditGeneralPromoCode />
                ) : (
                  <Home />
                )
              }
            />

            <Route
              path="applicationdoc/:applicationId"
              element={
                Object.keys(props.resources).length ? (
                  <ApplicationDocumentDownload />
                ) : (
                  <Loader loading />
                )
              }
            />
            <Route
              path="certificate/:txnId"
              element={
                Object.keys(props.resources).length ? (
                  <CertificateDownload />
                ) : (
                  <Loader loading />
                )
              }
            />
            <Route
              path="applicationsreview"
              element={checkVisibility('5') ? <ApplicationsReview /> : <Home />}
            />
            <Route path="qr-code-generator" element={<QRCodeGenerate />} />
            <Route path="onboard-email" element={<OnboardMail />} />
            <Route
              path="paymentStatus"
              element={checkVisibility('33') ? <PaymentStatus /> : <Home />}
            />
            <Route
              path="cashpayment"
              element={checkVisibility('33') ? <CashPayment /> : <Home />}
            />
            <Route
              path="applyTokenPayment"
              element={checkVisibility('33') ? <ApplyTokenPayment /> : <Home />}
            />
            <Route
              path="partner_collected"
              element={
                checkVisibility('33') ? (
                  <PartnerCollectedAllocation />
                ) : (
                  <Home />
                )
              }
            />
            <Route path="kaching_viewer" element={<KachingViewer />} />
            <Route
              path="trackreconciliation"
              element={
                checkVisibility('33') ? <TrackReconciliation /> : <Home />
              }
            />
            <Route
              path="imarticus_collected/*"
              element={
                checkVisibility('33') ? <BranchCollectedAllocation /> : <Home />
              }
            />
            <Route
              path="deposits"
              element={checkVisibility('33') ? <Deposits /> : <Home />}
            />
            <Route
              path="createDeposits/*"
              element={checkVisibility('33') ? <CreateDeposits /> : <Home />}
            />
            <Route
              path="createChequeDeposits"
              element={
                checkVisibility('33') ? <CreateChequeDeposits /> : <Home />
              }
            />
            <Route
              path="set_user_payment_preferences"
              element={
                checkVisibility('33') ? <CreateCustomerPreferences /> : <Home />
              }
            />
            <Route
              path="legacy_installment_collection"
              element={
                checkVisibility('37') ? (
                  <LegacyInstallmentCollection />
                ) : (
                  <Home />
                )
              }
            />
            <Route
              path="live_payment_tracking"
              element={<LivePaymentTracking />}
            />
            <Route
              path="edit-transaction/registration"
              element={
                checkVisibility('5') ? <EditRegistrationFee /> : <Home />
              }
            />
            <Route
              path="edit-transaction/application"
              element={checkVisibility('5') ? <Editapplicationfee /> : <Home />}
            />
            <Route
              path="edit-transaction/installment"
              element={checkVisibility('33') ? <InstallmentEdit /> : <Home />}
            />
            <Route
              path="edit-transaction/installmentdates"
              element={
                checkVisibility('33') ? <EditInstallmentDates /> : <Home />
              }
            />
            <Route
              path="leaddistributionpanel"
              element={
                checkVisibility('39') ? <LeadDistributionPanel /> : <Home />
              }
            />
            <Route
              path="LeadDistributionPanelAdvisors"
              element={
                checkVisibility('39') ? (
                  <LeadDistributionPanelAdvisors />
                ) : (
                  <Home />
                )
              }
            />
            <Route
              path="batchDropRequest"
              element={checkVisibility('59') ? <BatchDrop /> : <Home />}
            />
            <Route
              path="learner-call-management"
              element={<LearnerCallManagement />}
            />
          </Route>
        </Routes>
      </Suspense>
    </>
  )
}

const mapStateToProps = (state) => ({
  permissions: state.login.permissions,
  resources: state.login.resources,
  isReconciled: state.login.isReconciled,
  isDismissible: state.login.isDismissible,
  reconciliationPopUpLimit: state.login.reconciliationPopUpLimit,
})
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissions:
      (
        permissions,
        resources,
        isReconciled,
        isDismissible,
        locationMap,
        reconciliationPopUpLimit
      ) =>
      (dispatch) => {
        return dispatch({
          type: 'SET_PERMISSIONS_AND_RESOURCES',
          payload: {
            permissions,
            resources,
            isReconciled,
            isDismissible,
            locationMap,
            reconciliationPopUpLimit,
          },
        })
      },
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
