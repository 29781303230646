import React, { useState, useEffect } from 'react'
import momentjs from 'moment-timezone'
import { connect } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  Button,
  TextField,
  CircularProgress,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  Box,
  Checkbox,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import {
  getFOBankTransaction,
  getFODepositDetail,
  exportFODepositDetail,
  exportFOBankTransaction,
} from '../../actions/general'

const TrackReconciliation = (props) => {
  const [bankAccount, setBankAccount] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [reconciled, setReconciled] = useState('')
  const [loading, setLoading] = useState(false)
  const [trackBankTransaction, setTrackBankTransaction] = useState(true)
  const [trackDepositTransaction, setTrackDepositTransaction] = useState(false)
  const [depositTransactions, setDepositTransactions] = useState([])
  const [bankTransactions, setBankTransactions] = useState([])
  const [loadMoreCountBank, setLoadMoreCountBank] = useState(0)
  const [isMoreFoundBank, setIsMoreFoundBank] = useState(false)
  const [loadMoreCount, setLoadMoreCount] = useState(0)
  const [isMoreFound, setIsMoreFound] = useState(false)
  const [openAttachment, setOpenAttachment] = useState(false)
  const [fileUrl, setFileUrl] = useState('')
  const [bankTransactionFilter, setBankTransactionFilter] = useState({
    location: '',
    bankAccount: '',
    fromDate: '',
    toDate: '',
    isReconciled: '',
  })
  const [depositFilter, setDepositFilter] = useState({
    location: '',
    paymentMode: '',
    fromDate: '',
    toDate: '',
    isReconciled: '',
  })
  const [paymentMethods, setPaymentMethods] = useState('')

  console.log({ props })
  useEffect(() => {
    if (trackBankTransaction && bankTransactionFilter.location) {
      getBankDetail(loadMoreCountBank)
    }
  }, [trackBankTransaction, bankTransactionFilter])
  let getBankDetail = async (skip) => {
    setLoading(true)
    let { success, message, ...data } = await getFOBankTransaction(
      skip,
      bankTransactionFilter
    )
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { result, isMoreFound } = data.data
    setBankTransactions([...result])
    setIsMoreFoundBank(isMoreFound)
    return result
  }

  useEffect(() => {
    if (trackDepositTransaction && depositFilter.location) {
      getDepositDetail(loadMoreCount)
    }
  }, [trackDepositTransaction, depositFilter])
  let getDepositDetail = async (loadMoreCount) => {
    setLoading(true)
    let { success, message, ...data } = await getFODepositDetail(
      loadMoreCount,
      depositFilter
    )
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { depositData, isMoreFound, paymentMethodMap } = data.data
    setIsMoreFound(isMoreFound)
    setDepositTransactions([...depositData])
    setPaymentMethods(paymentMethodMap)
    return depositData
  }

  const uniquePaymentMode = [
    ...new Set(depositTransactions.map((tx) => tx.mode)),
  ]
  const bankAccounts = bankTransactionFilter.location
    ? props.locationMap[bankTransactionFilter.location].accountNumber
    : []

  const handleBankFilterChange = (name, value) => {
    setLoadMoreCountBank(0)
    setBankTransactionFilter({ ...bankTransactionFilter, [name]: value })
  }

  const handleDepositFilterChange = (name, value) => {
    setLoadMoreCount(0)
    setDepositFilter({ ...depositFilter, [name]: value })
  }

  const handleLoadMore = async () => {
    const temp = [...depositTransactions]
    setLoadMoreCount(loadMoreCount + 1)
    const result = await getDepositDetail(loadMoreCount + 1)
    setDepositTransactions([...temp, ...result])
  }

  const handleLoadMoreBank = async () => {
    const temp = [...bankTransactions]
    setLoadMoreCountBank(loadMoreCountBank + 1)
    const result = await getBankDetail(loadMoreCountBank + 1)
    console.log({ temp, result })
    setBankTransactions([...temp, ...result])
  }

  const startExportFODepositDetail = async () => {
    setLoading(true)
    exportFODepositDetail(depositFilter)
      .then((res) => {
        const contentDisposition = res.headers['content-disposition']
        let fileName = contentDisposition.split(';')[1].split('=')[1]

        console.log('File name : ', fileName)
        fileName = fileName.replaceAll('"', '')
        console.log('File name : ', fileName)

        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        setLoading(false)
      })
      .catch((err) => {
        const reader = new FileReader()
        reader.readAsText(err.data)
        reader.addEventListener('loadend', (e) => {
          const data = JSON.parse(e.target.result)
          console.log({ data })
          setLoading(false)
          if (data.message) {
            alert(data.message)
          } else {
            alert('Something Went Wrong. Please Try Again Later.')
          }
        })
      })
  }

  const startExportBankTransaction = async () => {
    setLoading(true)
    exportFOBankTransaction(bankTransactionFilter)
      .then((res) => {
        const contentDisposition = res.headers['content-disposition']
        let fileName = contentDisposition.split(';')[1].split('=')[1]

        console.log('File name : ', fileName)
        fileName = fileName.replaceAll('"', '')
        console.log('File name : ', fileName)

        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        setLoading(false)
      })
      .catch((err) => {
        const reader = new FileReader()
        reader.readAsText(err.data)
        reader.addEventListener('loadend', (e) => {
          const data = JSON.parse(e.target.result)
          console.log({ data })
          setLoading(false)
          if (data.message) {
            alert(data.message)
          } else {
            alert('Something Went Wrong. Please Try Again Later.')
          }
        })
      })
  }

  let getDate = (date) => {
    let tz = momentjs.tz.guess()
    let Date = momentjs.tz(date, tz).valueOf()
    let formatedString = momentjs(Date).format('LL')
    return formatedString
  }

  const handleView = async (file) => {
    setLoading(true)
    let { success, message, ...data } = await getSignedS3Url({
      m_url: file,
    })
    setLoading(false)
    console.log({ success, message })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
      return
    }
    let { signed_url } = data
    console.log({ signed_url })
    setOpenAttachment(true)
    setFileUrl(signed_url)
  }

  if (loading)
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </div>
    )

  return (
    <div style={{ padding: '24px', width: '100%', overflowX: 'hidden' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            fontSize: '32px',
            fontWeight: '600',
            lineHeight: '48px',
            color: '#3c4852',
          }}
        >
          Track Reconciliation
        </div>
        {trackBankTransaction ? (
          <div
            style={{
              display: 'flex',
              columnGap: '8px',
              color: '#055646',
              fontSize: '14px',
              fontWeight: '600',
              border: '1px',
              borderColor: '#055646',
              cursor: 'pointer',
              alignItems: 'center',
            }}
            onClick={startExportBankTransaction}
          >
            <div
              style={{
                borderRadius: '3px',
                height: '24px',
                width: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#055646"
                  d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
                />
              </svg>
            </div>
            Download Report
          </div>
        ) : null}
        {trackDepositTransaction ? (
          <div
            style={{
              display: 'flex',
              columnGap: '8px',
              color: '#055646',
              fontSize: '14px',
              fontWeight: '600',
              border: '1px',
              borderColor: '#055646',
              cursor: 'pointer',
              alignItems: 'center',
            }}
            onClick={startExportFODepositDetail}
          >
            <div
              style={{
                borderRadius: '3px',
                height: '24px',
                width: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#055646"
                  d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
                />
              </svg>
            </div>
            Download Report
          </div>
        ) : null}
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
          marginTop: '24px',
        }}
      >
        <div
          onClick={() => {
            setTrackBankTransaction(true)
            setTrackDepositTransaction(false)
            setLoadMoreCount(0)
            setLoadMoreCountBank(0)
          }}
          style={{
            width: '50%',
            textAlign: 'center',
            backgroundColor: !trackBankTransaction
              ? 'rgba(33,42,57,0.5)'
              : 'rgba(5,86,70,1)',
            color: !trackBankTransaction ? 'rgba(60,72,82,1)' : 'white',
            fontSize: '16px',
            fontWeight: '600',
            borderRadius: '8px',
            padding: '12px 0px',
          }}
        >
          Track Bank Payments Reconciliation
          <Tooltip
            disableInteractive
            disableFocusListener
            disableTouchListener
            title="Track bank transaction"
            arrow
          >
            <button
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
              disabled={true}
            >
              <InfoIcon
                sx={{
                  color: !trackBankTransaction ? 'rgba(60, 72, 82,1)' : 'white',
                  fontSize: '16px',
                }}
              />
            </button>
          </Tooltip>
        </div>
        <div
          onClick={() => {
            setTrackBankTransaction(false)
            setTrackDepositTransaction(true)
            setLoadMoreCount(0)
            setLoadMoreCountBank(0)
          }}
          style={{
            width: '50%',
            textAlign: 'center',
            backgroundColor: !trackDepositTransaction
              ? 'rgba(33,42,57,0.5)'
              : 'rgba(5,86,70,1)',
            color: !trackDepositTransaction ? 'rgba(60,72,82,1)' : 'white',
            fontSize: '16px',
            fontWeight: '600',
            borderRadius: '8px',
            padding: '12px 0px',
          }}
        >
          Track FO Deposits Reconciliation
          <Tooltip
            disableInteractive
            disableFocusListener
            disableTouchListener
            title="Track FO Deposit transaction"
            arrow
          >
            <button
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
              disabled={true}
            >
              <InfoIcon
                sx={{
                  color: !trackDepositTransaction
                    ? 'rgba(60, 72, 82,1)'
                    : 'white',
                  fontSize: '16px',
                }}
              />
            </button>
          </Tooltip>
        </div>
      </div>
      {trackBankTransaction ? (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '24px',
              marginTop: '24px',
            }}
          >
            <div style={{ display: 'flex', gap: '12px' }}>
              <FormControl sx={{ width: 200 }}>
                <InputLabel id="location-label">Select Location</InputLabel>
                <Select
                  labelId="location-label"
                  label="Select Location"
                  value={bankTransactionFilter.location}
                  onChange={(e) =>
                    handleBankFilterChange('location', e.target.value)
                  }
                >
                  {Object.entries(props.locationMap || {}).map(
                    ([key, { name }]) => (
                      <MenuItem key={key} value={key}>
                        {name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl
                sx={{ width: 200 }}
                disabled={
                  !bankTransactionFilter.location ||
                  props.locationMap[bankTransactionFilter.location]
                    ?.accountNumber?.length === 0
                }
              >
                <InputLabel>Bank Account</InputLabel>
                <Select
                  label="Bank Account"
                  value={bankTransactionFilter.bankAccount}
                  onChange={(e) =>
                    handleBankFilterChange('bankAccount', e.target.value)
                  }
                >
                  {bankAccounts.map((dt) => (
                    <MenuItem key={dt} value={dt}>
                      {dt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                sx={{ width: 200, marginRight: '12px' }}
                label="Filter From"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={bankTransactionFilter.fromDate}
                onChange={(e) =>
                  handleBankFilterChange('fromDate', e.target.value)
                }
              />

              <TextField
                sx={{ width: 200, marginRight: '12px' }}
                label="Filter To"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={bankTransactionFilter.toDate}
                onChange={(e) =>
                  handleBankFilterChange('toDate', e.target.value)
                }
              />
            </div>
            <div>
              <FormControl sx={{ width: 200 }}>
                <InputLabel>Is Reconciled ?</InputLabel>
                <Select
                  label="Is Reconciled ?"
                  value={bankTransactionFilter.isReconciled ?? ''}
                  onChange={(e) =>
                    handleBankFilterChange('isReconciled', e.target.value)
                  }
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f1f1f1' }}>
                  <TableCell
                    sx={{
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    S. No.
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Narration
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Bank Account Number
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Transaction No.
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Has been reconciled? (Yes/No)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bankTransactions.map((tx, index) => (
                  <TableRow key={tx.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {getDate(tx.date)}
                    </TableCell>
                    <TableCell>{tx.narration}</TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {tx.bankAccount}
                    </TableCell>
                    <TableCell>{tx.amount.toLocaleString()}</TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {tx.transactionNo}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor:
                          tx.isReconciled === true ? '#d4edda' : '#ffeeba',
                        color: tx.isReconciled === true ? 'green' : 'black',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {tx.isReconciled ? 'Yes' : 'No'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isMoreFoundBank && (
            <Button
              variant="contained"
              color="primary"
              sx={{ display: 'block', margin: '20px auto' }}
              onClick={handleLoadMoreBank}
            >
              Load More
            </Button>
          )}
        </div>
      ) : null}
      {trackDepositTransaction ? (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '24px',
              marginTop: '24px',
            }}
          >
            <div style={{ display: 'flex', gap: '12px' }}>
              <FormControl sx={{ width: 200 }}>
                <InputLabel id="location-label">Select Location</InputLabel>
                <Select
                  labelId="location-label"
                  label="Select Location"
                  value={depositFilter.location}
                  onChange={(e) =>
                    handleDepositFilterChange('location', e.target.value)
                  }
                >
                  {Object.entries(props.locationMap || {}).map(
                    ([key, { name }]) => (
                      <MenuItem key={key} value={key}>
                        {name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              {depositFilter.location && (
                <>
                  <FormControl sx={{ width: 200 }}>
                    <InputLabel>Payment Mode</InputLabel>
                    <Select
                      label="Select a Payment Mode"
                      value={depositFilter.paymentMode}
                      onChange={(e) =>
                        handleDepositFilterChange('paymentMode', e.target.value)
                      }
                    >
                      <MenuItem value="">All</MenuItem>
                      {Object.entries(paymentMethods || {}).map(
                        ([key, { type }]) => (
                          <MenuItem key={key} value={key}>
                            {type}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: 200, marginRight: '12px' }}
                    label="Filter From"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={depositFilter.fromDate}
                    onChange={(e) =>
                      handleDepositFilterChange('fromDate', e.target.value)
                    }
                  />

                  <TextField
                    sx={{ width: 200, marginRight: '12px' }}
                    label="Filter To"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={depositFilter.toDate}
                    onChange={(e) =>
                      handleDepositFilterChange('toDate', e.target.value)
                    }
                  />
                </>
              )}
            </div>
            <div>
              <FormControl sx={{ width: 200 }}>
                <InputLabel>Is Reconciled ?</InputLabel>
                <Select
                  label="Is Reconciled ?"
                  value={depositFilter.isReconciled ?? ''}
                  onChange={(e) =>
                    handleDepositFilterChange('isReconciled', e.target.value)
                  }
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <TableContainer
            component={Paper}
            sx={{
              marginTop: '32px',
              width: '100%',
              overflowX: 'auto',
              border: '1px solid #ccc',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 0,
                      background: '#f4f4f4',
                      zIndex: 3,
                      width: '50px',
                      minWidth: '50px',
                      border: '1px solid #ccc',
                    }}
                  >
                    S.No.
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 50,
                      background: '#f4f4f4',
                      zIndex: 3,
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Learner UID
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 300,
                      background: '#f4f4f4',
                      zIndex: 3,
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Learner Name
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 550,
                      background: '#f4f4f4',
                      zIndex: 3,
                      width: '250px',
                      minWidth: '250px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Course Name
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Batch Name
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Payment Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    View Supporting Document
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Fo Name
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Center Location
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Payment Date
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Payment Type
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Payment Mode
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Comment
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Bank Reference
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Bank Date
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '250px',
                      minWidth: '150px',
                      border: '1px solid #ccc',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                    }}
                  >
                    Is Reconciled
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {depositTransactions.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '50px',
                        minWidth: '50px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 50,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['uid']}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 300,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['name']}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 550,
                        background: '#f4f4f4',
                        zIndex: 3,
                        width: '250px',
                        minWidth: '250px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['mobile']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['courseName']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['batchName']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['amount']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        cursor: 'pointer',
                        color: 'blue',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        if (row['file']) {
                          handleView(row['file'])
                        } else {
                          window.alert('No supporting document uploaded')
                        }
                      }}
                    >
                      View Attachment
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['foName']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['location']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {getDate(row['date'])}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['type']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['mode']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['comment']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['bankReference']}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '250px',
                        minWidth: '150px',
                        border: '1px solid #ccc',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {row['bankDate']}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor:
                          row['isReconciled'] === true ? '#d4edda' : '#ffeeba',
                        color: row['isReconciled'] === true ? 'green' : 'black',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {row['isReconciled'] ? 'Yes' : 'No'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isMoreFound && (
            <Button
              variant="contained"
              color="primary"
              sx={{ display: 'block', margin: '20px auto' }}
              onClick={handleLoadMore}
            >
              Load More
            </Button>
          )}
        </div>
      ) : null}
      <Dialog
        open={openAttachment}
        onClose={() => {
          setOpenAttachment(false)
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Attachment Preview
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenAttachment(false)
            }}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <iframe
            src={fileUrl}
            title="PDF Preview"
            width="100%"
            height="500px"
            style={{ border: 'none' }}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  console.log({ state })
  return {
    permissions: state.login.permissions,
    resources: state.login.resources,
    locationMap: state.login.locationMap,
  }
}
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TrackReconciliation)
