import axios from 'axios'
// import { configs } from "../constants";
import { API_ENDPOINT, DOMAIN } from './../../constants'
import Cookies from 'universal-cookie'
import moment from 'moment-timezone'

export async function getManagePOCDetails() {
  const cookies = new Cookies()
  try {
    const res = await axios.get(`${API_ENDPOINT}/sparktg/getManagePocDetail`, {
      headers: {
        'x-access-token': cookies.get('at', {
          domain: DOMAIN,
          path: '/',
        }),
      },
    })

    return res.data
  } catch (error) {
    return Promise.reject(error.response)
  }
}

export async function addPocMapping(mappingData, actionType) {
  const cookies = new Cookies()
  try {
    const res = await axios.post(
      `${API_ENDPOINT}/sparktg/addPocMapping`,
      { mappingData, actionType },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at', {
            domain: DOMAIN,
            path: '/',
          }),
        },
      }
    )

    return res.data
  } catch (error) {
    return Promise.reject(error.response)
  }
}

export async function getPocMapping(course = '', batch = '') {
  const cookies = new Cookies()
  try {
    const res = await axios.post(
      `${API_ENDPOINT}/sparktg/getPocMapping`,
      { course, batch },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at', {
            domain: DOMAIN,
            path: '/',
          }),
        },
      }
    )

    return res.data
  } catch (error) {
    return Promise.reject(error.response)
  }
}

export async function getCourseSpecificLG(course = '', batch = '') {
  const cookies = new Cookies()
  try {
    const res = await axios.post(
      `${API_ENDPOINT}/sparktg/getCourseSpecificLG`,
      { course, batch },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at', {
            domain: DOMAIN,
            path: '/',
          }),
        },
      }
    )

    return res.data
  } catch (error) {
    return Promise.reject(error.response)
  }
}

export async function getAllCourses() {
  const cookies = new Cookies()
  try {
    const res = await axios.post(
      `${API_ENDPOINT}/ops/get_all_course`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at', {
            domain: DOMAIN,
            path: '/',
          }),
        },
      }
    )

    return res.data
  } catch (error) {
    return Promise.reject(error.response)
  }
}

export async function getAllPermitLocation() {
  const cookies = new Cookies()
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/cfpanelGeneric/get_all_course_locations`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at', {
            domain: DOMAIN,
            path: '/',
          }),
        },
      }
    )

    return res.data
  } catch (error) {
    return Promise.reject(error.response)
  }
}

export async function getAllPermitBatch(course_id, location_id) {
  const cookies = new Cookies()
  try {
    const res = await axios.post(
      `${API_ENDPOINT}/cfpanelCourse/ls_btch`,
      { crs_pg_id: course_id, location_id: location_id },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at', {
            domain: DOMAIN,
            path: '/',
          }),
        },
      }
    )

    return res.data
  } catch (error) {
    return Promise.reject(error.response)
  }
}

export async function getLearnerProfileAndCallHistory(category, credential) {
  const cookies = new Cookies()
  try {
    const res = await axios.post(
      `${API_ENDPOINT}/sparktg/getLearnerProfileAndCallHistory`,
      { category, credential },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at', {
            domain: DOMAIN,
            path: '/',
          }),
        },
      }
    )

    return res.data
  } catch (error) {
    return Promise.reject(error.response)
  }
}

export const checkDeepVisibility = ({ permissions, value, resource }) => {
  if (!permissions) {
    return false
  } else {
    if (!permissions[resource]) {
      return false
    } else if (permissions[resource]?.indexOf(value) !== -1) {
      return true
    } else {
      return false
    }
  }
}

export const getAllCallsLogs = async (pageNumber, limit) => {
  const cookies = new Cookies()
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/sparktg/getAllCallsLogs?pageNumber=${pageNumber}&limit=${limit}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at', {
            domain: DOMAIN,
            path: '/',
          }),
        },
      }
    )

    return res.data
  } catch (error) {
    return Promise.reject(error.response)
  }
}
