import React, { useState, useEffect } from 'react'
import Loader from '../Loader'
import {
  getPgLsKeyAndCost,
  getSubSalesGroup,
  getAdvisors,
  freezeAdvisor,
  addAdvisorProgramMapingTarget,
  getCurrentMonthAdvisorTarget,
} from '../helper'
import { TagPicker } from 'rsuite'

function ManageAdvisors(props) {
  const [loading, setLoading] = useState(false)
  const [month, setMonth] = useState('')
  const [subSalesGroup, setSubSalesGroup] = useState()
  const [subSalesGroupArray, setSubSalesGroupArray] = useState()
  const [advisorsArray, setAdvisorsArray] = useState()

  const [options, setOption] = useState()
  const [value, setValue] = React.useState({})
  const [cacheData, setCacheData] = React.useState([])
  const [renderData, setRenderData] = React.useState({})
  const [currentMonthTarget, setCurrentMonthTarget] = useState()
  // const handleSelect = (value, item, event) => {
  //   setCacheData([...cacheData, item]);
  // };

  const handleTagRemove = (value, item, event) => {
    console.log(value, item, event)
    let tempData = { ...renderData }
    let index = tempData[value].indexOf(item)
    let removed = tempData[value].splice(index, 1)
    console.log({ tempData })
    setRenderData(tempData)
  }

  handleTagRemove

  const handleChange = (e, ad_id) => {
    setCacheData([...cacheData, ...e])
    console.log(e, ad_id)
    let tempData = {}
    const lastElement = e[e.length - 1]
    if (renderData[lastElement]) {
      console.log('does exist!')
      // Check if ad_id already exists in the array
      if (!renderData[lastElement].includes(ad_id)) {
        tempData = {
          ...renderData,
          [lastElement]: [...renderData[lastElement], ad_id],
        }
      } else {
        tempData = { ...renderData }
      }
    } else {
      console.log('doesnot exist!')
      tempData = {
        ...renderData,
        [lastElement]: [ad_id],
      }
    }
    setRenderData(tempData)
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    let salesGroupID = JSON.parse(subSalesGroup)._id
    console.log(month, renderData, salesGroupID)
    // return

    if ('Select Programs' in renderData) {
      renderData = delete renderData['Select Programs']
    }

    setLoading(true)
    let d = {
      month: month,
      salesGroupID: salesGroupID,
      advisorProgramData: renderData,
    }

    let response = await addAdvisorProgramMapingTarget(d)

    if (response.status === 200) {
      alert(response.data.data)
    } else if (response.status === 420) {
      alert(response.data.message)
    } else alert('Something went wrong')
    setLoading(false)
  }

  useEffect(() => {
    SalesGroup().then((data) => {
      setSubSalesGroupArray(data.data)
      setLoading(false)
    })

    async function SalesGroup() {
      setLoading(true)
      return await getSubSalesGroup(props.businessHead)
    }

    GetProgramAndCost().then((data) => {
      let optionsArray = data.data.map((data) => ({
        label: data.leadsquareProgramKey,
        value: data.leadsquareProgramKey,
      }))

      setOption(optionsArray)
    })

    async function GetProgramAndCost() {
      setLoading(true)
      return await getPgLsKeyAndCost(props.businessHead)
    }
  }, [])

  useEffect(() => {
    getCurrentMonthTarget().then((data) => {
      let render = data.data.target || {}
      setRenderData(render)
      let tempMap = {}
      let targetArray = Object.keys(data.data.target)
      for (let i = 0; i < targetArray.length; i++) {
        let tempAdvisorIdArray = data.data.target[targetArray[i]]
        for (let j = 0; j < tempAdvisorIdArray.length; j++) {
          if (!tempMap[tempAdvisorIdArray[j]]) {
            tempMap[tempAdvisorIdArray[j]] = []
            tempMap[tempAdvisorIdArray[j]].push(targetArray[i])
          } else {
            tempMap[tempAdvisorIdArray[j]].push(targetArray[i])
          }
        }
      }

      console.log({ tempMap })

      setCurrentMonthTarget(tempMap)
      setMonth(data.data.month)
    })

    async function getCurrentMonthTarget() {
      setLoading(true)
      let id = JSON.parse(subSalesGroup)._id
      console.log({ id })
      return await getCurrentMonthAdvisorTarget(id)
    }

    GetAdvisors().then((data) => {
      setAdvisorsArray(data.data)
      let temp = {}
      for (let i of data.data) {
        temp[i._id] = []
      }
      setValue(temp)
      setLoading(false)
    })

    async function GetAdvisors() {
      setLoading(true)

      console.log(subSalesGroup)

      let id = JSON.parse(subSalesGroup)._id
      console.log({ id })
      return await getAdvisors(id)
    }

    setRenderData({})
  }, [subSalesGroup])

  const handleFreeze = async (data) => {
    let d = {
      id: data._id,
      freeze: data.freeze ? false : true,
    }

    setLoading(true)
    let response = await freezeAdvisor(d)
    console.log({ response })
    if (response.status == '200') {
      alert(response.data.data)
      GetAdvisors().then((data) => {
        setAdvisorsArray(data.data)
        setLoading(false)
      })

      async function GetAdvisors() {
        setLoading(true)
        let id = JSON.parse(subSalesGroup)._id
        return await getAdvisors(id)
      }
      setLoading(false)
    } else {
      alert('Something went wrong')
      setLoading(false)
    }
  }

  console.log({ renderData })
  console.log({ currentMonthTarget })

  return (
    <>
      {loading && <Loader />}
      <div>
        <form onSubmit={submitHandler}>
          <div className="addprogram2container">
            <div
              className="addprogram2container"
              style={{
                flexDirection: 'row',
                paddingBottom: '0px',
                paddingTop: '0px',
              }}
            >
              <div>
                <p className="addadmissiontargetmonth">Sub Sales Group</p>
                <div>
                  <select
                    className="timeleadtabledrop"
                    value={subSalesGroup && subSalesGroup.email}
                    onChange={(e) => {
                      setSubSalesGroup(e.target.value)
                    }}
                    name="leadsource"
                    id="leadsource"
                    required
                  >
                    <option disabled selected value="">
                      Sub Sales Group
                    </option>
                    {subSalesGroupArray &&
                      subSalesGroupArray.map((data, ind) => {
                        return (
                          <option key={ind} value={JSON.stringify(data)}>
                            {data.email}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>
              <div>
                <p className="addadmissiontargetmonth">Sales Head</p>
                <div>
                  <input
                    className="addadmissiontargetmonthdropdown"
                    type="text"
                    disabled
                    id="month"
                    name="month"
                    defaultValue={
                      subSalesGroup && JSON.parse(subSalesGroup).email
                    }
                    value={subSalesGroup && JSON.parse(subSalesGroup).email}
                  />
                </div>
              </div>
              <div>
                <p className="addadmissiontargetmonth">Select Month</p>
                <div>
                  <input
                    className="addadmissiontargetmonthdropdown"
                    type="month"
                    id="month"
                    name="month"
                    value={month}
                    required
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {subSalesGroup && subSalesGroup ? (
              <>
                {/* table matrix */}
                <div
                  className="addadmissiontargettablemain"
                  style={{
                    height: '380px',
                    marginTop: '0px',
                    overflow: 'scroll',
                  }}
                >
                  <div
                    className=""
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <div style={{ width: 'auto' }}>
                      <table className="table table-striped groupleadtable">
                        <thead>
                          <tr style={{ height: '58px' }}>
                            {/* <th
                              scope="col"
                              style={{ width: "1px", padding: "0px 8px" }}
                            ></th> */}
                            <th
                              scope="col"
                              style={{ width: '200px', padding: '0px 8px' }}
                            >
                              Advisors
                            </th>
                            <th
                              scope="col"
                              style={{ width: '600px', padding: '0px 8px' }}
                            >
                              Allocated Programs
                            </th>
                            <th
                              scope="col"
                              style={{ width: '160px', padding: '0px 8px' }}
                            ></th>
                          </tr>
                          {advisorsArray &&
                            advisorsArray
                              .sort((a, b) => a.email.localeCompare(b.email))
                              .map((data, ind) => {
                                return (
                                  <tr
                                    style={{ backgroundColor: '#ffff' }}
                                    key={data._id}
                                  >
                                    {/* <th></th> */}
                                    <th
                                      scope="col"
                                      style={{ padding: '0px 10px' }}
                                    >
                                      {data.email}
                                    </th>
                                    <th>
                                      <div style={{ padding: '8px 0px' }}>
                                        <TagPicker
                                          cacheData={cacheData}
                                          onSelect={(e) => {
                                            handleChange(e, data._id)
                                          }}
                                          // onSelect={handleChange}
                                          onTagRemove={(e) => {
                                            handleTagRemove(e, data._id)
                                          }}
                                          style={{ width: 500, zIndex: 999999 }}
                                          placeholder="Select Programs"
                                          data={options}
                                          disabled={data.freeze ? true : false}
                                          defaultValue={
                                            data._id &&
                                            currentMonthTarget &&
                                            currentMonthTarget[data._id] &&
                                            currentMonthTarget[data._id]
                                              .length > 0
                                              ? currentMonthTarget[data._id]
                                              : ['Select Programs']
                                          }
                                        />

                                        {data.freeze && (
                                          <p
                                            style={{
                                              fontSize: '12px',
                                              color: '#DC3545',
                                              paddingTop: '8px',
                                            }}
                                          >
                                            Leads have been Frozen
                                          </p>
                                        )}
                                      </div>
                                    </th>
                                    <th>
                                      <div>
                                        <button
                                          className="freezBtn"
                                          style={{
                                            color: `${
                                              data.freeze
                                                ? '#3C4852'
                                                : '#dc3545'
                                            }`,
                                            border: `${
                                              data.freeze
                                                ? '1px solid #3C4852'
                                                : '1px solid #dc3545'
                                            }`,
                                          }}
                                          onClick={() => handleFreeze(data)}
                                        >
                                          {data.freeze
                                            ? 'UnFreeze Leads'
                                            : 'Freeze Leads'}
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                )
                              })}
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    width: '100%',
                    marginTop: '24px',
                    textAlign: 'center',
                  }}
                >
                  <h5>Select a Sub Sales Group</h5>
                </div>
              </>
            )}
          </div>
          <div
            className="addprogrambtns"
            style={{ justifyContent: 'space-between' }}
          >
            <p style={{ paddingLeft: '10px' }}>
              Note: Any changes here will lead to lead allocation being changed
              for that advisor with an immediate effect
            </p>
            <button
              onClick={() => submitHandler}
              className="addprogramsubmitbtn"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ManageAdvisors
