import { Modal } from '@mui/material'

const SentimentModel = ({
  sentimentModel,
  closeSentimentModel,
  summary,
  actionItem,
}) => {
  return (
    <Modal
      open={sentimentModel}
      onClose={closeSentimentModel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className="sentimentModelShadow">
        <div className="sentimentModelContainer">
          <div className="top">
            <p>
              <img src="https://webcdn.imarticus.org/CIB/Vector.png" />
              <span>{'Summary'}</span>
              <span className="AI">{'AI'}</span>
            </p>
            <img
              src="https://webcdn.imarticus.org/inbound/Group926.svg"
              className="crosslogo"
              onClick={closeSentimentModel}
            />
          </div>
          <div className="middle">
            <div className="summaryContainer">
              <p className="content">{summary}</p>
            </div>

            <div className="actionItemContainer">
              <div className="head">
                <img
                  className="icon"
                  src="https://webcdn.imarticus.org/CIB/ri_emotion-happy-line.svg"
                />
                <p className="title">Action Items</p>
              </div>

              <div className="content">
                {actionItem.map((point, index) => {
                  return <p key={index}>{point}</p>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SentimentModel
