import { useEffect, useState } from 'react'
import { getAllCallsLogs, getLearnerProfileAndCallHistory } from './helper'
import TrackLearnerCallTable from './TrackLearnerCallTable'
import SentimentModel from './SentimentModel'
import Loader from '../leadDistribution/Loader'
const TrackLearnerCallHistory = ({}) => {
  const PER_PAGE = 10
  const [selectedFetchCategory, setSelectedFetchCategory] = useState('Mobile')
  const [learnerCrediential, setLearnerCrediential] = useState('')

  const [learnerProfileData, setLearnerProfileData] = useState({})
  const [learnerCallData, setLearnerCallData] = useState([])
  const [queryCategory, setQueryCategory] = useState({})
  const [sentimentScale, setSentimentScale] = useState({})

  const [sentimentModel, setSentimentModel] = useState(false)
  const [activeSummary, setActiveSummary] = useState(``)
  const [activeActionItem, setActiveActionItem] = useState([])
  const [allCallData, setAllCallData] = useState([])
  const [size, setSize] = useState(10)
  const [loading, setLoading] = useState(false)
  let pageCount = Math.ceil(size / PER_PAGE)
  const [currentPage, setCurrentPage] = useState(0)

  const fetchCategory = [
    {
      label: 'Mobile',
      value: 'Mobile',
    },
    {
      label: 'Email',
      value: 'Email',
    },
    {
      label: 'UID',
      value: 'UID',
    },
  ]

  const fetchLearnerDataHandler = async () => {
    setLoading(true)
    if (window) {
      localStorage.setItem('selectedFetchCategory', selectedFetchCategory)
      localStorage.setItem('learnerCrediential', learnerCrediential.trim())
    }

    const response = await getLearnerProfileAndCallHistory(
      selectedFetchCategory,
      learnerCrediential.trim()
    )

    setLoading(false)

    if (response.success == false) {
      alert(
        response.message
          ? response.message
          : 'Fail to fetch Student detail. Please try again'
      )
    } else {
      setLearnerProfileData(response.data.profileData)
      setLearnerCallData(response.data.callData)
      setQueryCategory(response.data.queryCategory)
      setSentimentScale(response.data.sentiment)
    }
  }

  const closeSentimentModel = () => {
    setSentimentModel(false)
  }

  const openSentimentModel = (summary, actionItem) => {
    console.log({ summary })
    console.log({ actionItem })
    setSentimentModel(() => {
      setActiveSummary(summary)
      setActiveActionItem(actionItem)

      return true
    })
  }

  const handleClick = ({ selected: selectedPage }) => {
    console.log({ selectedPage })
    setCurrentPage(parseInt(selectedPage + '0'))
    GetAllCallsLogs(selectedPage).then((data) => {
      console.log('on handleClick')
      console.log({ data })
      setAllCallData(data.data.callData)
      setQueryCategory(data.data.queryCategory)
      setSentimentScale(data.data.sentiment)
      setLoading(false)
    })

    async function GetAllCallsLogs(selectedPage) {
      setLoading(true)
      return await getAllCallsLogs(selectedPage, PER_PAGE)
    }
  }

  useEffect(() => {
    GetAllCallsLogs().then((data) => {
      setLoading(false)
      console.log({ data })
      setSize(data.data.totalCount)
      setAllCallData(data.data.callData)
      setQueryCategory(data.data.queryCategory)
      setSentimentScale(data.data.sentiment)
    })

    async function GetAllCallsLogs() {
      setLoading(true)
      return await getAllCallsLogs(0, PER_PAGE)
    }
  }, [])

  return (
    <>
      {loading && <Loader />}
      {sentimentModel && (
        <SentimentModel
          sentimentModel={sentimentModel}
          closeSentimentModel={closeSentimentModel}
          summary={activeSummary}
          actionItem={activeActionItem}
        />
      )}

      <div className="learnerBody">
        <div className="formSection">
          <div className="inputs">
            <div className="inputLabel">
              <p className="label">Search Via</p>
              <div className="selectInput">
                <select
                  value={selectedFetchCategory}
                  onChange={(e) => {
                    setSelectedFetchCategory(e.target.value)
                    setLearnerCrediential('')
                  }}
                >
                  {fetchCategory.map((fetch, index) => {
                    return (
                      <option
                        key={index}
                        value={fetch.value}
                      >{`Search Via Learner ${fetch.label}`}</option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="inputLabel">
              <p className="label"></p>
              <div className="selectInput" style={{}}>
                <input
                  type="text"
                  placeholder={`Enter Learner ${selectedFetchCategory} Here`}
                  value={learnerCrediential}
                  onChange={(e) => {
                    setLearnerCrediential(e.target.value)
                    setLearnerProfileData({})
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="button"
            style={
              learnerCrediential.length <= 4
                ? { opacity: '0.6', cursor: 'not-allowed' }
                : { cursor: 'pointer' }
            }
            onClick={learnerCrediential.length > 4 && fetchLearnerDataHandler}
          >
            <p>Fetch User</p>
          </div>
        </div>

        {
          learnerProfileData.mobile && (
            <>
              <div className="learnerCard">
                <p className="title">Learner Details</p>
                <div className="details">
                  <div className="detailSet">
                    <p className="label">Name</p>
                    <p className="value">
                      {learnerProfileData.name
                        ? learnerProfileData.name
                        : 'Unknown'}
                    </p>
                  </div>

                  <div className="detailSet">
                    <p className="label">Learner UID</p>
                    <p className="value">
                      {learnerProfileData.uid ? learnerProfileData.uid : '-'}
                    </p>
                  </div>

                  <div className="detailSet">
                    <p className="label">Registered Email ID</p>
                    <p className="value">
                      {learnerProfileData.email
                        ? learnerProfileData.email
                        : '-'}
                    </p>
                  </div>

                  <div className="detailSet">
                    <p className="label">Registered Mobile Number</p>
                    <p className="value">
                      {learnerProfileData.mobile
                        ? learnerProfileData.mobile
                        : '-'}
                    </p>
                  </div>

                  <div className="detailSet">
                    <p className="label">Enrolled Course/s</p>
                    <p className="value">
                      {learnerProfileData.courseName
                        ? learnerProfileData.courseName
                        : '-'}
                    </p>
                  </div>

                  <div className="detailSet">
                    <p className="label">Batch/es ID</p>
                    <p className="value">
                      {learnerProfileData.batchName
                        ? learnerProfileData.batchName
                        : '-'}
                    </p>
                  </div>
                </div>
              </div>
              {learnerCallData.length ? (
                <>
                  <div className="tableSection">
                    <p className="title">Learner Call History</p>
                    <p className="middle">
                      <strong>Note :</strong> This data only includes calls
                      where the learner selects an IVR option and the call
                      successfully connects to any SPOC. Calls that fail to
                      connect to a SPOC (e.g., due to network issues) are not
                      included.
                    </p>
                    <TrackLearnerCallTable
                      data={learnerCallData}
                      queryCategory={queryCategory}
                      sentimentScale={sentimentScale}
                      openSentimentModel={openSentimentModel}
                      currentPage={currentPage}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="emptyScreen2">
                    <img
                      src="https://webcdn.imarticus.org/inbound/emptyLevel2.svg"
                      className="emptyImage"
                    />
                    <p>
                      This learner has no query call records currently. Any new
                      calls will be shown here.
                    </p>
                  </div>
                </>
              )}
            </>
          )
          //  : (
          //   <div className="emptyScreen">
          //     <img
          //       src="https://webcdn.imarticus.org/inbound/Group4606.svg"
          //       className="emptyImage"
          //     />
          //     <p>
          //       You will be able to view the learner call history once you fetch
          //       the user.
          //     </p>
          //   </div>
          // )
        }

        {!learnerProfileData.mobile && (
          <>
            <div className="tableSection">
              <p className="title">All Learner Call History</p>
              <p className="middle">
                <strong>Note :</strong> This data only includes calls where the
                learner selects an IVR option and the call successfully connects
                to any SPOC. Calls that fail to connect to a SPOC (e.g., due to
                network issues) are not included.
              </p>
              {allCallData && (
                <TrackLearnerCallTable
                  data={allCallData}
                  queryCategory={queryCategory}
                  sentimentScale={sentimentScale}
                  openSentimentModel={openSentimentModel}
                  pageCount={pageCount}
                  handleClick={handleClick}
                  pagination={true}
                  currentPage={currentPage}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default TrackLearnerCallHistory
