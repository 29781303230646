import React, { Component, useState, useRef, useEffect } from 'react'
import './style.scss'

//Liberary
import { connect } from 'react-redux'

//MUI
import { Box, CircularProgress } from '@mui/material'

// Componets
import ManagePOCDetail from './ManagePOCDetails'
import Loader from '../leadDistribution/Loader'

import {
  checkDeepVisibility,
  getAllCourses,
  getManagePOCDetails,
  getPocMapping,
} from './helper'
import TrackLearnerCallHistory from './TrackLearnerCallHistory'

let LearnerCallManagement = (props) => {
  console.log({ props })

  const [loading, setLoading] = useState(true)

  // // Learner Trackhistory
  // const [selectedFetchCategory, setSelectedFetchCategory] = useState("Mobile");
  // const [learnerCrediential, setLearnerCrediential] = useState("");

  // const [learnerProfileData, setLearnerProfileData] = useState({});
  // const [learnerCallData, setLearnerCallData] = useState([]);

  // Module Tab
  const [activeInboundTab, setActiveInboundTab] = useState('Manage POC Details')
  const activeInboundTabStyle = {
    background: '#055646',
    color: '#FFF',
  }

  const [courseList, setCourseList] = useState([])
  const [defaultMaping, setDefaultMapping] = useState(null)
  const [onlineLG, setOnlineLG] = useState([])
  const [customMapping, setCustomMapping] = useState([])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await getManagePOCDetails()

      if (response.success == true) {
        setOnlineLG(response.data.onlineLG)
        setDefaultMapping(response.data.defaulPOCMaping)
        // setLoading(false)
      } else {
        // setLoading(false)
        alert('Fail to fetch Data. Please Try again')
      }

      const courseRes = await getAllCourses()

      if (courseRes.success) {
        setCourseList(courseRes.data.courses)
      } else {
        alert('Fail to fetch Course list')
      }

      const customMapping = await getPocMapping()

      if (customMapping.success) {
        setLoading(false)
        setCustomMapping(customMapping.data)
      } else {
        setLoading(false)
        alert('Fail to fetch Custom Mapping Data')
      }
    }

    getData()
  }, [])

  // if (loading) {
  //   return (
  //     <Box sx={{ display: 'flex' }}>
  //       <CircularProgress />
  //     </Box>
  //   )
  // }

  return (
    <>
      {loading && <Loader />}
      <div className="inboundParent">
        <div className="inboundHeader">
          <div className="inboundHeading">
            <p>Learner Call Management</p>
          </div>
          <div className="inboundTabBody">
            <div
              className="inboundTab"
              onClick={() => setActiveInboundTab('Manage POC Details')}
              style={
                activeInboundTab === 'Manage POC Details'
                  ? activeInboundTabStyle
                  : {}
              }
            >
              Manage POC Details
            </div>
            <div
              className="inboundTab"
              onClick={() => setActiveInboundTab('Track Learner Call History')}
              style={
                activeInboundTab === 'Track Learner Call History'
                  ? activeInboundTabStyle
                  : {}
              }
            >
              Track Learner Call History
            </div>
            {/* <div className="inboundTab" onClick={()=>setActiveInboundTab("Governance Team Response Report")} style={activeInboundTab === "Governance Team Response Report" ? activeInboundTabStyle : {}}>          
                    Governance Team Response Report          
                </div> */}
          </div>
        </div>

        <div className="inboundScroll">
          {activeInboundTab === 'Manage POC Details' && (
            <ManagePOCDetail
              defaultMaping={defaultMaping}
              onlineLG={onlineLG}
              courseList={courseList}
              customMapping={customMapping}
              setCustomMapping={setCustomMapping}
              checkDeepVisibility={checkDeepVisibility}
              permissions={props.permissions}
            />
          )}

          {activeInboundTab === 'Track Learner Call History' && (
            <TrackLearnerCallHistory />
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearnerCallManagement)
